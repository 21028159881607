import './ds-accordion-menu.scss';
import { SessionService } from 'services/session-service';
import { bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { ToastService } from 'services/toast-service';
import { autoinject } from 'aurelia-framework';
import { AureliaNavigationOptions } from 'services/models/options';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsAccordionMenu {
    @bindable clickHandler;
    @bindable navigationItems;
    menuElement;
    drawer;
    accordionMenu;
    preferredCurrency;
    currencyOptions;
    notification;
    forceCurrencySubscriber;
    currencySubmenu;
    availableCurrencies;
    paymentMethodSelected;
    paymentMethodSelectedObject;
    pages;

    constructor(private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private toastService: ToastService,
        private websiteService: WebsiteService) {}

    async attached() {
        this.overrideMdc();
        try {
            this.currencyOptions = await this.sessionService.getCurrencyOptions();
            this.pages = await this.websiteService.getPagesByWebsiteShortcode();
            const currentCurrency = this.sessionService.getCurrency();
            if (this.currencyOptions) {
                this.preferredCurrency = this.currencyOptions.find(currency => {
                    return currency.value === currentCurrency;
                });
            }
            if (!this.preferredCurrency) {
                this.sessionService.saveCurrency('USD');
                this.preferredCurrency = this.currencyOptions[0];
            }
        } catch (e) {
            console.log(e);
        }
        this.setCurrency(this.preferredCurrency, null, false);
        this.handleEventSubscriptions();
    }

    detached() {
        this.forceCurrencySubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.forceCurrencySubscriber = this.eventAggregator.subscribe('force-currency', payload => {
            if (payload.currentPaymentMethodSelected !== null) {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference;
                this.paymentMethodSelectedObject = payload.currentPaymentMethodSelected;
                const currencyOption = this.sessionService.getDesiredCurrency(payload.currency);
                this.setCurrency(currencyOption, payload.oldCurrency, false);
            } else {
                this.paymentMethodSelected = payload.currentPaymentMethodSelected.reference ?? null;
            }
        });
    }

    async setCurrency(currency, oldCurrency, fromCurrencyDropdown) {
        this.handleDrawerNavigationClick();
        this.currencySubmenu.open = false;
        this.openCurrency();
        let correctCurrency = '';
        oldCurrency && typeof oldCurrency === 'object' ? oldCurrency = oldCurrency.value : oldCurrency;
        if (this.paymentMethodSelected && (this.router.currentInstruction.config.route.includes('cart') || this.router.currentInstruction.fragment === `/${this.pages.find(x => x.name === 'Customer Portal')?.routeName ?? 'customer-portal'}/balance`)) {
            if ((this.paymentMethodSelected?.includes('ideal')
            || this.paymentMethodSelected?.includes('sepa')
            || this.paymentMethodSelected?.includes('giropay')) && oldCurrency !== 'EUR') {
                correctCurrency = 'EUR';
            } else if (this.paymentMethodSelected?.includes('interac') && oldCurrency !== 'CAD') {
                correctCurrency = 'CAD';
            } else if ((this.paymentMethodSelected?.includes('zelle')
            || this.paymentMethodSelected?.includes('electronic-check-bluesnap-checkout')
            || this.paymentMethodSelected?.includes('webmoney-bluesnap-checkout')
            || this.paymentMethodSelected?.includes('google-pay-bluesnap-checkout'))
            && oldCurrency !== 'USD') {
                correctCurrency = 'USD';
            } else if (this.paymentMethodSelected?.includes('bank-transfer-bluesnap-checkout')
            || this.paymentMethodSelected?.includes('wire-transfer-bluesnap-checkout')) {
                if (oldCurrency !== 'EUR'
                && oldCurrency !== 'GBP'
                && oldCurrency !== 'USD'
                && oldCurrency !== 'BRL'
                && oldCurrency !== 'CAD'
                && oldCurrency !== 'AUD'
                && oldCurrency !== 'NZD'
                && oldCurrency !== 'JPY'
                && oldCurrency !== 'NOK'
                && oldCurrency !== 'CHF') {
                    currency = this.sessionService.getDesiredCurrency('USD');
                    correctCurrency = 'USD, EUR, GBP, BRL, CAD, AUD, NZD, JPY. NOK, CHF';
                }
            } else if (this.paymentMethodSelected?.includes('paysafe-card-bluesnap-checkout')) {
                if (oldCurrency !== 'EUR'
                && oldCurrency !== 'GBP'
                && oldCurrency !== 'USD'
                && oldCurrency !== 'CHF'
                && oldCurrency !== 'DKK'
                && oldCurrency !== 'NOK'
                && oldCurrency !== 'SEK') {
                    currency = this.sessionService.getDesiredCurrency('USD');
                    correctCurrency = 'USD, EUR, GBP, CHF, DKK, NOK, SEK';
                }
            } else if (this.paymentMethodSelected?.includes('skrill-bluesnap-checkout')) {
                if (oldCurrency === 'CNY') {
                    currency = this.sessionService.getDesiredCurrency('USD');
                    correctCurrency = 'AUD, BRL, CAD, EUR, GBP, JPY, NZD, USD, DKK, NOK, SEK, CHF';
                }
            } else if (this.paymentMethodSelected?.includes('paypal-bluesnap-checkout')) {
                if (oldCurrency === 'BRL' || oldCurrency === 'JPY' || oldCurrency === 'CNY') {
                    currency = this.sessionService.getDesiredCurrency('USD');
                    correctCurrency = 'USD, AUD, CAD, EUR, CHF, DKK, GBP, NOK, NZD, SEK';
                }
            } else if (this.paymentMethodSelected?.includes('sofort')) {
                if (oldCurrency !== 'EUR'
                && oldCurrency !== 'GBP'
                && oldCurrency !== 'CHF') {
                    correctCurrency = 'EUR, GBP, CHF';
                }
            } else if (this.paymentMethodSelected?.includes('alipay-bluesnap-checkout')) {
                if (oldCurrency !== 'CNY') {
                    correctCurrency = 'CNY';
                }
            } else if (this.paymentMethodSelected?.includes('boleto-bancario')) {
                if (oldCurrency !== 'BRL') {
                    correctCurrency = 'BRL';
                }
            } else if (this.paymentMethodSelected === 'bluesnap') {
                if (oldCurrency === 'BRL') {
                    currency = this.sessionService.getDesiredCurrency('USD');
                    correctCurrency = 'AUD, BRL, CAD, EUR, GBP, JPY, NZD, USD, DKK, NOK, SEK, CHF, CNY';
                }
            }
        }

        oldCurrency = this.sessionService.getDesiredCurrency(oldCurrency);
        this.preferredCurrency = (fromCurrencyDropdown && correctCurrency === '') || !currency ? oldCurrency : currency;
        if (this.preferredCurrency) {
            await this.sessionService.saveCurrency(this.preferredCurrency.value);
        }
        if (oldCurrency && correctCurrency === '') {
            await this.sessionService.savePreviousCurrency(oldCurrency.value);
        }
        if (this.currencyOptions) {
            this.availableCurrencies = [...this.currencyOptions];
            const selectedOptionIndex = this.currencyOptions.findIndex(selectedCurrency => {
                return selectedCurrency.value === this.preferredCurrency.value;
            });
            if (selectedOptionIndex >= 0) {
                this.availableCurrencies.splice(selectedOptionIndex, 1);
            }
        }
    }

    overrideMdc() {
        const selectArrows = this.accordionMenu.querySelectorAll('.mdc-expandable__header:not(.expandable-nested) .mdc-expandable__dropdown-icon');
        if (selectArrows) {
            selectArrows.forEach((el) => {
                el.innerHTML = '<img class="faq-arrow-icon" src="/faq/arrow-white.svg" alt="arrow icon" loading="lazy">';
            });
        }
    }

    openCurrency() {
        const drawerContainer = document.querySelector('.drawer');
        this.currencySubmenu.open ? drawerContainer.classList.add('h-100') : drawerContainer.classList.remove('h-100');
    }

    navigateLink(link) {
        this.handleDrawerNavigationClick();
        this.route(link, null);
    }

    handleDrawerNavigationClick() {
        const drawerContainer = document.querySelector('.drawer');
        drawerContainer.classList.remove('mdc-drawer--open');
    }

    route(route, param) {
        const options: AureliaNavigationOptions = { id: param };
        if (param) {
            this.router.navigate(route + '/' + param, options);
            return;
        }
        this.router.navigate(route);
    }

}
