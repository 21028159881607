import './ds-order-summary-card.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CurrencyService } from 'services/currency-service';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class DsOrderSummaryCard {
    @bindable totalPrice = 0;
    @bindable subtotal = 0;
    @bindable orderId;
    @bindable buttonFunction;
    @bindable buttonText;
    @bindable state;
    @bindable couponDiscount;
    @bindable insuranceFee;
    @bindable smallOrderFee;
    @bindable smallOrderMinimumAmount;
    @bindable transactionFee;
    @bindable deliveryFee;
    @bindable hstValue;
    @bindable coupon;
    @bindable selectedPaymentMethod;
    @bindable validCoupon;
    @bindable balanceAmount;
    @bindable balanceFee;
    currencyPrefix;
    rate;
    couponOpen = false;
    insuranceName;
    cart;
    preferredCurrency;
    cartSubscriber;
    currencySubscriber;
    couponInputViewModel;
    insuranceNameSubscriber;
    width;
    sizeChanged;

    constructor(
        private sessionService: SessionService,
        private currencyService: CurrencyService,
        private currencyFormatValueConverter: CurrencyFormatValueConverter,
        private eventAggregator: EventAggregator) { }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        [this.cart, this.preferredCurrency] = await Promise.all([
            this.sessionService.getCart(),
            this.sessionService.getCurrency()
        ]);
        this.currencyPrefix = this.currencyFormatValueConverter.getCurrencyPrefix(this.preferredCurrency);
        this.rate = await this.currencyService.getStoredCurrencyRates(this.preferredCurrency);
        this.handleEventSubscriptions();
    }

    detached() {
        this.cartSubscriber?.dispose();
        this.currencySubscriber?.dispose();
        this.insuranceNameSubscriber?.dispose();
        this.sizeChanged?.dispose();
    }

    handleEventSubscriptions() {
        this.cartSubscriber = this.eventAggregator.subscribe('cart-updated', payload => {
            if (payload.updateWithoutRefresh) {
                this.cart.splice(payload.index, 1);
            } else {
                this.cart = payload.cart;
            }
            if (this.coupon && this.cart.length > 0) {
                this.couponInputViewModel.checkCouponValidation();
            }
        });

        this.currencySubscriber = this.eventAggregator.subscribe('currency-updated', async payload => {
            this.preferredCurrency = payload.currency;
            this.currencyPrefix = this.currencyFormatValueConverter.getCurrencyPrefix(this.preferredCurrency);
            this.rate = await this.currencyService.getStoredCurrencyRates(this.preferredCurrency);
            this.getSmallOrderFeeTooltip();
        });

        this.insuranceNameSubscriber = this.eventAggregator.subscribe('insurance-updated', async payload => {
            this.insuranceName = payload;
            setTimeout(() => {
                this.changeTooltipText();
            }, 500);
        });

        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
        });
    }

    getTooltipValues() {
        return this.insuranceName;
    }

    changeTooltipText() {
        const tooltipElements = document.querySelectorAll('#insurance-tooltip');
        if (tooltipElements.length) {
            tooltipElements.forEach((el) => {
                el.innerHTML = this.getTooltipValues();
            });
        } else {
            const element = document.querySelectorAll('.mdc-tooltip');
            const array = Array.from(element);
            array.shift();
            array.forEach((el) => {
                const tooltip = el.querySelector('div.mdc-tooltip__surface') as HTMLElement | null;
                if (tooltip.innerText.trim() === this.insuranceName) {
                    tooltip.setAttribute('id', 'insurance-tooltip');
                    setTimeout(() => {
                        tooltip.innerHTML = this.getTooltipValues();
                    }, 500);
                }
            });
        }
    }

    getSmallOrderFeeTooltip() {
        return `A ${ this.currencyPrefix } ${ (this.smallOrderFee * this.rate).toFixed(2) } fee applies to orders less than ${ this.currencyPrefix } ${ (this.smallOrderMinimumAmount * this.rate).toFixed(2) }.`;
    }
}
