import './ds-faq-category-card.scss';
import { bindable } from 'aurelia-framework';

export class DsFaqCategoryCard {
    @bindable title: string;
    @bindable iconName: string;
    @bindable class: string;
    @bindable categoryId: number;
    @bindable position: number;

    private categoryCard: HTMLElement;

    attached() {
        if (this.position === 1) {
            this.activate();
        }
    }

    activate() {
        this.categoryCard.classList.add('active');
    }

    deactivate() {
        this.categoryCard.classList.remove('active');
    }

    disable() {
        this.categoryCard.classList.add('d-none');
    }

    enable() {
        this.categoryCard.classList.remove('d-none');
    }
}
