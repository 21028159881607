import './ds-phone-input.scss';
import { bindable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { CustomerService } from 'services/customer-service';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import intlTelInput from 'intl-tel-input';
import intlTelInputUtils from 'intl-tel-input/build/js/utils';

@autoinject()
export class DsPhoneInput {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    stages = {
        ENTERING_PHONE: 1,
        ENTERING_CODE: 2,
        VERIFIED: 3,
    };

    phoneElement;
    authCode;
    sentPhone;
    countryCodes;
    verificationStage = this.stages.ENTERING_PHONE;
    @bindable requireSms;
    @bindable verifiedFunction;
    @bindable user;
    @bindable onCart;
    @bindable phoneInput;
    @bindable inputStyle;
    @bindable enteringToken;
    @bindable loading;
    @bindable labelStyle;
    @bindable labelText;
    @bindable containLabel;
    @bindable flowPage;

    showMessage;
    phoneInputState;
    authCodeState;
    justReset = true;
    errorTimeout;
    profileErrorTimeout;
    showCallMeInstead;
    firedFunction;
    showMiniSpinnerPhone;
    miniSpinnerPhoneStopWatch;
    showMiniSpinnerAuthCode;
    miniSpinnerAuthCodeStopWatch;
    whiteXMark;
    whiteAuthCodeXMark;
    toastPhoneInputSent;
    toastAuthCodeSent;
    phoneInputFocusInStopWatch;
    authCodeFocusInStopWatch;
    timeouts;

    lastEventTrigered;
    arrowRotated = false;
    arrowTrigered;

    countryToUse;
    parent;
    countryCodeSelector;
    phoneNumber;
    phoneSubscriber;
    inputContainer;


    constructor(private router: Router, private customerService: CustomerService, private sessionService: SessionService, private eventAggregator: EventAggregator, private inputNumericValueChecker: InputNumericValueChecker, private toastService: ToastService, private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async attached() {
        await this.sessionService.getGeolocation();
        this.loading = true;

        this.phoneInput = intlTelInput(this.phoneElement, {
            separateDialCode: true,
            utilsScript: intlTelInputUtils,
            preferredCountries: ['us', 'ca', 'gb'],
            dropdownContainer: this.inputContainer
        });

        this.user = await this.sessionService.refreshProfile();
        this.countryToUse = this.user?.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : this.user?.country
                ? this.user.country.toLowerCase()
                : await this.sessionService.getCountry();
        if (this.countryToUse && this.countryToUse !== 'N/A') {
            this.phoneInput.setCountry(this.countryToUse.toLowerCase());
        }
        if (this.user?.phoneNumber) {
            this.phoneNumber = this.user.phoneNumber;
            this.phoneInput.setNumber(this.user.phoneNumber);
        }
        if (this.user?.phoneNumberConfirmed) {
            if (this.phoneInput.isValidNumber()) {
                this.phoneInputState = 'success';
            } else {
                this.phoneInputState = 'error';
            }
        }

        //Problem with the image path
        // this.overrideITI();

        this.handleEventSubscriptions();
        this.loading = false;
    }

    async detached() {
        this.phoneInput?.destroy();
        this.phoneSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.phoneSubscriber = this.eventAggregator.subscribe('phone-updated', (payload) => {
            if (payload.successful) {
                this.user.phoneNumberConfirmed = true;
                this.user.phoneNumberInReview = false;
                if (!this.onCart) {
                    this.resetPhoneInput();
                }
            }
        });
    }

    overrideITI() {
        const selectOuterContainer = this.countryCodeSelector.querySelector(
            '.iti__flag-container'
        );
        const selectContainer =
            this.countryCodeSelector.querySelector('.iti__arrow');
        selectContainer.innerHTML +=
            '<div class="override-arrow-container"></div>';
        const selectContainerForArrow = this.countryCodeSelector.querySelector(
            '.override-arrow-container'
        );
        const newArrowIcon =
            '<img class="faq-arrow-icon" src="/static/icons/arrow-white.svg" alt="arrow icon" loading="lazy">';
        selectContainerForArrow.innerHTML = newArrowIcon;
        this.arrowTrigered = selectContainer.querySelector('.faq-arrow-icon');
        const handleClick = () => {
            if (this.arrowRotated) {
                this.arrowTrigered.style.transform = 'rotate(0deg)';
                this.arrowRotated = false;
            } else {
                if (this.lastEventTrigered !== 'focusOut') {
                    this.arrowTrigered.style.transform = 'rotate(180deg)';
                    this.arrowRotated = true;
                }
            }
        };
        const handleFocusOut = () => {
            if (this.arrowTrigered) {
                this.arrowTrigered.style.transform = 'rotate(0deg)';
                this.arrowRotated = false;
                this.lastEventTrigered = 'focusOut';
                setTimeout(() => {
                    this.lastEventTrigered = '';
                }, 200);
            }
        };
        selectOuterContainer.addEventListener('click', handleClick);
        selectOuterContainer.addEventListener('focusout', handleFocusOut);
    }

    async submitPhone() {
        if (this.phoneInput.isValidNumber()) {
            const cc = this.phoneInput.getSelectedCountryData().dialCode;
            const num = this.phoneInput.getNumber().slice(1 + cc.length);
            const cf = this.phoneInput.getSelectedCountryData().iso2;

            if (!cc || !num) {
                await this.toastService.showToast('Account Error', 'Please enter both your country code and phone number.', 'error');
                return;
            }
            try {
                const result = await this.customerService.registerPhone(
                    cc,
                    num,
                    cf
                );
                if (result === true) {
                    await this.toastService.showToast('A code has been sent to your mobile device.', 'Please enter your code to confirm the phone number.', 'info');
                    this.phoneInputState = null;
                    this.verificationStage = this.stages.ENTERING_CODE;
                    this.enteringToken = true;
                    this.parent.shouldShowPhoneNumber = false;
                    this.user.phoneNumberInReview = true;
                    this.eventAggregator.publish('user-updated', {
                        user: await this.sessionService.refreshProfile(),
                    });
                } else {
                    this.phoneInputState = 'error';
                    this.firedFunction = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Account Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
        }
    }

    async codeNotSent() {
        if (this.requireSms === 'true') {
            this.showCallMeInstead = true;
            await this.submitPhone();
        }
    }

    async setPhoneNumberNotInReview() {
        this.verificationStage = this.stages.ENTERING_PHONE;
        this.enteringToken = false;
        this.clearAuthCode();
        this.authCodeState = '';
        this.parent.shouldShowPhoneNumber = true;
        this.user.phoneNumberInReview = false;
        this.eventAggregator.publish('user-updated', {
            user: await this.sessionService.getProfile(),
        });
    }

    async callInstead() {
        if (this.phoneInput.isValidNumber()) {
            const cc = this.phoneInput.getSelectedCountryData().dialCode;
            const num = this.phoneInput.getNumber().slice(1 + cc.length);
            const cf = this.phoneInput.getSelectedCountryData().iso2;
            if (!cc || !num) {
                await this.toastService.showToast('Account Error', 'Please enter both your country code and phone number.', 'error');
                return;
            }
            try {
                const result = await this.customerService.requestCall(
                    cc,
                    num,
                    cf
                );
                if (result === true) {
                    await this.toastService.showToast('You will receive a call with a code.', 'Please enter the code provided to confirm the phone number.', 'Info');
                    this.phoneInputState = null;
                    this.verificationStage = this.stages.ENTERING_CODE;
                    this.enteringToken = true;
                    this.parent.shouldShowPhoneNumber = false;
                    this.user.phoneNumberInReview = true;
                    this.eventAggregator.publish('user-updated', {
                        user: await this.sessionService.refreshProfile(),
                    });
                } else {
                    this.firedFunction = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Account Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
        }
    }

    getElementStateClass(state, isInputPhone) {
        if (state === 'success') {
            return isInputPhone ? 'ds-input--success' : 'ds-form-control--success';
        } else if (state === 'error' || state === 'inactive') {
            return isInputPhone ? 'ds-input--error' : 'ds-form-control--error';
        }
    }

    async checkIfPhoneValid() {
        this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.showMiniSpinnerPhone = false;
        this.whiteXMark = false;
        const result = this.phoneInput?.isValidNumber();
        if (result && this.phoneInputState !== 'error' && !this.firedFunction) {
            this.phoneInputState = 'success';
            if (this.requireSms === 'true') {
                await this.submitPhone();
            }
        } else if (!this.firedFunction) {
            this.phoneInputState = 'error';
            if (!this.toastPhoneInputSent) {
                await this.toastService.showToast('Account Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
            }
        }
        return result;
    }

    phoneInputFocusIn() {
        this.firedFunction = false;
        this.showMiniSpinnerPhone = false;
        this.toastPhoneInputSent = false;
        this.phoneInputState = 'typing';
        this.whiteXMark = true;

        this.phoneInputFocusInStopWatch = setTimeout(() => {
            if (this.phoneNumber !== undefined) {
                this.isPhoneValid();
            }
        });
    }

    async isPhoneValid(ev = { key: '' }) {
        if (ev.key === 'Enter') {
            await this.checkIfPhoneValid();
        } else {
            this.justReset = false;
            this.phoneInputState = 'typing';
            this.showMiniSpinnerPhone = false;
            this.whiteXMark = true;
            const result = this.phoneInput?.isValidNumber();
            this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.miniSpinnerPhoneStopWatch = setTimeout(() => {
                this.whiteXMark = false;
                this.showMiniSpinnerPhone = true;
            }, 1000);
            if (result && this.requireSms === 'true') {
                this.profileErrorTimeout = setTimeout(async () => {
                    this.showMiniSpinnerPhone = false;
                    this.phoneInputState = 'success';
                    this.firedFunction = true;
                    await this.submitPhone();
                }, 2000);
            } else {
                this.profileErrorTimeout = setTimeout(async () => {
                    if (!result) {
                        this.showMiniSpinnerPhone = false;
                        this.phoneInputState = 'error';
                        this.toastPhoneInputSent = true;
                        await this.toastService.showToast('Account Error', 'Please enter a valid phone number to proceed with your phone verification.', 'error');
                    } else {
                        this.showMiniSpinnerPhone = false;
                        this.phoneInputState = 'success';
                        this.toastPhoneInputSent = false;
                    }
                }, 4000);
            }
            return result;
        }
    }

    clearPhoneInput() {
        this.timeouts = [this.profileErrorTimeout, this.miniSpinnerPhoneStopWatch, this.phoneInputFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.phoneInput.setNumber('');
        this.phoneNumber = null;
        this.justReset = true;
        this.phoneInputState = null;
    }

    clearAuthCode() {
        this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.authCode = null;
    }

    async authCodeFocusOut() {
        if (this.router.currentInstruction.config.name === 'profile') {
            this.timeouts = [this.profileErrorTimeout];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            if (this.authCode?.length === 7) {
                this.authCodeState = 'success';
            } else {
                this.authCodeState = 'error';
            }
        } else {
            this.showMiniSpinnerAuthCode = false;
            this.whiteAuthCodeXMark = false;
            this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            if (
                this.authCode?.length === 7 &&
                this.authCodeState !== 'error' &&
                !this.firedFunction
            ) {
                this.authCodeState = 'success';
                await this.submitCode();
            } else if (!this.firedFunction) {
                this.authCodeState = 'error';
                this.showCallMeInstead = true;
                if (!this.toastAuthCodeSent) {
                    await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                }
            }
        }
    }

    authCodeFocusIn() {
        this.firedFunction = false;
        this.showMiniSpinnerAuthCode = false;
        this.toastAuthCodeSent = false;
        this.authCodeState = 'typing';
        this.whiteAuthCodeXMark = true;

        this.authCodeFocusInStopWatch = setTimeout(() => {
            if (this.authCode !== undefined) {
                this.authCodeUpdatedOnKeyPress();
            }
        });
    }

    async authCodeUpdatedOnKeyPress(ev = { key: '' }) {
        if (ev.key === 'Enter') {
            await this.authCodeFocusOut();
        } else {
            if (this.router.currentInstruction.config.name === 'profile') {
                this.timeouts = [this.profileErrorTimeout];
                this.clearationTimeoutValueConverter.toView(this.timeouts);
                if (this.authCode?.length === 7) {
                    this.profileErrorTimeout = setTimeout(() => {
                        this.firedFunction = true;
                        this.submitCode();
                    }, 1000);
                } else {
                    this.profileErrorTimeout = setTimeout(async () => {
                        if (this.authCode?.length !== 7) {
                            this.authCodeState = 'error';
                            this.toastAuthCodeSent = true;
                            await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                        }
                    }, 4000);
                }
            } else {
                this.authCodeState = 'typing';
                this.showMiniSpinnerAuthCode = false;
                this.whiteAuthCodeXMark = true;
                this.timeouts = [this.errorTimeout, this.miniSpinnerAuthCodeStopWatch, this.authCodeFocusInStopWatch];
                this.clearationTimeoutValueConverter.toView(this.timeouts);
                this.miniSpinnerAuthCodeStopWatch = setTimeout(() => {
                    this.whiteAuthCodeXMark = false;
                    this.showMiniSpinnerAuthCode = true;
                }, 1000);
                if (this.authCode?.length === 7) {
                    this.errorTimeout = setTimeout(() => {
                        this.firedFunction = true;
                        this.submitCode();
                    }, 2000);
                } else {
                    this.errorTimeout = setTimeout(async () => {
                        if (this.authCode?.length !== 7) {
                            this.showMiniSpinnerAuthCode = false;
                            this.authCodeState = 'inactive';
                            this.showCallMeInstead = true;
                            this.toastAuthCodeSent = true;
                            await this.toastService.showToast('Error', 'Please enter a valid verification code.', 'error');
                        }
                    }, 4000);
                }
            }
        }
    }

    async resetPhoneInput() {
        this.user = await this.sessionService.refreshProfile();
        this.countryToUse = this.user.phoneCountryFlag
            ? this.user.phoneCountryFlag
            : await this.sessionService.getCountry();
        this.phoneInput.setCountry(this.countryToUse?.toLowerCase());
        this.phoneInput.setNumber(this.user.phoneNumber);
        this.phoneNumber = this.user.phoneNumber;
    }

    async submitCode() {
        if (this.authCode?.length === 7) {
            try {
                const result = await this.customerService.verifyPhone(
                    this.authCode
                );
                if (result) {
                    await this.toastService.showToast('Success!', 'Phone number has been validated succesfully', 'success');
                    this.verificationStage = this.stages.VERIFIED;
                    this.showMiniSpinnerAuthCode = false;
                    this.authCodeState = 'success';
                    this.eventAggregator.publish('user-updated', {
                        user: await this.sessionService.refreshProfile(),
                    });
                    this.eventAggregator.publish('phone-updated', {
                        successful: true,
                    });
                    this.parent?.paymentMethodSelector?.selectPaymentMethod(this.parent?.selectedPaymentMethod);
                    if (this.verifiedFunction) {
                        this.verifiedFunction(result);
                    }
                } else {
                    this.showMiniSpinnerAuthCode = false;
                    this.firedFunction = false;
                    this.authCodeState = 'error';
                    this.showCallMeInstead = true;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            await this.toastService.showToast('Error', 'Please enter a valid 7 digit verification code.', 'error');
        }
    }

    enteringTokenChanged() {
        if (!this.enteringToken) {
            this.verificationStage = this.stages.ENTERING_PHONE;
            this.phoneInputState = 'success';
            this.firedFunction = false;
            this.showCallMeInstead = false;
            this.clearAuthCode();
            this.authCodeState = '';
        }
    }

    displayMessage() {
        this.showMessage = true;
    }

    checkPasteText() {
        navigator.clipboard.readText().then(text => {
            const numStr = /^\d+$/;
            if (numStr.test(text)) {
                this.phoneNumber += text;
            }
        });
    }
}
