import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { websiteShortCode } from 'environment';
import { ToastService } from './toast-service';
import { StartVerificationRequest } from './models/veriff/startVerificationRequest';
import { StartVerificationResponse } from './models/veriff/startVerificationResponse';

@autoinject()
export class CustomerService {
    path = 'User';
    idPath = this.path + '/';

    constructor(private api: ApiService, private eventAggregator: EventAggregator, private toastService: ToastService) { }

    async register(data) {
        data.websiteShortCode = websiteShortCode();
        const registerResponse = await this.api.doPost(this.path, data);
        if (registerResponse) {
            if (registerResponse.alreadyRegistered && !data.slowValidateEmail) {
                await this.toastService.showToast('Welcome back!', 'Account already exists. Log in instead.', 'info');
                return true;
            }
        }
        return registerResponse;
    }

    async updateInformation(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async deleteProfile() {
        return await this.api.doDelete(this.path + '/deleteProfile');
    }

    async requestEmailConfirmation() {
        return await this.api.doGet(this.path + '/RequestEmailConfirmation?websiteShortCode=' + websiteShortCode());
    }

    async sendContactEmail(from, message, topic, name, attachments = null) {
        const data = {
            from: from,
            message: message,
            topic: topic,
            name: name,
            websiteShortCode: websiteShortCode(),
            attachments: attachments ? JSON.stringify({ files: attachments }) : null
        };

        return await this.api.doPost('Contact', data);
    }

    async checkIfUserDeleted() {
        return await this.api.doGet(this.path + '/checkIfUserDeleted');
    }

    async setPaymentMethod(paymentMethodId) {
        const data = { paymentMethodId: paymentMethodId };
        return await this.api.doPut(this.path + '/updateInformation', data);
    }

    async requestTwoFactorAuthentication() {
        return await this.api.doGet(this.path + '/requestTwoFactorAuthentication');
    }

    async confirmTwoFactorActivation(token) {
        return await this.api.doPost(this.path + '/confirmTwoFactorActivation', { token: token });
    }

    async verifyTwoFactor(token) {
        return await this.api.doPost(this.path + '/VerifyTwoFactor', { token: token });
    }

    async checkIfTwoFactorEnabled() {
        return await this.api.doGet(this.path + '/checkIfTwoFactorEnabled');
    }

    async removeTwoFactorAuthentication(token) {
        return await this.api.doPost(this.path + '/removeTwoFactorAuthentication', { token: token });
    }

    async getPaysafeVaultProfile() {
        return await this.api.doGet(this.path + '/PaysafeVaultProfile');
    }

    async getPaysafeVaultLastUsedCard() {
        return await this.api.doGet(this.path + '/PaysafeVaultLastUsedCard?websiteShortCode=' + websiteShortCode());
    }

    async deletePaysafeCard(cardId) {
        return await this.api.doDelete(this.path + '/DeleteSavedCard?paysafeCardId=' + cardId);
    }

    async updatePaysafeCard(firstName, lastName, cardId, expiryDate) {
        const data = {
            expiryMonth: parseInt(expiryDate.substring(0, 2)),
            expiryYear: parseInt('20' + expiryDate.substring(expiryDate.length - 2)),
            holderName: `${firstName} ${lastName}`
        };
        return await this.api.doPut(this.path + `/UpdatePaysafeCardExpiry?cardId=${cardId}`, data);
    }

    async requestPasswordReset(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('requestResetPassword', data);
    }

    async updatePassword(currentPassword, newPassword) {
        const data = { currentPassword: currentPassword, newPassword: newPassword };
        const response = await this.api.doPost(this.path + '/updatePassword', data);
        if (response === null) throw new Error('Failed to update password');
        return response;
    }

    async resetPasswordWithToken(email, newPassword, token) {
        const data = { newPassword, email, accessToken: token };
        return await this.api.doPost('ResetPassword', data);
    }

    async verifyResetPasswordToken(token, email) {
        const data = { token, email };
        return await this.api.doGet('VerifyPasswordResetToken', data);
    }

    async registerPhone(countryCode, phone, countryFlag) {
        const data = { phoneNumber: phone, countryCode: countryCode, phoneCountryFlag: countryFlag };
        return await this.api.doPost(this.path + '/RegisterPhoneNumber', data);
    }

    async requestCall(countryCode, phone, phoneCountryFlag) {
        return await this.api.doGet(this.path + '/RequestCallVerification?countryCode=' + countryCode + '&phoneNumber=' + phone + '&phoneCountryFlag=' + phoneCountryFlag);
    }

    async verificationCategoryCriteriaMet(verificationCategoryName) {
        return await this.api.doGet(`${this.path}/${verificationCategoryName}/GetUserVerificationCategoryCriteriaMet`);
    }

    async verifyPhone(token) {
        const data = { token: token };
        return await this.api.doPost(this.path + '/VerifyPhoneNumber', data);
    }

    async confirmEmail(email, token) {
        const data = { email: email, token: token };
        return await this.api.doPost(this.path + '/ConfirmEmail', data);
    }

    async requestIpAuthorization(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('RequestIpAuthorization', data);
    }

    async authorizeIp(data) {
        data.websiteShortCode = websiteShortCode();
        return await this.api.doPost('AuthorizeIp', data);
    }

    async storeUserCartProducts(cartItem, userId, loggingIn, updateCart) {
        cartItem = Array.isArray(cartItem) ? cartItem[0] : cartItem;
        cartItem.userCartId = cartItem.userCartId ? cartItem.userCartId : 0;
        cartItem.quantity = parseFloat(cartItem.quantity.toString().replaceAll(',', ''));
        cartItem.id = cartItem.id ?? cartItem.productId;
        const data = {
            userId: userId,
            loggingIn: loggingIn ? loggingIn : false,
            updateCart: updateCart ? updateCart : false,
            product: cartItem
        };
        return await this.api.doPost(`${this.path}/StoreUserCartProducts`, data);
    }

    async destroyUserCartProducts(id = 0) {
        return await this.api.doDelete(`${this.path}/${id}/DestroyUserCartProducts`);
    }

    async getUserCartProducts() {
        return await this.api.doGet(`${this.path}/GetUserCartProducts`);
    }

    async getDocumentDecision(sessionId: string): Promise<StartVerificationResponse> {
        return await this.api.doGet(`${this.path}/${sessionId}/GetDocumentDecision`);
    }

    async processPaysafe3DS(data) {
        return await this.api.doPost(`${this.path}/ProcessPaysafe3DS`, data);
    }

    async startVerification(userId: number, verification: StartVerificationRequest): Promise<StartVerificationResponse> {
        return await this.api.doPost(`${this.path}/${userId}/StartVeriffSession`, { verification });
    }

    async getVeriffUserData(id) {
        return await this.api.doGet(`${this.path}/${id}/GetVeriffUserData`);
    }
}
