import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class DeliveryMethodService {
    constructor(private api: ApiService) {
        this.api = api;
    }

    async getAllDeliveryMethods() {
        return await this.api.doGet('DeliveryMethod');
    }
}
