import './ds-coupon-input.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { CouponService } from 'services/coupon-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';

@autoinject()
export class DsCouponInput {
    @bindable text;
    @bindable totalPrice;
    @bindable coupon;
    @bindable validCoupon;
    @bindable cart;
    id;
    state;
    width;
    couponStopWatch;
    couponStopWatch2;
    miniSpinnerStopWatch;
    showMiniSpinnerCoupon;
    couponFocusInStopWatch;
    parent;
    timeouts;

    constructor(
        private couponService: CouponService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.couponService = couponService;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    created() {
        this.id = 'menu-' + Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    getElementStateClass(state) {
        if (state === 'error') {
            return 'ds-input--error';
        }
        if (state === 'warning') {
            return 'ds-input--warning';
        }
        if (state === 'success') {
            return 'ds-input--success';
        }
    }

    scrollScreenToSummary() {
        if (this.width <= 425) {
            const dsOrderSummaryCard = document.getElementById('ds-order-summary-card');
            dsOrderSummaryCard.scrollIntoView();
        }
    }

    async couponChanged() {
        this.state = null;
        this.validCoupon = null;
        if (this.coupon === '') {
            return;
        }
        this.showMiniSpinnerCoupon = false;
        this.timeouts = [this.couponStopWatch, this.couponStopWatch2, this.miniSpinnerStopWatch, this.couponFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.coupon) {
            try {
                this.miniSpinnerStopWatch = setTimeout(() => {
                    this.showMiniSpinnerCoupon = true;
                }, 1000);
                this.couponStopWatch = setTimeout(async() => {
                    const categories = this.cart.map(p => p.productCategoryId);
                    const games = this.cart.map(p => p.gameId);
                    const products = this.cart.map(p => p.id);
                    const services = this.cart.map(p => p.productId);
                    const productsAndServices = products.concat(services).filter(e => e !== undefined);
                    const validatedCoupon = await this.couponService.validateCoupon(this.coupon.trim(), this.totalPrice, categories, games, productsAndServices);
                    if (validatedCoupon) {
                        this.showMiniSpinnerCoupon = false;
                        this.validCoupon = validatedCoupon;
                        this.state = 'success';
                    } else {
                        this.couponStopWatch2 = setTimeout(() => {
                            this.showMiniSpinnerCoupon = false;
                            this.validCoupon = null;
                            this.state = 'error';
                        }, 2000);
                    }
                }, 2000);
            } catch (e) {
                console.log(e);
            }
        }
    }

    couponUpdatedOnFocusIn() {
        this.showMiniSpinnerCoupon = false;
        this.state = null;
        this.validCoupon = null;
        this.couponFocusInStopWatch = setTimeout(() => {
            if (this.coupon !== '') {
                this.couponChanged();
            }
        });
    }

    async checkCouponValidation() {
        if (this.coupon) {
            this.showMiniSpinnerCoupon = false;
            this.timeouts = [this.couponStopWatch, this.couponStopWatch2, this.miniSpinnerStopWatch, this.couponFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            const categories = this.cart.map(p => p.productCategoryId);
            const games = this.cart.map(p => p.gameId);
            const products = this.cart.map(p => p.id);
            const services = this.cart.map(p => p.productId);
            const productsAndServices = products.concat(services).filter(e => e !== undefined);
            const validatedCoupon = await this.couponService.validateCoupon(this.coupon.trim(), this.totalPrice, categories, games, productsAndServices);
            if (validatedCoupon) {
                this.validCoupon = validatedCoupon;
                this.state = 'success';
            } else {
                this.validCoupon = null;
                this.state = 'error';
            }
        }
    }
}
