import './ds-blog-navigator.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { getAWSBucketEndpoint } from 'environment';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsBlogNavigator {
    constructor(private websiteService: WebsiteService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('blogs');
    }

    @bindable blogs;
    @bindable title;
    baseAwsEndpoint: string;
    blogsPageRoute;

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.blogsPageRoute = pages.find(x => x.name === 'Blogs')?.routeName ?? 'blogs';
    }
}
