import env from '../config/environment.json';

function apiEndpoint(): string {
    return env.apiEndpoint;
}

function apiPaymentEndpoint(): string {
    return env.apiPaymentEndpoint;
}

function websiteShortCode(): string {
    return env.websiteShortCode;
}

function getAWSBucketEndpoint(endpoint: string): string {
    return env.amazonAwsUrl.replace('dynamic', endpoint);
}

function debug(): boolean {
    return env.debug;
}

function countries(): { code: string; name: string; }[] {
    return env.countries;
}

function paysafeApiKey(): string {
    return env.paysafeKey;
}

function paysafeMerchantId(): string {
    return env.paysafeMerchantId;
}

function paysafeEnvironment(): string {
    return env.paysafeEnvironment;
}

function paysafeIgnoreCc(): string[] {
    return env.paysafeIgnoreCC;
}

function paysafeUsdAccount(): number {
    return env.paysafeUSDAccount;
}

function paysafeCadAccount(): number {
    return env.paysafeCADAccount;
}

function kountUrl(): string {
    return env.kountUrl;
}

function baseUrl(): string {
    return env.baseUrl;
}

function adminPanelUrl(): string {
    return env.adminPanelUrl;
}

export default this;

export {
    websiteShortCode,
    apiEndpoint,
    getAWSBucketEndpoint,
    debug,
    countries,
    paysafeApiKey,
    paysafeCadAccount,
    paysafeEnvironment,
    paysafeIgnoreCc,
    paysafeMerchantId,
    paysafeUsdAccount,
    kountUrl,
    apiPaymentEndpoint,
    baseUrl,
    adminPanelUrl
};
