import './ds-dialog.scss';
import { bindable, observable, autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';

@autoinject()
export class DsDialog {
    bind(bindingContext) {
        this.parent = bindingContext;
    }

    constructor(private router: Router, private sessionService: SessionService, private toastService: ToastService, private clearationTimeoutValueConverter: ClearationTimeoutValueConverter) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    @bindable dialog;
    @bindable title: string;
    @bindable content: string;
    @bindable buttons: string;
    @bindable showCheckMark;
    @bindable showErrorMark;
    @observable password;

    loading;
    user;
    parent;
    stopWatch1;
    stopWatch2;
    timeouts;
    step = 1;

    async attached() {
        this.user = await this.sessionService.refreshProfile();
    }

    open() {
        this.dialog.open = !this.dialog.open;
    }

    confirm() {
        this.step = 2;
    }

    handleClosing() {
        this.step = 1;
        this.password = '';
        this.showErrorMark = this.showCheckMark = false;
    }

    passwordUpdatedOnKeyPress() {
        if (this.password !== '') {
            this.showErrorMark = this.showCheckMark = false;
            this.timeouts = [this.stopWatch1, this.stopWatch2];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.stopWatch1 = setTimeout(async () => {
                this.loading = true;
                this.stopWatch2 = setTimeout(async () => {
                    this.loading = false;
                    this.triggerAccountDelete();
                }, 2000);
            }, 2000);
        }
    }

    catchSubmit() {
        return;
    }

    async triggerAccountDelete() {
        this.showErrorMark = this.showCheckMark = false;
        try {
            const response = await this.sessionService.verifyPassword(this.password);
            if (response) {
                this.showCheckMark = true;
                if (this.user.twoFactorEnabled) {
                    this.step = 3;
                } else {
                    this.handleAccountDelete();
                }
            } else {
                this.showErrorMark = true;
                await this.toastService.showToast('Failed to delete account', 'Please review the entered password or contact support.', 'error');
            }
        } catch (e) {
            console.log(e);
        }
    }

    handleAccountDelete() {
        if (this.router.currentInstruction.config.name === 'profile') {
            this.parent.deleteProfile();
        }
    }
}
