import './ds-page-content-area.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
/**
 * ToDo in this file:
 *
 * -Create SessionService and import it (for now, compile it without it until users are considered)
 *
 *
 */

@autoinject()
export class DsPageContentArea {
    @bindable key;
    pageContent = '';
    viewingAsAdmin: boolean;
    adminViewSubscriber;

    constructor(private pageContentAreaService: PageContentAreaService, private sessionService: SessionService, private eventAggregator: EventAggregator) { }

    async attached() {
        try {
            this.viewingAsAdmin = this.sessionService.getAdminView();
            await this.setByKey();
            this.handleEventSubscriptions();
        } catch (e) {
            console.log(e);
        }
    }

    detached() {
        this.adminViewSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.adminViewSubscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
            this.setByKey();
        });
    }

    async setByKey() {
        const result = await this.pageContentAreaService.getByKey(this.key);
        if (this.viewingAsAdmin) {
            this.pageContent = `Key: { ${this.key} }`;
        } else {
            this.pageContent = result ? result.markup : '';
        }
    }

    async keyChanged() {
        await this.setByKey();
    }
}
