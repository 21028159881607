import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { SessionService } from './session-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class IpAddressService {
    path = 'IpAddress';

    constructor(private api: ApiService, private sessionService: SessionService, private eventAggregator: EventAggregator) {}

    async postIp() {
        const response = await this.api.doPost(this.path, {});
        this.eventAggregator.publish('vpn-detection', { vpn: response.vpn, tor: response.tor, ip: response.ip });
        await this.saveUserProxy(response);
        await this.saveGeoLocation(response);
        return response;
    }

    async saveGeoLocation(geolocation) {
        this.sessionService.geolocation = geolocation;
    }

    async saveUserProxy(userProxy) {
        this.sessionService.userProxy = userProxy;
    }
}
