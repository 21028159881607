import './ds-payment-method-selector.scss';
import { bindable, autoinject, observable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ToastService } from 'services/toast-service';
import { PaymentMethodsService } from 'services/payment-methods-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BlacklistService } from 'services/blacklist-service';

@autoinject()
export class DsPaymentMethodSelector {
    @bindable selectedPaymentMethod;
    @bindable givenCurrencySelected;
    @bindable home;
    @bindable @observable user;
    @bindable preferredCurrency;
    @bindable phoneVerified;
    @bindable totalFunction;
    @bindable totalPrice;
    balancePaymentMethod;
    allPaymentMethods;
    paymentMethods;
    search = '';
    validCountry = true;
    parent;
    userBlacklist;

    constructor(
        private paymentMethodService: PaymentMethodsService,
        private sessionService: SessionService,
        private toastService: ToastService,
        private eventAggregator: EventAggregator,
        private blacklistService : BlacklistService) {
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        if (!this.paymentMethods) {
            this.paymentMethods = await this.paymentMethodService.getByWebsite();
        }

        if (await this.checkUserInBlackList()) {
            return;
        }

        this.balancePaymentMethod = this.paymentMethods.find(e => e.name === 'Balance');
        this.paymentMethods = this.paymentMethods.filter(e => e.name !== this.balancePaymentMethod?.name);
        this.checkIfUserAndMethodBalanceEnabled();
        this.validCountry = await this.sessionService.isCountryValidForCartPage();
        if (!this.validCountry) {
            return this.toastService.showToast('Error', 'The country which you\'re attempting to access chickswatches.com is not supported. Please contact support for assistance.', 'error');
        }
    }

    checkIfUserAndMethodBalanceEnabled() {
        if (this.user?.balance > 0 && this.balancePaymentMethod) {
            this.user.balanceEnabled = true;
        }
    }

    /**
     * Resets the payment method selected and filter the paymentMethodList based on the given currency
     */
    filterPaymentMethodsByCurrencySelected() {
        if (this.givenCurrencySelected?.type === 'C') {
            this.paymentMethods = this.allPaymentMethods.filter(p => p.paymentMethodCategoryId === 4);
            this.paymentMethods.forEach(element => {
                element.maximum = null;
                element.minimum = null;
            });
        } else {
            this.paymentMethods = this.allPaymentMethods.filter(p => p.paymentMethodCategoryId !== 4);
        }
    }

    handleSearchChange(event) {
        if (event?.target?.value) {
            this.search = event.target.value;
            if (this.paymentMethods.length) {
                for (const method of this.paymentMethods) {
                    method.show = method.name.toLowerCase().includes(this.search.toLocaleLowerCase());
                }
            }
        }
    }

    async selectPaymentMethod(method) {
        if (!method) {
            return;
        }
        const selector = document.getElementById('payment-selector');
        selector.classList.remove('show');
        const dropdownMenu = document.getElementById('payment-dropdown-menu');
        dropdownMenu.classList.remove('show');
        if (method.reference === 'crypto' || method.reference.includes('bluesnap-checkout') || method.reference.includes('coinpayments') || method.reference.includes('skrill-direct') || method.reference.includes('google-pay-direct') || method.reference.includes('g2a')) {
            if (method.reference.includes('bluesnap-checkout') || method.reference.includes('skrill-direct') || method.reference.includes('google-pay-direct') || method.reference.includes('g2a')) {
                this.parent.summaryButtonState = this.parent.user && this.parent.billing.street && this.parent.billing.country && this.parent.billing.city && this.parent.billing.zip ? 'active' : 'disabled';
            } else {
                this.parent.summaryButtonState = this.parent.user ? 'active' : 'disabled';
            }
            this.parent.summaryButtonFunction = this.startOrder.bind(this);
            this.parent.summaryButtonText = 'Pay Now';
            this.parent.shouldShowBlueLine = this.parent.user ? false : true;
        } else if (method !== this.selectedPaymentMethod && method.reference !== this.selectedPaymentMethod?.reference) {
            this.parent.shouldShowBlueLine = true;
        }
        this.selectedPaymentMethod = method;
        await this.checkUserInBlackList();

        if (this.totalFunction) {
            this.totalFunction();
        }

        //Force currency based on payment
        await this.forceCurrency(method);
    }

    clearSearch() {
        this.search = null;
    }

    startOrder() {
        this.parent.startOrder();
    }

    changeCryptoPaymentSelected(cryptoAbbreviation: string) {
        const paymentMethod = this.paymentMethods.find(p => p.cryptoAbbreviation === cryptoAbbreviation);
        this.selectPaymentMethod(paymentMethod);
    }

    async forceCurrency(method) : Promise<void> {
        let currencyToSent = '';
        if (method.reference.includes('ideal')
        || method.reference.includes('interac')
        || method.reference.includes('zelle')
        || method.reference.includes('bluesnap-checkout')
        || method.reference.includes('sofort')) {
            switch (method.reference) {
                case 'ideal':
                case 'sepa-bluesnap-checkout':
                case 'giropay-bluesnap-checkout':
                case 'sofort':
                    currencyToSent = 'EUR';
                    break;
                case 'interac':
                    currencyToSent = 'CAD';
                    break;
                case 'zelle':
                case 'electronic-check-bluesnap-checkout':
                case 'webmoney-bluesnap-checkout':
                case 'google-pay-bluesnap-checkout':
                case 'paysafe-card-bluesnap-checkout':
                case 'skrill-bluesnap-checkout':
                    currencyToSent = 'USD';
                    break;
                case 'alipay-bluesnap-checkout':
                    currencyToSent = 'CNY';
                    break;
                case 'boleto-bancario-bluesnap-checkout':
                    currencyToSent = 'BRL';
                    break;
                default:
                    break;
            }
        } else {
            const previousCurrency = await this.sessionService.getPreviousCurrency();
            previousCurrency !== 'undefined' ? currencyToSent = previousCurrency : '';
        }
        this.eventAggregator.publish('force-currency', { currency: currencyToSent, oldCurrency: this.preferredCurrency, currentPaymentMethodSelected: method });
    }

    async checkUserInBlackList() {
        const banForPaymenthMethod = await this.sessionService.checkIfUserByPaymentMethodInBlackList(this.selectedPaymentMethod);
        if (banForPaymenthMethod.paymentMethods) {
            this.paymentMethods = null;
            this.selectedPaymentMethod = null;
            this.parent.summaryButtonState = 'disabled';
            this.parent.userBanByPaymenthMethod = true;
            return true;
        }
        if (banForPaymenthMethod.filterPaymentMethods) {
            this.paymentMethods = this.paymentMethods?.filter(x => !x.requiresVerification && !x.requiresPhoneVerification);
            this.selectedPaymentMethod = banForPaymenthMethod.selectedPaymentMethod ? null : this.selectedPaymentMethod;
            this.parent.summaryButtonState = banForPaymenthMethod.selectedPaymentMethod ? 'disabled' : 'active';
        }
        return false;
    }
}
