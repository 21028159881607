import './ds-newsletter-prompt.scss';
import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';

@autoinject()
export class DsNewsletterPrompt {
    constructor(private sessionService: SessionService,
        private customerService: CustomerService,
        private eventAggregator: EventAggregator,
        private router: Router) {
    }

    user;
    routerSubscription;

    async attached() {
        this.user = await this.sessionService.refreshProfile();
        this.handleBannerVisibility();
        this.handleEventSubscriptions();
    }

    detached() {
        this.routerSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.routerSubscription = this.eventAggregator.subscribe('router:navigation:success', async() => {
            this.handleBannerVisibilityRouteChange();
        });
    }

    handleBannerVisibility() {
        const element = document.getElementById('ds-newsletter-prompt');
        if (this.router.currentInstruction.config.route === '' && this.user && !this.user.optedInForEmails && !this.user.closedSubscriptionBanner) {
            if (element) {
                element.style.display = 'block';
            }
        } else {
            if (element) {
                element.style.display = 'none';
            }
        }
    }

    handleBannerVisibilityRouteChange() {
        const element = document.getElementById('ds-newsletter-prompt');
        if (this.router.currentInstruction.config.route === '' && this.user && !this.user.optedInForEmails && !this.user.closedSubscriptionBanner) {
            if (element) {
                element.style.display = 'block';
            }
        }
    }

    async optIn() {
        this.user.closedSubscriptionBanner = true;
        this.user.optedInForEmails = true;
        await this.customerService.updateInformation(this.user);
        this.eventAggregator.publish('banner-updated', { successful: 'success', text: 'Thank you for subscribing to our newsletter! You will now receive exclusive offers about our products. You may change your preferences at any time from your profile page.' });
        this.hideBanner();
    }

    async dismissPrompt() {
        this.hideBanner();
        this.user.closedSubscriptionBanner = true;
        await this.customerService.updateInformation(this.user);
    }

    hideBanner() {
        const banner = document.getElementById('ds-newsletter-prompt');
        banner.style.display = 'none';
    }
}
