export class PhoneFormatValueConverter {
    toView(value, code) {
        if (!value) {
            return 0;
        }

        const aux = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

        return `+${code} ${aux[1]}-${aux[2]}-${aux[3]}`;
    }
}
