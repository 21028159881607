import moment from 'moment';

export class DateFormatterValueConverter {
    toView(value, format = 'calendar') {
        if (!value) {
            return;
        }

        if (!format) {
            format = 'MMMM Do YYYY, h:mmA';
        }

        if (format === 'calendar') {
            return moment.utc(value).local().calendar({
                sameDay: '[Today at] hh:mm A',
                nextDay: '[Tomorrow at] hh:mm A',
                nextWeek: 'dddd',
                lastDay: '[Yesterday at] hh:mm A',
                lastWeek: 'MM/DD/YYYY',
                sameElse: 'MM/DD/YYYY'
            });
        } else {
            return moment.utc(value).local().format(format);
        }
    }
}
