import { baseUrl } from 'environment';

export class SeoStep {
    run(navigationInstruction, next) {
        const canonicalLink = document.querySelector('link[rel=canonical]');
        const baseUrlWithoutSlash = baseUrl().slice(0, -1);
        canonicalLink.setAttribute('href', `${baseUrlWithoutSlash}${navigationInstruction.fragment}`);
        return next();
    }
}

