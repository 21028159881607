import { autoinject } from 'aurelia-framework';
import { Redirect } from 'aurelia-router';
import { SessionService } from 'services/session-service';

@autoinject()
export class AuthorizeStep {
    constructor(private sessionService: SessionService) {
        this.sessionService = sessionService;
    }

    async run(navigationInstruction, next) {
        const requiresAuth = navigationInstruction.getAllInstructions().some(i => i.config.settings.auth);
        const isLoggedIn = this.sessionService.isTokenValid();

        if (requiresAuth && !isLoggedIn) {
            return next.cancel(new Redirect('sign-in?redirect_url=' + navigationInstruction.fragment.substring(1)));
        }

        return next();
    }
}
