import { autoinject } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { CurrencyService } from 'services/currency-service';
import numeral from 'numeral';

@autoinject()
export class CurrencyFormatValueConverter {
    signals = ['currency-changed'];

    constructor(private sessionService: SessionService, private currencyService: CurrencyService) {}

    toView(value, format = undefined, overrideCurrency = undefined, avoidConversion = false) {
        if (!format) {
            format = '(0,0.00)';
        }

        let currentCurrency = overrideCurrency;
        if (!currentCurrency) {
            currentCurrency = this.sessionService.getCurrency();
        }

        let returnString;

        if (!value && value !== 0) {
            returnString = '0.00';
        } else if (value < 0) {
            returnString = numeral(value).format(format).replace('(', '').replace(')', '');
        } else if (avoidConversion) {
            returnString = numeral(value).format(format);
        } else {
            const rate = this.currencyService.getStoredCurrencyRates(currentCurrency);
            if (!rate) {
                return 'CURRENCY-ERROR';
            }
            const total = this.evenRound(value, 5);
            const convertedPrice = total * rate;

            returnString = numeral(convertedPrice).format(format);
        }

        const prefix = this.getCurrencyPrefix(currentCurrency);
        if (prefix) {
            value < 0 ? returnString = '-' + prefix + returnString : returnString = prefix + returnString;
        }

        return returnString;
    }

    getCurrencyPrefix(currentCurrency) {
        const activeCurrencies = this.currencyService.getStoredActiveCurrenciesByWebsite();
        return activeCurrencies?.find(x => x.code === currentCurrency)?.symbol || 'CURRENCY-ERROR';
    }

    evenRound(num, decimalPlaces) {
        const d = decimalPlaces || 0;
        const m = Math.pow(10, d);
        const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
        const i = Math.floor(n); const f = n - i;
        const e = 1e-8; // Allow for rounding errors in f
        const r = (f > 0.5 - e && f < 0.5 + e) ?
            ((i % 2 === 0) ? i : i + 1) : Math.round(n);
        return d ? r / m : r;
    }
}
