import './ds-game-banner.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class DsGameBanner {
    constructor(private sessionService: SessionService,
        private eventAggregator: EventAggregator) {}

    @bindable title;
    @bindable backgroundPath;
    device;
    width;
    viewingAsAdmin;
    sizeChanged;
    subscriber;

    async bind() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.viewingAsAdmin = await this.sessionService.getAdminView();
        this.setBackgroundDeviceKey();
        this.handleEventSubscriptions();
    }

    handleEventSubscriptions() {
        this.sizeChanged = this.eventAggregator.subscribe('size-changed', payload => {
            this.width = payload.width;
            this.setBackgroundDeviceKey();
        });

        this.subscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
        });
    }

    setBackgroundDeviceKey() {
        if (this.width > 992) {
            this.device = 'DESKTOP';
        } else if (this.width > 576) {
            this.device = 'TABLET';
        } else {
            this.device = 'MOBILE';
        }
    }
}
