import './navbar.scss';
import 'simplebar/dist/simplebar.css';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { ProductCategoryService } from 'services/product-category-service';
import { GameService } from 'services/game-service';
import { SessionService } from 'services/session-service';
import { getAWSBucketEndpoint } from 'environment';
import { SubItem } from 'services/models/subItem';
import { AccordionCategory } from 'services/models/accordionCategory';
import { WebsiteService } from 'services/website-service';
import scrollbar from 'simplebar';

@autoinject()
export class Navbar {
    @bindable user;
    @bindable buttonStyling = 'primary navbar-btn';
    @bindable showReadingProgress;
    @bindable currencyOptions = [];
    @bindable pages;
    productCategories = [];
    userSubscriber;
    drawer;
    togglerButton;
    currentCurrency;
    games;
    test;
    cart;
    cartSubscriber;
    formattedAccordionCategories;
    formattedCategories;
    bannerSubscriber;
    showBanner;
    stateBanner;
    textBanner;
    url;
    clickFunctionName;
    baseAwsEndpoint = getAWSBucketEndpoint('games');
    contactPageRoute;
    homePageRoute;

    private scrollbar: scrollbar;

    constructor(private router: Router, private sessionService: SessionService,
        private eventAggregator: EventAggregator, private productCategoryService: ProductCategoryService,
        private gameService: GameService, private websiteService: WebsiteService) {
    }

    async bind() {
        [this.productCategories, this.cart] = await Promise.all([
            this.productCategoryService.getAllForNav(),
            this.sessionService.getCart()
        ]);
        this.formattedAccordionCategories = this.getFormattedCategoriesWithCurrency().filter(x => x.name !== 'Sell' && !x.isCurrency);
        this.formattedCategories = this.getFormattedCategories().filter(x => x.name !== 'Sell');
    }

    async attached() {
        try {
            [this.currentCurrency, this.games] = await Promise.all([
                this.sessionService.getCurrency(),
                this.gameService.getAll()
            ]);
            this.scrollbar = new scrollbar(document.getElementById('main-page-host'));
            this.togglerButton = document.getElementById('toggler-button');
            this.contactPageRoute = this.pages.find(x => x.name === 'Contact Us')?.routeName ?? 'contact';
            this.homePageRoute = this.pages.find(x => x.name === 'Home')?.routeName ?? '';
            await this.handleScrollBar();
            this.handleEventSubscriptions();
        }
        catch (e) {
            console.error(e);
        }
    }

    detached() {
        this.userSubscriber?.dispose();
        this.cartSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.cartSubscriber = this.eventAggregator.subscribe('cart-updated', payload => {
            this.cart = payload.cart;
        });

        this.bannerSubscriber = this.eventAggregator.subscribe('banner-updated', payload => {
            this.showBanner = true;
            this.stateBanner = payload.successful;
            this.textBanner = payload.text;
            this.clickFunctionName = payload.clickFunctionName;
            this.url = payload.url;
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            if (payload) {
                if (payload.forDelete) {
                    this.user = undefined;
                }
                else {
                    const profile = await this.sessionService.getProfile();
                    if (payload.user.id === profile.id) {
                        this.user = payload.user;
                    }
                }
            }
        });
    }

    async handleScrollBar() {
        const navbar = document.getElementById('navigation-bar');
        const readingProgress = document.getElementById('reading-progress-fill');

        if (this.scrollbar) {
            const offset = this.scrollbar.getScrollElement();
            if (offset) {
                offset.addEventListener('scroll', (e: Event) => {
                    if ((e.target as HTMLElement).scrollTop > 0) {
                        navbar.classList.add('navbar-color');
                    } else {
                        navbar.classList.remove('navbar-color');
                    }
                    if (this.showReadingProgress) {
                        const w = ((e.target as HTMLElement).scrollTop) / ((e.target as HTMLElement).scrollHeight - (e.target as HTMLElement).clientHeight) * 100;
                        readingProgress.style.width = w + '%';
                    }
                });
            }
        }
    }

    redirectToSignIn() {
        this.router.navigate('/sign-in');
    }

    handleDrawerNavigationClick(something, openMenu) {
        this.drawer.open = openMenu;
    }

    /**
     * Function to retrieve the accordion list with currencies
     * @returns the accordion list with the currencies
     */
    getFormattedCategoriesWithCurrency(): AccordionCategory[] {
        const accordionAux = this.getFormattedCategories();
        if (accordionAux) {
            accordionAux.push(this.getCurrencyOptions());
        }
        return accordionAux;
    }

    getCurrencyOptions() {
        if (!this.currentCurrency) {
            this.sessionService.saveCurrency('USD');
            this.currentCurrency = this.currencyOptions[0]?.text;
        }

        const currencyAccordianObject = {
            name: this.currentCurrency,
            isCurrency: true,
            items: []
        };

        for (const currency of this.currencyOptions) {
            if (currency.value !== this.currentCurrency) {
                currencyAccordianObject.items.push({ category: 'CurrencyOptions', name: currency.value, isCurrency: true, link: false });
            }
        }
        return currencyAccordianObject;
    }


    /**
     * Builds Navigation object for desktop
     * This same function maps the entire categories object
     */
    getFormattedCategories() {
        return this.productCategories?.map(category => {
            return {
                name: category.name,
                items: category.gameForNav.filter(e => !e.parentGameId).map(game => this.getGame(category, game))
            };
        });
    }

    /**
     * Gets games for each category
     * If subGames exist it fills each game with those items.
     * @param {*} category
     * @param {*} game
     * @returns
     */
    getGame(category, game) {
        const dynamicRoute = this.pages.find(x => x.name.toLowerCase() === category.name.toLowerCase())?.routeName ?? category.name.toLowerCase();
        const subItemObject: SubItem = {
            category: category.name,
            name: game.name,
            shortName: game.shortName,
            slug: `/${dynamicRoute}/${game.slug[0]}`,
            link: true
        };
        const childGames = category.gameForNav.filter(g => g.parentGameId === parseInt(game.id));
        subItemObject.template = childGames.length > 0
            ? this.makeItemWithLocalImageTemplate(game.dropdownText && game.dropdownText !== '' ? game.dropdownText : game.name, game.gameNavigationIcon, true)
            : this.makeItemWithCustomImageTemplate(game.dropdownText && game.dropdownText !== '' ? game.dropdownText : game.name, game.gameNavigationIcon);
        subItemObject.items = childGames.length > 0 ?
            this.getSubGames(childGames, category)
            : null;
        return subItemObject;
    }

    getSubGames(childGames, category) {
        const dynamicRoute = this.pages.find(x => x.name.toLowerCase() === category.name.toLowerCase())?.routeName ?? category.name.toLowerCase();
        return childGames.map(childGame => {
            return {
                category: category.name,
                name: childGame.name,
                shortName: childGame.shortName,
                slug: `/${dynamicRoute}/${childGame.slug[0]}`,
                template: this.makeItemWithCustomImageTemplate(childGame.dropdownText && childGame.dropdownText !== '' ? childGame.dropdownText : childGame.name, childGame.gameNavigationIcon),
                link: true
            };
        });
    }

    makeItemWithCustomImageTemplate(text, icon) {
        const el1 = document.createElement('div');
        el1.innerText = text;
        el1.setAttribute('style', 'display: flex; align-items: center; text-transform: captilize;');
        el1.classList.add('customer-dropdown-option');
        const imageContainer = document.createElement('span');
        imageContainer.setAttribute('style', 'width: 30px;');
        imageContainer.classList.add('me-3', 'text-center');
        if (icon) {
            const el = document.createElement('img');
            el.loading = 'lazy';
            el.alt = text;
            el.src = icon?.includes('default.svg') ? `/icons/${icon}` : `${this.baseAwsEndpoint}${icon}`;
            el.setAttribute('style', 'width: auto; max-width: 30px; max-height: 20px');
            el.innerText = icon;
            imageContainer.appendChild(el);
        }
        el1.prepend(imageContainer);
        return el1;
    }

    makeItemWithLocalImageTemplate(text, icon, subcategory) {
        const el1 = document.createElement('div');
        el1.innerHTML = `<span class="dropdown-nav-text ${subcategory ? 'col' : ''}">${text}</span>`;
        el1.setAttribute('style', 'display: flex; align-items: space-between; text-transform: captilize;');
        el1.classList.add('customer-dropdown-option');
        const imageContainer = document.createElement('span');
        imageContainer.setAttribute('style', 'width: 30px;');
        imageContainer.classList.add('me-3', 'text-center');
        if (icon) {
            const el = document.createElement('img');
            el.loading = 'lazy';
            el.alt = text;
            el.src = icon?.includes('default.svg') ? `/icons/${icon}` : `${this.baseAwsEndpoint}${icon}`;
            el.setAttribute('style', 'width: auto; max-width: 30px; max-height: 20px');
            el.innerText = icon;
            imageContainer.appendChild(el);
        }
        el1.prepend(imageContainer);
        if (subcategory) {
            const arrowContainer = document.createElement('span');
            arrowContainer.classList.add('material-icons', 'submenu-arrow');
            arrowContainer.innerText = 'chevron_right';
            el1.append(arrowContainer);
        }
        return el1;
    }

    handleNavigationClick(item) {
        if (item) {
            this.route(item);
        }
    }

    route(route, param = undefined) {
        if (param) {
            this.router.navigate(route + '/' + param);
            return;
        }
        this.router.navigate(route);
    }

}
