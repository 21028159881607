import './manual-checkout-form.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { ImageService } from 'services/image-service';
import QRCode from 'qrcode';

@autoinject()
export class ManualCheckoutForm {
    parent;
    constructor(private sessionService: SessionService, private imageService: ImageService) {}

    bind(bindingContext) {
        this.parent = bindingContext;
        this.parent.summaryButtonState = 'disabled';
        this.parent.summaryButtonText = 'Mark as sent';
        this.parent.summaryButtonFunction = () => {
            this.parent.startOrder();
        };
    }

    @bindable currency;
    @bindable paymentMethod;
    @bindable totalPrice;
    @bindable qrCode;
    @bindable veriffStep;
    user;

    async attached() {
        this.user = await this.sessionService.getProfile();
        if (this.user?.idVerified || !this.paymentMethod.requiresVerification) {
            this.veriffStep = false;
            this.parent.summaryButtonState = 'active';
            this.parent.shouldShowBlueLine = false;
        } else {
            this.veriffStep = true;
            this.parent.summaryButtonState = 'disabled';
            this.parent.shouldShowBlueLine = true;
        }
        if (this.paymentMethod.reference === 'alipay') {
            await this.generateQrCode();
        }
    }

    detached() {
        this.veriffStep = false;
    }

    async paymentMethodChanged() {
        if (this.user?.idVerified || !this.paymentMethod.requiresVerification) {
            this.veriffStep = false;
            this.parent.summaryButtonState = 'active';
            this.parent.shouldShowBlueLine = false;
        } else {
            this.veriffStep = true;
            this.parent.summaryButtonState = 'disabled';
            this.parent.shouldShowBlueLine = true;
        }
        if (this.paymentMethod.reference === 'alipay') {
            await this.generateQrCode();
        }
    }

    async generateQrCode() {
        try {
            this.qrCode = await QRCode.toDataURL(this.paymentMethod.qrCodeValue);
        } catch (e) {
            console.error(e);
        }
    }
}
