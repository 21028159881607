import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class WebsiteService {
    path = 'Website';
    pages;

    constructor(private api: ApiService) {}

    async getPagesByWebsiteShortcode() {
        if (this.pages) {
            return this.pages;
        }
        this.pages = await this.api.doGet(`${this.path}/Pages`);
        return this.pages;
    }
}
