export class EntityStatusValueConverter {
    toView(value, fulfilled, isSelector = false) {
        // Orders
        if (!value) {
            return;
        } else if (value.includes('complete') && (fulfilled === '1' || fulfilled === 'True')) {
            return 'Completed';
        } else if (value.includes('refund-requested')) {
            return 'Refund Pending';
        } else if (value.includes('partially-refunded')) {
            return 'Partially Refunded';
        } else if (value.includes('refunded')) {
            return 'Refunded';
        } else if (value.includes('refund-pending')) {
            return 'Refund Pending';
        } else if (value.includes('rejected')) {
            return 'Rejected';
        } else if (value.includes('created')) {
            return 'Created';
        } else if (value.includes('received')) {
            return 'Received';
        }
        // Tickets
        else if (value.includes('activated') || value.includes('active')) {
            return isSelector ? 'Active Tickets' : 'Active';
        } else if (value.includes('draft')) {
            return isSelector ? 'Drafts' : 'Draft';
        }
        else if (value.includes('closed')) {
            return isSelector ? 'Closed Ticktes' : 'Closed';
        }
        // Notifications
        else if (value.includes('all')) {
            return 'All';
        } else if (value.includes('none')) {
            return 'None';
        } else if (value === 'read') {
            return 'Read';
        } else if (value === 'unread') {
            return 'Unread';
        }
        return 'Pending';
    }
}
