import './ds-validator-progress.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { Router } from 'aurelia-router';

@autoinject()
export class DsValidatorProgress {
    id;
    @bindable validatorProperty;
    @bindable value;
    @bindable isCustomerPortal = false;
    firstProperty;
    secondProperty;
    thirdProperty;
    fourthProperty;
    fifthProperty;

    constructor(private router: Router) { }

    created() {
        if (this.router.currentInstruction?.config?.route === 'security') {
            this.isCustomerPortal = true;
        }
        this.id = 'progressbar-' + Date.now().toString(36) + Math.random().toString(36).substring(2);
    }

    getCurrentProgressBarStatus(value) {
        let counter = 0;
        this.firstProperty = this.secondProperty = this.thirdProperty = this.fourthProperty = this.fifthProperty = false;
        if (value !== undefined) {
            if (/[a-z]/.test(value)) {
                this.firstProperty = true;
                counter++;
            }
            if (/[A-Z]/.test(value)) {
                this.fourthProperty = true;
                counter++;
            }
            if (/_|\W/.test(value)) {
                this.fifthProperty = true;
                counter++;
            }
            if (value?.length >= 6) {
                this.secondProperty = true;
                counter++;
            }
            if (/[0-9]/.test(value)) {
                this.thirdProperty = true;
                counter++;
            }
        }
        switch (counter) {
            case 5:
                return 'w-100 background-green';
            case 4:
                return 'w-80 background-green';
            case 3:
                return 'w-60 background-yellow';
            case 2:
                return 'w-40 background-yellow';
            case 1:
                return 'w-20 background-red';
            default:
                return 'w-0 background-red';
        }
    }
}
