import { Toast } from './models/toast';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class ToastService {
    constructor(private eventAggregator: EventAggregator) {
    }

    async showToast(title, message, toastType) {
        this.eventAggregator.publish('toast', { title: title, message: message, toastType: toastType } as Toast);
    }
}
