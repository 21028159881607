import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class InsuranceService {
    constructor(private api: ApiService) {
        this.api = api;
    }

    async getAllInsurances() {
        return await this.api.doGet('Insurance');
    }

    async getById(id) {
        return await this.api.doGet('Insurance/' + id);
    }
}
