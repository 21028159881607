import './ds-auth-form.scss';
import { autoinject, bindable, observable } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { Router, activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { SessionService } from 'services/session-service';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsAuthForm {
    @bindable params;
    state: string | string[] = 'sign-in';
    routeChangeSubscription;
    @bindable type;

    @observable email;
    @observable newEmail;
    @observable recoveryEmail;
    @observable password;
    @observable newPassword;
    @observable resetPassword;
    @observable confirmPassword;
    @observable token;
    newPasswordValidatorProperty;
    resetPasswordValidatorProperty;
    confirmPasswordValidatorProperty;
    optedInForEmails;
    showGreenCheckMarkEmailRegister;
    showErrorCheckMarkEmailRegister;
    showMiniSpinnerEmailRegister;
    passwordValidRegister;
    passwordInvalidRegister;
    showErrorCheckMarkPasswordRegister;
    showMiniSpinnerPasswordRegister;
    showGreenCheckMarkEmailLogin;
    showErrorCheckMarkEmailLogin;
    showMiniSpinnerEmailLogin;
    passwordValidLogin;
    passwordInvalidLogin;
    showErrorCheckMarkPasswordLogin;
    showMiniSpinnerPasswordLogin;
    showGreenCheckMarkTokenLogin;
    showErrorCheckMarkTokenLogin;
    showMiniSpinnerTokenLogin;
    showGreenCheckMarkRecoveryEmail;
    showErrorCheckMarkRecoveryEmail;
    showMiniSpinnerRecoveryEmail;
    resetPasswordValid;
    resetPasswordInvalid;
    showErrorCheckMarkResetPassword;
    showMiniSpinnerResetPassword;
    confirmPasswordValid;
    confirmPasswordInvalid;
    showErrorCheckMarkConfirmPassword;
    showMiniSpinnerConfirmPassword;
    firedFunction;
    newEmailStopWatch;
    newEmailStopWatch2;
    newEmailFocusOutStopWatch;
    miniSpinnerNewEmailStopwatch;
    newEmailFocusInStopWatch;
    newPasswordStopWatch;
    newPasswordStopWatch2;
    miniSpinnerNewPasswordStopwatch;
    newPasswordFocusInStopWatch;
    newPasswordFocusOutStopWatch;
    emailStopWatch;
    emailStopWatch2;
    miniSpinnerEmailStopwatch;
    emailFocusInStopWatch;
    passwordStopWatch;
    passwordStopWatch2;
    miniSpinnerPasswordStopwatch;
    passwordFocusInStopWatch;
    tokenStopWatch;
    tokenStopWatch2;
    miniSpinnerTokenStopwatch;
    tokenFocusInStopWatch;
    recoveryEmailStopWatch;
    recoveryEmailStopWatch2;
    miniSpinnerRecoveryEmailStopwatch;
    recoveryEmailFocusInStopWatch;
    resetPasswordStopWatch;
    resetPasswordStopWatch2;
    miniSpinnerResetPasswordStopwatch;
    resetPasswordFocusInStopWatch;
    confirmPasswordStopWatch;
    confirmPasswordStopWatch2;
    miniSpinnerConfirmPasswordStopwatch;
    confirmPasswordFocusInStopWatch;
    firedClickFunction;
    loginFailed;
    successFunction;
    toastNewEmailSent;
    toastNewPasswordSent;
    toastEmailSent;
    toastPasswordSent;
    toastTokenSent;
    toastRecoveryEmailSent;
    toastResetPasswordSent;
    toastConfirmPasswordSent;
    isRequesting;
    autoFillTriggered;
    autoFillStopWatch;
    autoFillStopWatch1;
    timeouts;
    mainStopWatch;
    mainStopWatch1;
    visible = false;
    tokenRequired = false;

    showErrorCheckMarkToken;
    showSignIn;
    loading = false;
    signSubscriber;
    termsOfServiceRoute;
    privacyPolicyRoute;

    constructor(
        private router: Router,
        private validator: ValidationController,
        private sessionService: SessionService,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
    }

    async attached() {
        this.state = this.router.currentInstruction?.config?.route;
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.termsOfServiceRoute = pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.privacyPolicyRoute = pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
        if (this.params.accessToken) {
            const valid = await this.customerService.verifyResetPasswordToken(this.params.accessToken, this.params.email);
            if (!valid) {
                this.router.navigate('/expired-link');
            }
        }

        document.getElementById('auth').onclick = async (e: MouseEvent) => {
            const element = e.target as HTMLElement;
            if (element.tagName.toLowerCase() === 'input') {
                this.autoFillTriggered = true;
                this.firedClickFunction = true;
                return;
            }
            const autoFilledUsername = document.querySelector('#username-input input:-webkit-autofill');
            const autoFilledPassword = document.querySelector('#password-input input:-webkit-autofill');
            this.state = this.router.currentInstruction?.config?.route;
            if (!this.firedClickFunction && this.state === 'sign-in' && this.email && this.password && autoFilledUsername && autoFilledPassword) {
                this.firedClickFunction = true;
                this.firedFunction = true;
                this.mainStopWatch = setTimeout(() => {
                    this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
                    this.mainStopWatch1 = setTimeout(async () => {
                        await this.login();
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                        }
                    }, 1000);
                }, 1000);
            }
        };

        this.handleEventSubscriptions();
    }

    detached() {
        this.signSubscriber?.dispose();
        this.routeChangeSubscription?.dispose();
    }

    handleEventSubscriptions() {
        this.signSubscriber = this.eventAggregator.subscribe('pressed-back', () => {
            this.tokenRequiredUpdate();
        });

        this.routeChangeSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.state = this.router.currentInstruction?.config?.route;
            this.email = undefined;
            this.newEmail = undefined;
            this.password = undefined;
            this.newPassword = undefined;
            this.recoveryEmail = undefined;
            this.resetPassword = undefined;
            this.confirmPassword = undefined;
            this.passwordValidRegister = undefined;
            this.passwordInvalidRegister = undefined;
            this.showErrorCheckMarkPasswordRegister = undefined;
            this.showMiniSpinnerPasswordRegister = undefined;
            this.showGreenCheckMarkEmailRegister = undefined;
            this.showErrorCheckMarkEmailRegister = undefined;
            this.showMiniSpinnerEmailRegister = undefined;
            this.passwordValidLogin = undefined;
            this.passwordInvalidLogin = undefined;
            this.showErrorCheckMarkPasswordLogin = undefined;
            this.showMiniSpinnerPasswordLogin = undefined;
            this.showGreenCheckMarkEmailLogin = undefined;
            this.showErrorCheckMarkEmailLogin = undefined;
            this.showMiniSpinnerEmailLogin = undefined;
            this.showGreenCheckMarkRecoveryEmail = undefined;
            this.showErrorCheckMarkRecoveryEmail = undefined;
            this.showMiniSpinnerRecoveryEmail = undefined;
            this.resetPasswordValid = undefined;
            this.resetPasswordInvalid = undefined;
            this.showErrorCheckMarkResetPassword = undefined;
            this.showMiniSpinnerResetPassword = undefined;
            this.confirmPasswordValid = undefined;
            this.confirmPasswordInvalid = undefined;
            this.showErrorCheckMarkConfirmPassword = undefined;
            this.showMiniSpinnerConfirmPassword = undefined;
            this.newPasswordValidatorProperty = undefined;
            this.resetPasswordValidatorProperty = undefined;
            this.confirmPasswordValidatorProperty = undefined;
            this.firedFunction = undefined;
            this.successFunction = undefined;
            this.toastNewEmailSent = undefined;
            this.toastNewPasswordSent = undefined;
            this.toastEmailSent = undefined;
            this.toastPasswordSent = undefined;
            this.toastTokenSent = undefined;
            this.toastRecoveryEmailSent = undefined;
            this.toastResetPasswordSent = undefined;
            this.toastConfirmPasswordSent = undefined;
            this.newEmailFocusInStopWatch = undefined;
            this.newPasswordFocusInStopWatch = undefined;
            this.emailFocusInStopWatch = undefined;
            this.passwordFocusInStopWatch = undefined;
            this.newEmailFocusOutStopWatch = undefined;
            this.newPasswordFocusOutStopWatch = undefined;
            this.tokenFocusInStopWatch = undefined;
            this.recoveryEmailFocusInStopWatch = undefined;
            this.resetPasswordFocusInStopWatch = undefined;
            this.confirmPasswordFocusInStopWatch = undefined;
            this.isRequesting = undefined;
            this.autoFillTriggered = undefined;
            this.autoFillStopWatch = undefined;
            this.autoFillStopWatch1 = undefined;
            this.timeouts = undefined;
            this.validator.reset();
        });
    }

    determineActivationStrategy() {
        return activationStrategy.replace;
    }

    tokenRequiredUpdate() {
        this.tokenRequired = false;
    }

    async showToast(title: string, message: string, toastType: string) {
        await this.toastService.showToast(title, message, toastType);
    }

    validatorCheckOneCondition(field, results) {
        for (let i = 0; i < results.length; i++) {
            if (results[i].propertyName === field) {
                if (!results[i].valid) {
                    return false;
                }
            }
        }
        return true;
    }

    async optedInForEmailsOnClick() {
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.newPasswordFocusOutStopWatch, this.miniSpinnerNewPasswordStopwatch,
            this.newPasswordFocusInStopWatch, this.newEmailStopWatch, this.newEmailStopWatch2, this.newEmailFocusOutStopWatch, this.miniSpinnerNewEmailStopwatch,
            this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister && !this.firedFunction) {
            this.firedFunction = true;
            await this.register();
        }
    }

    async newEmailUpdatedOnKeyPress(event) {
        this.showGreenCheckMarkEmailRegister = this.showErrorCheckMarkEmailRegister = this.showMiniSpinnerEmailRegister = this.toastNewEmailSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.newEmailStopWatch, this.newEmailStopWatch2, this.miniSpinnerNewEmailStopwatch, this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.newPassword !== undefined) {
            ValidationRules
                .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
            this.validator.validate();
        } else if (event?.key === 'Enter') {
            this.checkNewEmailValidation();
            return;
        } else if (this.newEmail !== undefined) {
            this.miniSpinnerNewEmailStopwatch = setTimeout(() => {
                this.showMiniSpinnerEmailRegister = true;
            }, 1000);
            this.newEmailStopWatch = setTimeout(async() => {
                if (this.newPassword !== undefined) {
                    ValidationRules
                        .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                        .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
                } else {
                    ValidationRules
                        .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
                }
                const rules = await this.validator.validate();
                this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
                if (this.showGreenCheckMarkEmailRegister) {
                    this.showMiniSpinnerEmailRegister = false;
                }
                if (this.password !== undefined) {
                    this.passwordValidRegister = this.validatorCheckOneCondition('newPassword', rules.results);
                    this.passwordInvalidRegister = !this.passwordValidRegister;
                    this.showErrorCheckMarkPasswordRegister = !this.passwordValidRegister;
                }
                if (!this.showGreenCheckMarkEmailRegister) {
                    await this.validator.reset();
                    if (this.newPassword !== undefined) {
                        ValidationRules
                            .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                            .on(this);
                        this.validator.validate();
                    }
                    this.newEmailStopWatch2 = setTimeout(async() => {
                        if (this.newPassword !== undefined) {
                            ValidationRules
                                .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                                .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
                        } else {
                            ValidationRules
                                .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerEmailRegister = false;
                        this.showErrorCheckMarkEmailRegister = !this.validatorCheckOneCondition('newEmail', rules2.results);
                        this.toastNewEmailSent = true;
                        await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister) {
                    this.firedFunction = true;
                    await this.register();
                }
            }, 2000);
        }
    }

    newEmailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmailRegister = this.showErrorCheckMarkEmailRegister = this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastNewEmailSent = false;
        this.validator.reset();
        if (this.newPassword !== undefined) {
            ValidationRules
                .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
            this.validator.validate();
        }
        this.newEmailFocusInStopWatch = setTimeout(() => {
            if (this.newEmail !== undefined) {
                this.newEmailUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkNewEmailValidation() {
        this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
        this.timeouts = [this.newEmailStopWatch, this.newEmailStopWatch2, this.miniSpinnerNewEmailStopwatch, this.newEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.newEmail !== undefined) {
            if (this.newPassword !== undefined) {
                ValidationRules
                    .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
            } else {
                ValidationRules
                    .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
            }
            const rules = await this.validator.validate();
            this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = false;
            this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
            this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
            if (!this.showGreenCheckMarkEmailRegister && !this.toastNewEmailSent) {
                await this.toastService.showToast('Error', 'Please enter a valid email address', 'error');
            }

            if (this.password !== undefined) {
                this.passwordValidRegister = this.validatorCheckOneCondition('newPassword', rules.results);
                this.passwordInvalidRegister = !this.passwordValidRegister;
                this.showErrorCheckMarkPasswordRegister = !this.passwordValidRegister;
            }

            if (this.passwordValidRegister && this.showGreenCheckMarkEmailRegister && !this.firedFunction) {
                this.newEmailFocusOutStopWatch = setTimeout(async () => {
                    this.firedFunction = true;
                    await this.register();
                }, 1000);
            }
        }
    }

    async newPasswordUpdatedOnKeyPress(event) {
        this.showMiniSpinnerPasswordRegister = this.toastNewPasswordSent = this.firedFunction = this.successFunction = false;
        if (this.newEmail !== undefined) {
            ValidationRules
                .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
        } else {
            ValidationRules.ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/).on(this);
        }
        this.newPasswordValidatorProperty = await this.validator.validate();
        this.passwordValidRegister = false;
        this.passwordInvalidRegister = false;
        this.showErrorCheckMarkPasswordRegister = false;
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkNewPasswordValidation();
            return;
        } else {
            if (this.newPassword !== undefined) {
                this.miniSpinnerNewPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerPasswordRegister = true;
                }, 1000);
                this.newPasswordStopWatch = setTimeout(async() => {
                    let counterValidationsNewPasswordTwo = 0;
                    if (this.newPassword) {
                        for (const validations of this.newPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'newPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsNewPasswordTwo++;
                            }
                        }
                        if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsNewPasswordTwo = 0;
                        }
                    }
                    if (this.newEmail !== undefined) {
                        this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', this.newPasswordValidatorProperty.results);
                        this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
                    }
                    if (counterValidationsNewPasswordTwo >= 3 && this.newPasswordValidatorProperty.results.find(x => x.propertyName === 'newPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.passwordValidRegister = true;
                        this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = false;
                        if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister) {
                            this.firedFunction = true;
                            await this.register();
                        }
                    } else {
                        this.newPasswordStopWatch2 = setTimeout(async() => {
                            this.passwordValidRegister = this.showMiniSpinnerPasswordRegister = false;
                            this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.toastNewPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async newPasswordUpdatedOnFocusIn() {
        this.passwordValidRegister = this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastNewPasswordSent = false;
        this.validator.reset();
        if (this.email !== undefined) {
            ValidationRules
                .ensure('newEmail').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .on(this);
            const rules = await this.validator.validate();
            this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', rules.results);
            this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
        }
        this.newPasswordFocusInStopWatch = setTimeout(() => {
            if (this.newPassword !== undefined) {
                this.newPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkNewPasswordValidation() {
        this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
        this.timeouts = [this.newPasswordStopWatch, this.newPasswordStopWatch2, this.miniSpinnerNewPasswordStopwatch, this.newPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.newPassword !== undefined) {
            if (this.newEmail !== undefined) {
                ValidationRules
                    .ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .ensure('newEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).on(this);
            } else {
                ValidationRules.ensure('newPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/).on(this);
            }
            this.newPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsNewPassword = 0;
            if (this.newPassword) {
                for (const validations of this.newPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'newPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsNewPassword++;
                    }
                }
            }
            if (!this.newPasswordValidatorProperty.results.find(x => x.rule.messageKey === 'maxLength').valid) {
                counterValidationsNewPassword = 0;
            }

            if (this.newEmail !== undefined) {
                this.showGreenCheckMarkEmailRegister = this.validatorCheckOneCondition('newEmail', this.newPasswordValidatorProperty.results);
                this.showErrorCheckMarkEmailRegister = !this.showGreenCheckMarkEmailRegister;
            }

            if (counterValidationsNewPassword >= 3 && this.newPasswordValidatorProperty.results.find(x => x.propertyName === 'newPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
                this.passwordValidRegister = true;
                if (this.showGreenCheckMarkEmailRegister && this.passwordValidRegister && !this.firedFunction) {
                    this.newPasswordFocusOutStopWatch = setTimeout(async () => {
                        this.firedFunction = true;
                        await this.register();
                    }, 1000);
                }
            } else if (!this.successFunction) {
                this.passwordValidRegister = this.showMiniSpinnerPasswordRegister = this.showMiniSpinnerEmailRegister = false;
                this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = true;
                if (!this.toastNewPasswordSent && !this.showSignIn) {
                    await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                }
            }
        }
    }

    async emailUpdatedOnKeyPress(event) {
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!event?.keyCode && !this.autoFillTriggered) {
            return;
        } else if (event?.key === 'Enter') {
            this.checkEmailValidation();
            return;
        } else if (!event.keyCode && this.autoFillTriggered) {
            if (!this.firedFunction) {
                this.autoFillStopWatch = setTimeout(() => {
                    this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = true;
                    this.autoFillStopWatch1 = setTimeout(async() => {
                        this.firedFunction = true;
                        await this.login();
                        if (this.successFunction) {
                            this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = true;
                        }
                    }, 1000);
                }, 1000);
            }
            return;
        }
        this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.showMiniSpinnerEmailLogin = this.toastEmailSent = this.firedFunction = this.successFunction = false;
        await this.validator.reset();
        if (this.password !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.password !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.password === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        if (this.email !== undefined) {
            this.miniSpinnerEmailStopwatch = setTimeout(() => {
                this.showMiniSpinnerEmailLogin = true;
            }, 1000);
            this.emailStopWatch = setTimeout(async() => {
                if (this.password !== undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.password !== undefined && this.token === undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .on(this);
                } else if (this.password === undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .on(this);
                }
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('email', rules.results);
                let checkPasswordValidation;
                let checkTokenValidation;
                if (this.password !== undefined && this.token !== undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                } else if (this.password !== undefined && this.token === undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                } else if (this.password === undefined && this.token !== undefined) {
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                }
                if (!checkValidation) {
                    await this.validator.reset();
                    if (this.password !== undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    } else if (this.password !== undefined && this.token === undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    } else if (this.password === undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    }
                    this.emailStopWatch2 = setTimeout(async() => {
                        if (this.password !== undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.password !== undefined && this.token === undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .on(this);
                        } else if (this.password === undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerEmailLogin = false;
                        this.showErrorCheckMarkEmailLogin = !this.validatorCheckOneCondition('email', rules2.results);
                        this.toastEmailSent = true;
                        await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    }, 2000);
                } else if (this.tokenRequired && this.showGreenCheckMarkTokenLogin && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login();
                } else if (!this.tokenRequired && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login();
                }
                if (checkValidation && this.tokenRequired && (this.successFunction || !this.password || !this.token || checkPasswordValidation || checkTokenValidation)) {
                    this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                    this.showGreenCheckMarkEmailLogin = true;
                } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.password || checkPasswordValidation)) {
                    this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                    this.showGreenCheckMarkEmailLogin = true;
                }
            }, 2000);
        }
    }

    emailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmailLogin = this.showErrorCheckMarkEmailLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastEmailSent = false;
        this.validator.reset();
        if (this.password !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.password !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.password === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        this.emailFocusInStopWatch = setTimeout(() => {
            if (this.email !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.emailUpdatedOnKeyPress(event);
            }
        });
    }

    async checkEmailValidation() {
        this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopwatch, this.emailFocusInStopWatch, this.autoFillStopWatch, this.autoFillStopWatch1, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.email !== undefined) {
            if (this.password !== undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.password !== undefined && this.token === undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .on(this);
            } else if (this.password === undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerTokenLogin = false;
            const checkValidation = this.validatorCheckOneCondition('email', rules.results);

            if (!checkValidation && !this.toastEmailSent) {
                this.showErrorCheckMarkEmailLogin = true;
                await this.toastService.showToast('Error', 'Please enter a valid email address', 'error');
            }

            let checkPasswordValidation;
            let checkTokenValidation;
            if (this.password !== undefined && this.token !== undefined) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.passwordInvalidLogin;
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            } else if (this.password !== undefined && this.token === undefined) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.passwordInvalidLogin;
            } else if (this.password === undefined && this.token !== undefined) {
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            }

            if (this.tokenRequired && this.showGreenCheckMarkTokenLogin && this.passwordValidLogin && checkValidation && !this.firedFunction) {
                await this.login();
            } else if (!this.tokenRequired && this.passwordValidLogin && checkValidation && !this.firedFunction) {
                await this.login();
            }

            if (checkValidation && this.tokenRequired && (this.successFunction || !this.password || !this.token || checkPasswordValidation || checkTokenValidation)) {
                this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                this.showGreenCheckMarkEmailLogin = true;
            } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.password || checkPasswordValidation)) {
                this.showMiniSpinnerEmailLogin = this.showErrorCheckMarkEmailLogin = false;
                this.showGreenCheckMarkEmailLogin = true;
            }
        }
    }

    async passwordUpdatedOnKeyPress(event) {
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch, this.mainStopWatch, this.mainStopWatch1];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (!event?.keyCode && !this.autoFillTriggered) {
            return;
        } else if (event?.key === 'Enter') {
            this.checkPasswordValidation();
            return;
        } else if (!event?.keyCode && this.autoFillTriggered) {
            return;
        }
        if (this.loginFailed) {
            this.loginFailed = false;
            this.showGreenCheckMarkEmailLogin = true;
            this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
            this.showMiniSpinnerEmailLogin = false;
        }
        this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.toastPasswordSent = this.firedFunction = this.successFunction = false;
        await this.validator.reset();
        if (this.email !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        } else if (this.email !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (this.email === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            this.validator.validate();
        }
        if (this.password !== undefined) {
            this.miniSpinnerPasswordStopwatch = setTimeout(() => {
                this.showMiniSpinnerPasswordLogin = true;
            }, 1000);
            this.passwordStopWatch = setTimeout(async() => {
                if (this.email !== undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.email !== undefined && this.token === undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .on(this);
                } else if (this.email === undefined && this.token !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                        .on(this);
                }
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('password', rules.results);
                let checkEmailValidation;
                let checkTokenValidation;
                if (this.email !== undefined && this.token !== undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                } else if (this.email !== undefined && this.token === undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                } else if (this.email === undefined && this.token !== undefined) {
                    this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                    this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                    checkTokenValidation = this.showErrorCheckMarkTokenLogin;
                }
                if (!checkValidation) {
                    await this.validator.reset();
                    if (this.email !== undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    } else if (this.email !== undefined && this.token === undefined) {
                        ValidationRules
                            .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                            .on(this);
                        this.validator.validate();
                    } else if (this.email === undefined && this.token !== undefined) {
                        ValidationRules
                            .ensure('token').required().minLength(6).maxLength(6)
                            .on(this);
                        this.validator.validate();
                    }
                    this.passwordStopWatch2 = setTimeout(async() => {
                        if (this.email !== undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.email !== undefined && this.token === undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .on(this);
                        } else if (this.email === undefined && this.token !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('Password should be at least 6 characters').maxLength(100)
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                        this.passwordInvalidLogin = !this.validatorCheckOneCondition('password', rules2.results);
                        this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                        this.toastPasswordSent = true;
                        await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login(this.tokenRequired);
                }
                if (checkValidation && this.tokenRequired && (this.successFunction || !this.email || !this.token || checkEmailValidation || checkTokenValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.email || checkEmailValidation)) {
                    this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                    this.passwordValidLogin = true;
                }
            }, 2000);
        }
    }

    async passwordUpdatedOnFocusIn() {
        this.passwordValidLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastPasswordSent = false;
        this.validator.reset();
        let rules;
        if (this.email !== undefined && this.token !== undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            rules = await this.validator.validate();
        } else if (this.email !== undefined && this.token === undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .on(this);
            rules = await this.validator.validate();
        } else if (this.email === undefined && this.token !== undefined) {
            ValidationRules
                .ensure('token').required().minLength(6).maxLength(6)
                .on(this);
            rules = await this.validator.validate();
        }

        if (this.email !== undefined && this.token !== undefined) {
            this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
            this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
            this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
            this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
        } else if (this.email !== undefined && this.token === undefined) {
            this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
            this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
        } else if (this.email === undefined && this.token !== undefined) {
            this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
            this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
        }

        this.passwordFocusInStopWatch = setTimeout(() => {
            if (this.password !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.passwordUpdatedOnKeyPress(event);
            }
        });
    }

    async checkPasswordValidation() {
        this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopwatch, this.passwordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.password !== undefined) {
            if (this.email !== undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.email !== undefined && this.token === undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .on(this);
            } else if (this.email === undefined && this.token !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerTokenLogin = false;
            const checkValidation = this.validatorCheckOneCondition('password', rules.results);

            if ((this.password.length === 0 || !checkValidation) && !this.toastPasswordSent) {
                this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = true;
                await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
            }

            let checkEmailValidation;
            let checkTokenValidation;
            if (this.email !== undefined && this.token !== undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            } else if (this.email !== undefined && this.token === undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
            } else if (this.email === undefined && this.token !== undefined) {
                this.showGreenCheckMarkTokenLogin = this.validatorCheckOneCondition('token', rules.results);
                this.showErrorCheckMarkTokenLogin = !this.showGreenCheckMarkTokenLogin;
                checkTokenValidation = this.showErrorCheckMarkTokenLogin;
            }

            if (this.showGreenCheckMarkEmailLogin && checkValidation && !this.firedFunction) {
                await this.login(this.tokenRequired);
            }

            if (checkValidation && this.tokenRequired && (this.successFunction || !this.email || !this.token || checkEmailValidation || checkTokenValidation)) {
                this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                this.passwordValidLogin = true;
            } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.email || checkEmailValidation)) {
                this.showMiniSpinnerPasswordLogin = this.showErrorCheckMarkPasswordLogin = false;
                this.passwordValidLogin = true;
            } else if (!this.successFunction && !this.tokenRequired) {
                this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = true;
            }
        }
    }

    async tokenUpdatedOnKeyPress(event) {
        if (!event?.keyCode) {
            return;
        }
        this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkPasswordLogin = this.toastTokenSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (this.email !== undefined && this.password !== undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.email !== undefined && this.password === undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (this.email === undefined && this.password !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .on(this);
            this.validator.validate();
        }
        if (this.token !== undefined) {
            this.miniSpinnerTokenStopwatch = setTimeout(() => {
                this.showMiniSpinnerTokenLogin = true;
            }, 1000);
            this.tokenStopWatch = setTimeout(async () => {
                if (this.email !== undefined && this.password !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.email !== undefined && this.password === undefined) {
                    ValidationRules
                        .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else if (this.email === undefined && this.password !== undefined) {
                    ValidationRules
                        .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                } else {
                    ValidationRules
                        .ensure('token').required().minLength(6).maxLength(6)
                        .on(this);
                }
                const rules = await this.validator.validate();
                const checkValidation = this.validatorCheckOneCondition('token', rules.results);
                let checkEmailValidation;
                let checkPasswordValidation;
                if (this.email !== undefined && this.password !== undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                } else if (this.email !== undefined && this.password === undefined) {
                    this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                    this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                    checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                } else if (this.email === undefined && this.password !== undefined) {
                    this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                    this.passwordInvalidLogin = !this.passwordValidLogin;
                    this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                    checkPasswordValidation = this.passwordInvalidLogin;
                }
                if (!checkValidation) {
                    await this.validator.reset();
                    if (this.email !== undefined && this.password !== undefined) {
                        ValidationRules
                            .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                            .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    } else if (this.email !== undefined && this.password === undefined) {
                        ValidationRules
                            .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                            .on(this);
                        this.validator.validate();
                    } else if (this.email === undefined && this.password !== undefined) {
                        ValidationRules
                            .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                            .on(this);
                        this.validator.validate();
                    }
                    this.tokenStopWatch2 = setTimeout(async () => {
                        if (this.email !== undefined && this.password !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.email !== undefined && this.password === undefined) {
                            ValidationRules
                                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else if (this.email === undefined && this.password !== undefined) {
                            ValidationRules
                                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        } else {
                            ValidationRules
                                .ensure('token').required().minLength(6).maxLength(6)
                                .on(this);
                        }
                        const rules2 = await this.validator.validate();
                        this.showMiniSpinnerTokenLogin = false;
                        this.showErrorCheckMarkTokenLogin = !this.validatorCheckOneCondition('token', rules2.results);
                        this.toastTokenSent = true;
                        await this.toastService.showToast('Please enter a valid token', 'Entered token is incorrect or outdated.', 'error');
                    }, 2000);
                } else if (this.showGreenCheckMarkEmailLogin && this.passwordValidLogin && checkValidation) {
                    this.firedFunction = true;
                    await this.login();
                }
                if (checkValidation && (this.successFunction || !this.password || !this.email || checkEmailValidation || checkPasswordValidation)) {
                    this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkTokenLogin = false;
                    this.showGreenCheckMarkTokenLogin = true;
                }
            }, 2000);
        }
    }

    tokenUpdatedOnFocusIn() {
        this.showGreenCheckMarkTokenLogin = this.showErrorCheckMarkTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = false;
        this.firedFunction = false;
        this.successFunction = false;
        this.toastTokenSent = false;
        this.validator.reset();
        if (this.email !== undefined && this.password !== undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .on(this);
            this.validator.validate();
        } else if (this.email !== undefined && this.password === undefined) {
            ValidationRules
                .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                .on(this);
            this.validator.validate();
        } else if (this.email === undefined && this.password !== undefined) {
            ValidationRules
                .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                .on(this);
            this.validator.validate();
        }

        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.token !== undefined) {
                const event = {
                    keyCode: 'focusin'
                };
                this.tokenUpdatedOnKeyPress(event);
            }
        });
    }

    async checkTokenValidation() {
        this.showMiniSpinnerTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopwatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.token !== undefined) {
            if (this.email !== undefined && this.password !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.email !== undefined && this.password === undefined) {
                ValidationRules
                    .ensure('email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.')
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else if (this.email === undefined && this.password !== undefined) {
                ValidationRules
                    .ensure('password').required().minLength(6).withMessage('password should be at least 6 digits').maxLength(100)
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('token').required().minLength(6).maxLength(6)
                    .on(this);
            }
            const rules = await this.validator.validate();
            this.showErrorCheckMarkPasswordLogin = this.showMiniSpinnerTokenLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = false;
            const checkValidation = this.validatorCheckOneCondition('token', rules.results);

            if (!checkValidation && !this.toastTokenSent) {
                this.showErrorCheckMarkTokenLogin = true;
                await this.toastService.showToast('Please enter a valid token', 'Entered token is incorrect or outdated.', 'error');
            }

            let checkEmailValidation;
            let checkPasswordValidation;
            if (this.email !== undefined && this.password !== undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.showErrorCheckMarkPasswordLogin;
            } else if (this.email !== undefined && this.password === undefined) {
                this.showGreenCheckMarkEmailLogin = this.validatorCheckOneCondition('email', rules.results);
                this.showErrorCheckMarkEmailLogin = !this.showGreenCheckMarkEmailLogin;
                checkEmailValidation = this.showErrorCheckMarkEmailLogin;
            } else if (this.email === undefined && this.password !== undefined) {
                this.passwordValidLogin = this.validatorCheckOneCondition('password', rules.results);
                this.passwordInvalidLogin = !this.passwordValidLogin;
                this.showErrorCheckMarkPasswordLogin = !this.passwordValidLogin;
                checkPasswordValidation = this.showErrorCheckMarkPasswordLogin;
            }

            if (this.showGreenCheckMarkEmailLogin && this.passwordValidLogin && checkValidation && !this.firedFunction) {
                await this.login();
            }

            if (checkValidation && (this.successFunction || !this.password || !this.email || checkEmailValidation || checkPasswordValidation)) {
                this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkTokenLogin = false;
                this.showGreenCheckMarkTokenLogin = true;
            } else {
                this.showErrorCheckMarkToken = true;
            }
        }
    }

    async recoveryEmailUpdatedOnKeyPress(event) {
        this.showGreenCheckMarkRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.toastRecoveryEmailSent = this.firedFunction = this.successFunction = false;
        this.timeouts = [this.recoveryEmailStopWatch, this.recoveryEmailStopWatch2, this.miniSpinnerRecoveryEmailStopwatch, this.recoveryEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        await this.validator.reset();
        if (event?.key === 'Enter') {
            this.checkRecoveryEmailValidation();
            return;
        } else {
            if (this.recoveryEmail !== undefined) {
                this.miniSpinnerRecoveryEmailStopwatch = setTimeout(() => {
                    this.showMiniSpinnerRecoveryEmail = true;
                }, 1000);
                this.recoveryEmailStopWatch = setTimeout(async() => {
                    ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.').on(this);
                    const rules = await this.validator.validate();
                    const checkValidation = this.validatorCheckOneCondition('recoveryEmail', rules.results);
                    if (!checkValidation) {
                        await this.validator.reset();
                        this.recoveryEmailStopWatch2 = setTimeout(async() => {
                            ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.').on(this);
                            const rules2 = await this.validator.validate();
                            this.showMiniSpinnerRecoveryEmail = false;
                            this.showErrorCheckMarkRecoveryEmail = !this.validatorCheckOneCondition('recoveryEmail', rules2.results);
                            this.toastRecoveryEmailSent = true;
                            await this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                        }, 2000);
                    } else if (checkValidation) {
                        this.firedFunction = true;
                        await this.sendEmailReset();
                    }
                    if (checkValidation && this.successFunction) {
                        this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                        this.showGreenCheckMarkRecoveryEmail = true;
                    }
                }, 2000);
            }
        }
    }

    recoveryEmailUpdatedOnFocusIn() {
        this.showGreenCheckMarkRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.successFunction = this.showSignIn = false;
        this.firedFunction = false;
        this.toastRecoveryEmailSent = false;
        this.validator.reset();
        this.recoveryEmailFocusInStopWatch = setTimeout(() => {
            if (this.recoveryEmail !== undefined) {
                this.recoveryEmailUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkRecoveryEmailValidation() {
        this.showMiniSpinnerRecoveryEmail = false;
        this.successFunction = false;
        this.timeouts = [this.recoveryEmailStopWatch, this.recoveryEmailStopWatch2, this.miniSpinnerRecoveryEmailStopwatch, this.recoveryEmailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.recoveryEmail !== undefined) {
            ValidationRules.ensure('recoveryEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/).withMessage('Please enter a valid email.').on(this);
            const rules = await this.validator.validate();
            this.showMiniSpinnerRecoveryEmail = false;
            const checkValidation = this.validatorCheckOneCondition('recoveryEmail', rules.results);

            if (!checkValidation && !this.toastRecoveryEmailSent) {
                this.showErrorCheckMarkRecoveryEmail = true;
                await this.toastService.showToast('Error', 'Please enter a valid email address', 'error');
            }

            if (checkValidation && !this.firedFunction && !this.showSignIn) {
                await this.sendEmailReset();
            }

            if (checkValidation && this.successFunction) {
                this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                this.showGreenCheckMarkRecoveryEmail = true;
            }
        }
    }

    async resetPasswordUpdatedOnKeyPress(event) {
        this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.toastResetPasswordSent = this.firedFunction = this.successFunction = false;
        if (this.confirmPassword !== undefined) {
            ValidationRules
                .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
        } else {
            ValidationRules
                .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
        }
        this.resetPasswordValidatorProperty = await this.validator.validate();
        this.resetPasswordValid = false;
        this.resetPasswordInvalid = false;
        this.showErrorCheckMarkResetPassword = false;
        this.timeouts = [this.resetPasswordStopWatch, this.resetPasswordStopWatch2, this.miniSpinnerResetPasswordStopwatch, this.resetPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkResetPasswordValidation();
            return;
        } else {
            if (this.resetPassword !== undefined) {
                this.miniSpinnerResetPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerResetPassword = true;
                }, 1000);
                this.resetPasswordStopWatch = setTimeout(async() => {
                    let counterValidationsResetPasswordTwo = 0;
                    if (this.resetPassword) {
                        for (const validations of this.resetPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsResetPasswordTwo++;
                            }
                        }
                        if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsResetPasswordTwo = 0;
                        }
                    }
                    if (this.confirmPassword !== undefined && this.confirmPassword !== '') {
                        let counterValidationsConfirmPassword = 0;
                        for (const validations of this.resetPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsConfirmPassword++;
                            }
                        }
                        if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsConfirmPassword = 0;
                        }
                        if (counterValidationsConfirmPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                            this.confirmPasswordValid = true;
                            this.confirmPasswordInvalid = !this.confirmPasswordValid;
                            this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                        } else {
                            this.confirmPasswordValid = false;
                            this.confirmPasswordInvalid = !this.confirmPasswordValid;
                            this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                        }
                    } else if (this.confirmPassword === '') {
                        this.confirmPasswordValid = false;
                        this.confirmPasswordInvalid = !this.confirmPasswordValid;
                        this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                    }
                    if (counterValidationsResetPasswordTwo >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.resetPasswordValid = true;
                        this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = false;
                        if (this.confirmPasswordValid && this.resetPasswordValid) {
                            this.firedFunction = true;
                            await this.updatePassword();
                        }
                    } else {
                        this.resetPasswordStopWatch2 = setTimeout(async () => {
                            this.resetPasswordValid = this.showMiniSpinnerResetPassword = false;
                            this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.toastResetPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid password', 'Entered password does not match the expected structure.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async resetPasswordUpdatedOnFocusIn() {
        this.resetPasswordInvalid = this.resetPasswordValid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.successFunction = false;
        this.firedFunction = false;
        this.toastResetPasswordSent = false;
        this.validator.reset();
        this.resetPasswordFocusInStopWatch = setTimeout(() => {
            if (this.resetPassword !== undefined) {
                this.resetPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkResetPasswordValidation() {
        this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.showErrorCheckMarkResetPassword = false;
        this.timeouts = [this.resetPasswordStopWatch, this.resetPasswordStopWatch2, this.miniSpinnerResetPasswordStopwatch, this.resetPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.resetPassword !== undefined) {
            if (this.confirmPassword !== undefined) {
                ValidationRules
                    .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .on(this);
            }
            this.resetPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsResetPassword = 0;
            if (this.resetPassword) {
                for (const validations of this.resetPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsResetPassword++;
                    }
                }

                if (!this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsResetPassword = 0;
                }
            }

            if (this.confirmPassword !== undefined && this.confirmPassword !== '') {
                let counterValidationsConfirmPassword = 0;
                for (const validations of this.resetPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsConfirmPassword++;
                    }
                }
                if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsConfirmPassword = 0;
                }
                if (counterValidationsConfirmPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                    this.confirmPasswordValid = true;
                    this.confirmPasswordInvalid = !this.confirmPasswordValid;
                    this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                } else {
                    this.confirmPasswordValid = false;
                    this.confirmPasswordInvalid = !this.confirmPasswordValid;
                    this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
                }
            } else if (this.confirmPassword === '') {
                this.confirmPasswordValid = false;
                this.confirmPasswordInvalid = !this.confirmPasswordValid;
                this.showErrorCheckMarkConfirmPassword = !this.confirmPasswordValid;
            }

            if (counterValidationsResetPassword >= 3 && this.resetPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = false;
                this.resetPasswordValid = true;
                if (this.confirmPasswordValid && this.resetPasswordValid && !this.firedFunction) {
                    await this.updatePassword();
                }
            } else if (!this.successFunction) {
                this.resetPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = false;
                this.resetPasswordInvalid = this.showErrorCheckMarkResetPassword = true;
                if (!this.toastResetPasswordSent) {
                    await this.toastService.showToast('Please enter a valid confirmation password', 'Entered password does not match the expected value.', 'error');
                }
            }
        }
    }

    async confirmPasswordUpdatedOnKeyPress(event) {
        this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.toastConfirmPasswordSent = this.firedFunction = this.successFunction = false;
        if (this.resetPassword !== undefined) {
            ValidationRules
                .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
        } else {
            ValidationRules
                .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                .on(this);
        }
        this.confirmPasswordValidatorProperty = await this.validator.validate();
        this.confirmPasswordValid = false;
        this.confirmPasswordInvalid = false;
        this.showErrorCheckMarkConfirmPassword = false;
        this.timeouts = [this.confirmPasswordStopWatch, this.confirmPasswordStopWatch2, this.miniSpinnerConfirmPasswordStopwatch, this.confirmPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (event?.key === 'Enter') {
            this.checkConfirmPasswordValidation();
            return;
        } else {
            if (this.confirmPassword !== undefined) {
                this.miniSpinnerConfirmPasswordStopwatch = setTimeout(() => {
                    this.showMiniSpinnerConfirmPassword = true;
                }, 1000);
                this.confirmPasswordStopWatch = setTimeout(async() => {
                    let counterValidationsConfirmPasswordTwo = 0;
                    if (this.confirmPassword) {
                        for (const validations of this.confirmPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsConfirmPasswordTwo++;
                            }
                        }
                        if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsConfirmPasswordTwo = 0;
                        }
                    }
                    if (this.resetPassword !== undefined && this.resetPassword !== '') {
                        let counterValidationsResetPassword = 0;
                        for (const validations of this.confirmPasswordValidatorProperty.results) {
                            if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                                counterValidationsResetPassword++;
                            }
                        }
                        if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                            counterValidationsResetPassword = 0;
                        }
                        if (counterValidationsResetPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                            this.resetPasswordValid = true;
                            this.resetPasswordInvalid = !this.resetPasswordValid;
                            this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                        } else {
                            this.resetPasswordValid = false;
                            this.resetPasswordInvalid = !this.resetPasswordValid;
                            this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                        }
                    } else if (this.resetPassword === '') {
                        this.resetPasswordValid = false;
                        this.resetPasswordInvalid = !this.resetPasswordValid;
                        this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                    }
                    if (counterValidationsConfirmPasswordTwo >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                        this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                        this.confirmPasswordValid = true;
                        if (this.resetPasswordValid && this.confirmPasswordValid) {
                            this.firedFunction = true;
                            await this.updatePassword();
                        }
                    } else {
                        this.confirmPasswordStopWatch2 = setTimeout(async () => {
                            this.confirmPasswordValid = this.showMiniSpinnerConfirmPassword = false;
                            this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.toastConfirmPasswordSent = true;
                            await this.toastService.showToast('Please enter a valid confirm password', 'Entered password does not match the expected value.', 'error');
                        }, 2000);
                    }
                }, 2000);
            }
        }
    }

    async confirmPasswordUpdatedOnFocusIn() {
        this.confirmPasswordInvalid = this.confirmPasswordValid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
        this.firedFunction = false;
        this.toastConfirmPasswordSent = false;
        this.validator.reset();
        this.confirmPasswordFocusInStopWatch = setTimeout(() => {
            if (this.confirmPassword !== undefined) {
                this.confirmPasswordUpdatedOnKeyPress({ key: 'Enter' });
            }
        });
    }

    async checkConfirmPasswordValidation() {
        this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = this.showErrorCheckMarkConfirmPassword = false;
        this.timeouts = [this.confirmPasswordStopWatch, this.confirmPasswordStopWatch2, this.miniSpinnerConfirmPasswordStopwatch, this.confirmPasswordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (this.confirmPassword !== undefined) {
            if (this.resetPassword !== undefined) {
                ValidationRules
                    .ensure('resetPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .on(this);
            } else {
                ValidationRules
                    .ensure('confirmPassword').matches(/[a-z]/).minLength(6).maxLength(100).matches(/[0-9]/).matches(/[A-Z]/).matches(/_|\W/)
                    .on(this);
            }
            this.confirmPasswordValidatorProperty = await this.validator.validate();
            let counterValidationsConfirmPassword = 0;
            if (this.confirmPassword) {
                for (const validations of this.confirmPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'confirmPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsConfirmPassword++;
                    }
                }
                if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsConfirmPassword = 0;
                }
            }

            if (this.resetPassword !== undefined && this.resetPassword !== '') {
                let counterValidationsResetPassword = 0;
                for (const validations of this.confirmPasswordValidatorProperty.results) {
                    if (validations.propertyName === 'resetPassword' && validations.valid && validations.rule.messageKey !== 'maxLength') {
                        counterValidationsResetPassword++;
                    }
                }
                if (!this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'maxLength').valid) {
                    counterValidationsResetPassword = 0;
                }
                if (counterValidationsResetPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'resetPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                    this.resetPasswordValid = true;
                    this.resetPasswordInvalid = !this.resetPasswordValid;
                    this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                } else {
                    this.resetPasswordValid = false;
                    this.resetPasswordInvalid = !this.resetPasswordValid;
                    this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                }
            } else if (this.resetPassword === '') {
                this.resetPasswordValid = false;
                this.showErrorCheckMarkResetPassword = !this.resetPasswordValid;
                this.resetPasswordInvalid = !this.resetPasswordValid;
            }

            if (counterValidationsConfirmPassword >= 3 && this.confirmPasswordValidatorProperty.results.find(x => x.propertyName === 'confirmPassword' && x.rule.messageKey === 'minLength' && x.valid)) {
                this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                this.confirmPasswordValid = true;
                if (this.resetPasswordValid && this.confirmPasswordValid && !this.firedFunction) {
                    await this.updatePassword();
                }
            } else if (!this.successFunction) {
                this.confirmPasswordValid = this.showMiniSpinnerConfirmPassword = this.showMiniSpinnerResetPassword = false;
                this.confirmPasswordInvalid = this.showErrorCheckMarkConfirmPassword = true;
                if (!this.toastConfirmPasswordSent) {
                    await this.toastService.showToast('Please enter a valid confirm password', 'Entered password does not match the expected value.', 'error');
                }
            }
        }
    }

    async login(tokenActivated?) {

        if (!tokenActivated) {
            this.isRequesting = true;
        }
        try {
            const response = await this.sessionService.login({ email: this.email, password: this.password, token: this.token });

            if (response) {
                this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.showMiniSpinnerTokenLogin = this.showErrorCheckMarkEmailLogin = this.showErrorCheckMarkPasswordLogin = false;
                if (response?.incorrectToken) {
                    await this.toastService.showToast('Please enter a valid token', 'Entered token is incorrect or outdated.', 'error');
                    this.showGreenCheckMarkTokenLogin = this.isRequesting = false;
                    this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = this.showErrorCheckMarkTokenLogin = true;
                    return;
                }
                this.successFunction = true;
                setTimeout(() => {
                    if (!tokenActivated) {
                        this.loading = true;
                    }
                    setTimeout(async () => {
                        this.loading = false;
                        if (response?.tokenRequired) {
                            if (!tokenActivated) {
                                await this.toastService.showToast('2FA is required for login', 'Please provide your Two Factor Authentication token.', 'info');
                            }
                            this.isRequesting = false;
                            this.tokenRequired = true;
                            return;
                        }
                        if (response?.resetPassword) {
                            await this.toastService.showToast('You are required to reset your password.', 'An email has been sent to you to do so.', 'info');
                            this.isRequesting = false;
                            const data = { email: this.email };
                            await this.customerService.requestPasswordReset(data);
                            return;
                        }
                        if (response?.mustValidateNewIP) {
                            await this.toastService.showToast('Authorization required', `We sent an email to ${this.email}. Please check your inbox to authorize sign in.`, 'warning');
                            this.isRequesting = false;
                            const data = { email: this.email, requestedIPToValidate: response.requestedIPToValidate };
                            await this.customerService.requestIpAuthorization(data);
                            return;
                        }
                        const oldCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });
                        if (oldCart?.length) {
                            for (const item of oldCart) {
                                await this.sessionService.saveCart(oldCart, true, null, null, null, item, true);
                            }
                        }
                        const newCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('cart-updated', { cart: newCart });
                        this.isRequesting = false;

                        if (this.params.redirect_url && this.params.redirect_url !== 'sign-in' && this.params.redirect_url !== 'sign-up' && this.params.redirect_url !== 'home') {
                            this.router.navigate(this.params.redirect_url);
                        } else {
                            this.router.navigate('');
                        }
                    }, 1000);
                }, 1000);
            } else {
                if (this.tokenRequired) {
                    this.showGreenCheckMarkTokenLogin = this.showMiniSpinnerTokenLogin = false;
                    this.showErrorCheckMarkTokenLogin = true;
                }
                this.showGreenCheckMarkEmailLogin = this.passwordValidLogin = this.showMiniSpinnerEmailLogin = this.showMiniSpinnerPasswordLogin = this.successFunction = false;
                this.showErrorCheckMarkEmailLogin = this.passwordInvalidLogin = this.showErrorCheckMarkPasswordLogin = this.loginFailed = true;
                this.isRequesting = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async sendEmailReset() {
        this.isRequesting = true;
        try {
            const data = { email: this.recoveryEmail };
            const response = await this.customerService.requestPasswordReset(data);
            if (response) {
                this.showMiniSpinnerRecoveryEmail = this.showErrorCheckMarkRecoveryEmail = false;
                this.successFunction = true;
                setTimeout(() => {
                    this.loading = true;
                    setTimeout(async () => {
                        this.loading = false;
                        await this.toastService.showToast('Request received', 'If there is a matching account, a password reset email has been sent.', 'success');
                        this.email = this.recoveryEmail;
                        this.isRequesting = false;
                        this.showSignIn = true;
                        this.router.navigateToRoute('sign-in');
                    }, 1000);
                }, 1000);
            } else {
                this.showGreenCheckMarkRecoveryEmail = this.showMiniSpinnerRecoveryEmail = this.successFunction = false;
                this.showErrorCheckMarkRecoveryEmail = true;
                this.isRequesting = false;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async updatePassword() {
        this.isRequesting = true;
        if (this.resetPassword === this.confirmPassword) {
            try {
                const response = await this.customerService.resetPasswordWithToken(this.params.email, this.confirmPassword, this.params.accessToken);
                if (response) {
                    this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = false;
                    this.successFunction = true;
                    setTimeout(() => {
                        this.loading = true;
                        setTimeout(async () => {
                            this.loading = false;
                            this.params.accessToken = false;
                            await this.toastService.showToast('Done!', 'Password reset completed, login to access your account', 'success');
                            this.isRequesting = false;
                            this.router.navigateToRoute('sign-in');
                        }, 1000);
                    }, 1000);
                    return;
                } else {
                    this.resetPasswordValid = this.confirmPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
                    this.resetPasswordInvalid = this.confirmPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = true;
                    this.isRequesting = false;
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            this.resetPasswordValid = this.confirmPasswordValid = this.showMiniSpinnerResetPassword = this.showMiniSpinnerConfirmPassword = this.successFunction = false;
            this.resetPasswordInvalid = this.confirmPasswordInvalid = this.showErrorCheckMarkResetPassword = this.showErrorCheckMarkConfirmPassword = true;
            await this.toastService.showToast('Passwords do not match', 'Please make sure to enter same password on both inputs.', 'error');
            this.isRequesting = false;
        }
    }

    async register() {
        this.isRequesting = true;
        try {
            const registerResponse = await this.customerService.register({ email: this.newEmail, password: this.newPassword, optedInForEmails: this.optedInForEmails });
            if (registerResponse?.token) {
                this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.showErrorCheckMarkEmailRegister = this.showErrorCheckMarkPasswordRegister = false;
                this.successFunction = true;
                setTimeout(() => {
                    this.loading = true;
                    setTimeout(async () => {
                        this.loading = false;
                        await this.sessionService.saveToken(registerResponse.token);
                        const oldCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });
                        if (oldCart?.length) {
                            for (const item of oldCart) {
                                await this.sessionService.saveCart(oldCart, true, null, null, null, item, true);
                            }
                        }
                        const newCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('cart-updated', { cart: newCart });
                        await this.toastService.showToast('Account created successfully', 'A confirmation email with further instructions has been sent', 'success');
                        this.isRequesting = false;
                        this.router.navigateToRoute('home');
                    }, 1000);
                }, 1000);
            } else if (registerResponse) {
                this.showSignIn = true;
                this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.successFunction = false;
                this.isRequesting = false;
                this.router.navigate('sign-in');
            } else {
                this.showGreenCheckMarkEmailRegister = this.passwordValidRegister = this.showMiniSpinnerEmailRegister = this.showMiniSpinnerPasswordRegister = this.successFunction = false;
                this.showErrorCheckMarkEmailRegister = this.passwordInvalidRegister = this.showErrorCheckMarkPasswordRegister = true;
                await this.toastService.showToast('Failed to create user', 'Please try again or try using another email and password.', 'error');
                this.isRequesting = false;
            }
        } catch (e) {
            console.log(e);
        }
    }
}
