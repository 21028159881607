import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class ProductCategoryService {
    path = 'ProductCategory';
    navItems;

    constructor(private api: ApiService) {}

    async getAll() {
        return await this.api.doGet(this.path);
    }

    async getAllForNav() {
        if (!this.navItems) {
            this.navItems = await this.api.doGet(this.path + '/ForNav');
        }
        return this.navItems.filter(c => c.name !== 'sellextra' && c.name !== 'WOWTLKPW Power Leveling');
    }

    async getNavCategory(category) {
        if (category) {
            if (this.navItems) {
                return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
            }
            this.navItems = await this.api.doGet(this.path + '/ForNav');
            return this.navItems.find(x => x.name?.toLowerCase() === category?.toLowerCase());
        } else {
            throw Error('Category given was null');
        }
    }

    async getIdByName(name) {
        return await this.api.doGet(this.path + '/GetIdByName/' + name);
    }
}
