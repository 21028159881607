import './ds-chat-view.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';

@autoinject()
export class DsChatView {
    @bindable issue;
    @bindable fileList = [];
    @bindable messages;
    @bindable title;
    @bindable ticketKey;
    issueInput;
    containerMessages;
    user;

    constructor(private sessionService: SessionService) {}

    async attached() {
        this.user = await this.sessionService.getProfile();
        this.containerMessages = document.getElementById('messages');
        this.containerMessages.scrollTop = this.containerMessages.scrollHeight;
    }

    async showIntercomMessage() {
        if (window.Intercom) {
            if (!this.issue) {
                await window.Intercom('showNewMessage', 'Hello, I need help');
            } else {
                if (this.title !== 'Choose a Title') {
                    await window.Intercom('showNewMessage', this.title + ': ' + this.issue);
                } else {
                    await window.Intercom('showNewMessage', this.issue);
                }
            }
        }
    }

    replyToTicket() {
        if (this.issue || this.fileList.length > 0) {
            this.messages.push(
                {
                    isSender: true,
                    avatarImagePath: this.user.avatarImagePath,
                    date: '24-01-2022  5:00 pm',
                    message: this.issue,
                    files: this.fileList
                });

            this.issue = '';
            this.fileList = [];

            this.issueInput.au.controller.viewModel.cleanFileList();

            setTimeout(() => {
                this.containerMessages.scrollTop = this.containerMessages.scrollHeight;
            }, 10);
        }
    }
}
