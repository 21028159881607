import './ds-progress-bar.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class DsProgressBar {
    @bindable numSteps;
    @bindable isVerificationPage;
    @bindable isVertical;
    @bindable user;
    currentStep = 1;
    progressCurrent;
    verificationProgress = '0';
    bannerSubscriber;
    veriffSubscriber;

    constructor(private sessionService: SessionService, private eventAggregator: EventAggregator) { }

    async attached() {
        this.user = await this.sessionService.refreshProfile();
        if (this.user) {
            if (this.user.phoneNumberConfirmed && !(this.user.emailConfirmed && this.user.idVerified)) {
                this.verificationProgress = this.isVertical ? '0.45' : '0.5';
            }
            else if (this.user.emailConfirmed && this.user.idVerified) {
                this.verificationProgress = '1';
            }
        }
        this.setProgress(this.currentStep - 1);
        this.handleEventSubscriptions();
    }

    detached() {
        this.bannerSubscriber?.dispose();
        this.veriffSubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.bannerSubscriber = this.eventAggregator.subscribe('banner-updated', payload => {
            if (payload.successful === 'success' && payload.text === 'Your email has been confirmed') {
                this.user.emailConfirmed = true;
            }
        });

        this.veriffSubscriber = this.eventAggregator.subscribe('veriff-verification', payload => {
            if (payload.idVerified) {
                this.user.idVerified = payload.idVerified;
            }
        });
    }

    setProgress(step) {
        this.progressCurrent = (1 / (this.numSteps - 1)) * step;
    }

    nextStep() {
        if (this.currentStep < this.numSteps) {
            this.currentStep++;
            if (this.currentStep === this.numSteps) {
                document.getElementsByClassName('step').item(this.numSteps - 1).classList.add('step-success');
            }
        }
        this.setProgress(this.currentStep - 1);
    }

    previousStep() {
        if (this.currentStep > 1) {
            if (this.currentStep === this.numSteps) {
                document.getElementsByClassName('step').item(this.numSteps - 1).classList.remove('step-success');
            }
            this.currentStep--;
        }
        this.setProgress(this.currentStep - 1);
    }

}
