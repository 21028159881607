import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';

@autoinject()
export class PaymentMethodsService {
    path = 'PaymentMethod';
    limit = 25;

    constructor(private api: ApiService) {
        this.api = api;
    }

    async filter(filter) {
        return await this.api.doGet(this.path + '?filter=' + filter);
    }

    async getByWebsite() {
        return await this.api.doGet(this.path + '/ByWebsite?websiteShortCode=' + websiteShortCode());
    }
}
