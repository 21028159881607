import './ds-toast.scss';
import { bindable } from 'aurelia-framework';

export class DsToast {
    @bindable title: string;
    @bindable message: string;
    @bindable toastType: string;
    @bindable toastIcon: string;

    private container: HTMLElement;

    attached() {
        this.loadToastIcon();
        setTimeout(() => {
            this.closeToast();
        }, 9000);
    }

    closeToast() {
        this.container.classList.remove('fadeIn');
        this.container.classList.add('fadeOut');
        setTimeout(() => {
            this.container.remove();
        }, 500);
    }

    loadToastIcon() {
        switch (this.toastType) {
            case 'success':
                this.toastIcon = 'check_circle_outline';
                break;

            case 'warning':
                this.toastIcon = 'warning_amber';
                break;

            case 'info':
                this.toastIcon = 'error_outline';
                break;

            case 'error':
                this.toastIcon = 'highlight_off';
                break;
        }
    }
}
