import './ds-page-content-image.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { PageContentAreaService } from 'services/page-content-area-service';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { getAWSBucketEndpoint } from 'environment';

@autoinject()
export class DsPageContentImage {
    baseAwsEndpoint;
    subscriber;
    constructor(
        private pageContentAreaService: PageContentAreaService,
        private eventAggregator: EventAggregator,
        private sessionService: SessionService) {
        this.baseAwsEndpoint = getAWSBucketEndpoint('page-content-area-image');
    }

    @bindable key;
    imagePath = '';
    imageAlt = '';
    viewingAsAdmin;

    async attached() {
        try {
            this.viewingAsAdmin = await this.sessionService.getAdminView();
            await this.setByKey();
            this.handleEventSubscritpions();
        } catch (e) {
            console.log(e);
        }
    }

    detached() {
        this.subscriber?.dispose();
    }

    handleEventSubscritpions() {
        this.subscriber = this.eventAggregator.subscribe('admin-view-updated', payload => {
            this.viewingAsAdmin = payload.bool;
            this.setByKey();
        });
    }

    async setByKey() {
        const result = await this.pageContentAreaService.getByKey(this.key);
        if (this.viewingAsAdmin) {
            this.imagePath = `Key: { ${this.key} }`;
            this.imageAlt = `Key: { ${this.key} }`;
        } else {
            this.imagePath = result ? result.imagePath : '';
            this.imageAlt = result ? result.imageAlt : '';
        }
    }

    async keyChanged() {
        await this.setByKey();
    }
}
