import { bindable } from 'aurelia-framework';

export class DsTextInput {
    @bindable class = '';
    @bindable value;
    @bindable label = '';
    @bindable buttonIcon;
    @bindable buttonIconFunction;
    @bindable keyUpFunction;
}
