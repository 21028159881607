import './footer.scss';
import { PageContentAreaService } from 'services/page-content-area-service';
import { autoinject } from 'aurelia-dependency-injection';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class Footer {
    currentYear;
    detailsSelectors;
    aboutUsRoute;
    blogRoute;
    contactUsRoute;
    faqRoute;
    privacyPolicyRoute;
    termsOfServiceRoute;
    copyrightPolicyRoute;

    constructor(private pageContentAreaService: PageContentAreaService, private websiteService: WebsiteService) {}

    created() {
        this.currentYear = new Date().getFullYear();
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.aboutUsRoute = pages.find(x => x.name === 'About Us')?.routeName ?? 'about-us';
        this.blogRoute = pages.find(x => x.name === 'Blogs')?.routeName ?? 'blogs';
        this.contactUsRoute = pages.find(x => x.name === 'Contact Us')?.routeName ?? 'contact';
        this.faqRoute = pages.find(x => x.name === 'Faq')?.routeName ?? 'faq';
        this.privacyPolicyRoute = pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
        this.termsOfServiceRoute = pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.copyrightPolicyRoute = pages.find(x => x.name === 'Copyright Policy')?.routeName ?? 'copyright-policy';
        this.overrideMdc();
    }

    overrideMdc() {
        const selectArrow = document.querySelectorAll('#footer .mdc-expandable__dropdown-icon');
        const newArrowIcon = '<a class="expandable-arrow text-black p-0 material-icons">expand_more</a>';
        if (selectArrow) {
            for (const arrow of Array.from(selectArrow)) {
                arrow.innerHTML = newArrowIcon;
            }
        }
    }

    handleClickEvent(e) {
        const arrowTriggered = e.querySelector('.expandable-arrow');
        const container = e.querySelector('.mdc-expandable__content-container');
        if (arrowTriggered) {
            if (arrowTriggered.style.transform === 'rotate(180deg)') {
                arrowTriggered.style.transform = 'rotate(0deg)';
                container.classList.add('h-0', 'overflow-hidden');
            } else {
                arrowTriggered.style.transform = 'rotate(180deg)';
                container.classList.remove('h-0', 'overflow-hidden');
            }
        }
    }
}
