import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class CurrencyService {
    path = 'Currency';
    currencyRates = [];
    activeCurrencies;

    constructor(private api: ApiService) {}

    async getAllCurrencyRates() {
        try {
            const currencyResponse = await this.api.doGet(`${this.path}`);
            if (currencyResponse) {
                this.currencyRates.push(currencyResponse.rates);
                return;
            }
        } catch (e) {
            console.log(e);
        }
    }

    async getConvertionRateToUSD(currency) {
        try {
            return await this.api.doGet(`${this.path}/ConvertionRateToUSD/${currency}`);
        } catch (e) {
            console.log(e);
        }
    }

    getStoredCurrencyRates(currency) {
        try {
            if (currency === 'USD' || !currency) {
                return 1;
            }
            let foundRate;
            for (const rate of this.currencyRates) {
                for (const currencyRate in rate) {
                    if (currencyRate === currency.toLowerCase()) {
                        foundRate = rate[currencyRate];
                    }
                }
            }
            if (!foundRate) {
                return null;
            }
            return foundRate;
        } catch (e) {
            console.log(e);
        }
    }

    async getActiveCurrenciesByWebsite() {
        try {
            if (this.activeCurrencies) {
                return this.activeCurrencies;
            }
            this.activeCurrencies = await this.api.doGet(`${this.path}/GetActiveCurrenciesByWebsite`);
            return this.activeCurrencies;
        } catch (e) {
            console.log(e);
        }
    }

    getStoredActiveCurrenciesByWebsite() {
        return this.activeCurrencies;
    }
}
