import './ds-cart-product.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { DeliveryMethodService } from 'services/delivery-methods-service';
import { InsuranceService } from 'services/insurance-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Router } from 'aurelia-router';
import { getProductCategoryBaseRoutes } from 'resources/constants';
import { ToastService } from 'services/toast-service';
import { getAWSBucketEndpoint } from 'environment';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsCartProduct {
    @bindable product;
    @bindable totalFunction;
    @bindable tempQuantity;
    open = false;
    whiteXMark;
    stopWatch;
    lastEventTrigered;
    arrowRotated = false;
    arrowTrigered;
    deliveryMethodsId = [];
    insuranceId = [];
    showDescription = false;
    readMore;
    stopWatch2;
    characterNameFocusInStopWatch;
    miniSpinnerStopWatch;
    showGreenCheckMarkCharacterName;
    showErrorCheckMarkCharacterName;
    baseAwsEndpoint;
    deliveryMethods;
    selectedDeliveryMethod;
    insurances;
    selectedInsurance;
    productTotals;
    detailsSelectors;
    descriptionSelectors;
    showMiniSpinnerCharacterName;
    timeouts;
    characterXMark;
    firstLoad;
    updateCartTimer;
    triggeredSaveCartProduct;

    constructor(
        private sessionService: SessionService,
        private deliveryMethodService: DeliveryMethodService,
        private insuranceService: InsuranceService,
        private eventAggregator: EventAggregator,
        private router: Router,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService) {
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;
        this.baseAwsEndpoint = getAWSBucketEndpoint('products');
    }

    async attached() {
        this.firstLoad = true;
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.product.purchasedInsurance = false;
        this.product.total = this.product.selectedQuantity * this.product.price;

        if (this.product.productCategoryId === 3) {
            this.product.character = 'Character Name';
        } else if (this.product.character && this.sessionService.validateCharacterName(this.product)) {
            this.showGreenCheckMarkCharacterName = true;
        } else if (this.product.character && !this.sessionService.validateCharacterName(this.product)) {
            this.showErrorCheckMarkCharacterName = true;
        }

        this.readMore = `<a href="${pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service'}?AITS" target="_blank" rel="nofollow noopener noreferrer" class="ms-1 font-bold text-gray">Read More <img src="/icons/link.svg" class="ms-1" alt="link"></a></p>`;

        if (this.product.assurranceDescription) {
            this.product.assurranceDescription = this.product.assurranceDescription.replace('</p>', this.readMore);
        }

        if (this.product.deliveryMethods?.length > 0) {
            for (const method of this.product.deliveryMethods) {
                this.deliveryMethodsId.push(method.deliveryMethodId);
            }
            this.deliveryMethods = await this.deliveryMethodService.getAllDeliveryMethods();
            this.deliveryMethods = this.deliveryMethods.filter(x => this.deliveryMethodsId.includes(x.id)).sort((a, b) => { return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position; });
            this.selectedDeliveryMethod = this.deliveryMethods.find(x => x.id === this.product.deliveryMethodId);
        }

        if (this.product.insurances?.length > 0) {
            for (const insurance of this.product.insurances) {
                this.insuranceId.push(insurance.insuranceId);
            }
            this.insurances = await this.insuranceService.getAllInsurances();
            this.insurances = this.insurances.filter(x => this.insuranceId.includes(x.id)).sort((a, b) => { return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position; });

            if (this.product.insuranceId) {
                this.product.purchasedInsurance = true;
                this.selectedInsurance = this.insurances.find(x => x.id === this.product.insuranceId);
                this.setInsurancePrice();
            }
        }

        setTimeout(() => {
            this.overrideMdc();
        }, 1000);

        this.firstLoad = false;
    }

    handleClick(e) {
        if (e.target.querySelector('mdc-list-item')) {
            return;
        }
        this.arrowTrigered = e.target.querySelector('.faq-arrow-icon');
        if (this.arrowRotated) {
            this.arrowTrigered.style.transform = 'rotate(0deg)';
            this.arrowRotated = false;
        } else {
            if (this.lastEventTrigered !== 'focusOut') {
                this.arrowTrigered.style.transform = 'rotate(180deg)';
                this.arrowRotated = true;
            }
        }
    }

    handleFocusOut(_e) {
        if (this.arrowTrigered) {
            this.arrowTrigered.style.transform = 'rotate(0deg)';
            this.arrowRotated = false;
            this.lastEventTrigered = 'focusOut';
            setTimeout(() => {
                this.lastEventTrigered = '';
            }, 200);
        }
    }

    productChanged() {
        if (this.product.selectedQuantity) {
            this.product.quantity = this.product.selectedQuantity;
        }
    }

    tempQuantityChanged() {
        this.tempQuantity = this.tempQuantity?.toString().replaceAll(',', '');
        if (this.totalFunction) {
            this.totalFunction();
        }
    }

    async deleteCartItem(product) {
        const redirect = await this.sessionService.deleteCartItem(product);
        product.removed = true;
        this.whiteXMark = false;
        if (redirect) {
            const baseRoutes = getProductCategoryBaseRoutes();
            const category = product.productCategoryName ?? product.productCategory.name;
            this.router.navigateToRoute(baseRoutes[category]);
        }
        this.toastService.showToast(`'Removed ${product.name}'`, 'from your cart.', 'success');
    }

    async itemQuantityChanged(event) {
        if (event) {
            this.product.quantity = !isNaN(event) ? event : event?.target?.value;
        }
        if (!this.firstLoad) {
            this.timeouts = [this.updateCartTimer];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            this.updateCartTimer = setTimeout(() => {
                this.saveCartProduct();
            });
        }
    }

    saveCartProduct() {
        this.sessionService.updateCartItem(this.product, true);
        if (this.totalFunction) {
            this.totalFunction();
        }
    }

    toggleDetails() {
        this.open = !this.open;
    }

    clearCharacterName() {
        this.product.character = null;
        this.showMiniSpinnerCharacterName = this.whiteXMark = this.showGreenCheckMarkCharacterName = this.showErrorCheckMarkCharacterName = false;
        this.timeouts = [this.stopWatch, this.stopWatch2, this.miniSpinnerStopWatch, this.characterNameFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
    }

    characterNameUpdatedOnKeyPress(characterName) {
        this.showMiniSpinnerCharacterName = this.whiteXMark = this.showGreenCheckMarkCharacterName = this.showErrorCheckMarkCharacterName = false;
        this.timeouts = [this.stopWatch, this.stopWatch2, this.miniSpinnerStopWatch, this.characterNameFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        if (characterName) {
            const nameValid = characterName && this.sessionService.validateCharacterName(this.product);
            this.whiteXMark = true;
            this.miniSpinnerStopWatch = setTimeout(() => {
                this.whiteXMark = false;
                this.showMiniSpinnerCharacterName = true;
            }, 1000);
            this.stopWatch = setTimeout(() => {
                if (nameValid) {
                    if (!this.firstLoad) {
                        this.triggeredSaveCartProduct = true;
                        this.saveCartProduct();
                    }
                    this.showMiniSpinnerCharacterName = this.whiteXMark = this.showErrorCheckMarkCharacterName = false;
                    this.showGreenCheckMarkCharacterName = true;
                } else {
                    this.stopWatch2 = setTimeout(() => {
                        this.showMiniSpinnerCharacterName = this.whiteXMark = this.showGreenCheckMarkCharacterName = false;
                        this.showErrorCheckMarkCharacterName = true;
                    }, 2000);
                }
            }, 2000);
        }
    }

    characterNameUpdatedOnFocusIn(characterName) {
        this.showMiniSpinnerCharacterName = this.whiteXMark = this.characterXMark = false;
        this.characterNameFocusInStopWatch = setTimeout(() => {
            if (characterName !== '') {
                this.whiteXMark = true;
                this.characterNameUpdatedOnKeyPress(characterName);
            }
        });
    }

    getWarningMessage(characterWarningMessage) {
        return characterWarningMessage ? '' : 'Enter name';
    }

    checkIfCharacterNameExists(characterName) {
        this.showMiniSpinnerCharacterName = this.whiteXMark = false;
        if (characterName !== undefined && characterName !== '') {
            this.timeouts = [this.stopWatch, this.stopWatch2, this.miniSpinnerStopWatch, this.characterNameFocusInStopWatch];
            this.clearationTimeoutValueConverter.toView(this.timeouts);
            if (!this.firstLoad && !this.triggeredSaveCartProduct) {
                this.saveCartProduct();
            }
            const nameValid = characterName && this.sessionService.validateCharacterName(this.product);
            if (nameValid) {
                this.showMiniSpinnerCharacterName = this.whiteXMark = this.showErrorCheckMarkCharacterName = false;
                this.showGreenCheckMarkCharacterName = true;
            } else {
                this.showMiniSpinnerCharacterName = this.showGreenCheckMarkCharacterName = this.whiteXMark = false;
                this.showErrorCheckMarkCharacterName = true;
            }
        } else {
            this.showMiniSpinnerCharacterName = this.showGreenCheckMarkCharacterName = this.whiteXMark = false;
            this.showErrorCheckMarkCharacterName = true;
        }
    }

    handleInsuranceChange(event) {
        if (event?.detail?.value !== null) {
            this.product.purchasedInsurance = true;
            this.product.insuranceId = event?.detail?.value.id;
            this.arrowTrigered.style.transform = 'rotate(0deg)';
            this.arrowRotated = false;
            this.setInsurancePrice();
        } else {
            this.product.purchasedInsurance = false;
        }

        this.sessionService.updateCartItem(this.product, true);
        if (this.totalFunction) {
            this.totalFunction();
        }
    }

    overrideMdc() {
        const detailsSelectors = this.detailsSelectors ? this.detailsSelectors.querySelector('.mdc-select__dropdown-icon') : '';
        const descriptionSelectors = this.descriptionSelectors ? this.descriptionSelectors.querySelector('.mdc-select__dropdown-icon') : '';
        const newArrowIcon = '<img class="faq-arrow-icon" src="/faq/arrow-currency.svg" alt="arrow icon" loading="lazy">';
        if (detailsSelectors || descriptionSelectors) {
            detailsSelectors ? detailsSelectors.innerHTML = newArrowIcon : '';
            descriptionSelectors ? descriptionSelectors.innerHTML = newArrowIcon : '';
        }
    }

    setInsurancePrice() {
        const total = this.tempQuantity * this.product.price;
        if (this.selectedInsurance.fee > 0) {
            const fee = (parseFloat((this.selectedInsurance.fee / 100).toFixed(2)) + 1);
            this.productTotals = total * fee;
        } else {
            this.productTotals = total;
        }
    }

    getProductImage(product) {
        if (product.game?.shortName === 'OSRS') {
            return '/cart/img-osrs-cart.png';
        } else {
            return '/cart/img-rs3-cart.png';
        }
    }

    get requiresDeliveryMethod() {
        return this.product?.productCategoryId === 1 || this.product?.productCategoryId === 2;
    }

    handleRsDeliveryMethodUpdated(event) {
        this.product.deliveryMethod = event?.detail?.value;
        this.eventAggregator.publish('recalculate-cart', null);
        this.sessionService.updateCartItem(this.product, true);
        if (this.totalFunction) {
            this.totalFunction();
        }
    }

    longNameCheck(name, gameModeName) {
        if (gameModeName !== 'Regular' && (name?.length + gameModeName?.length) > 25) {
            return true;
        } else if (name.length > 28) {
            return true;
        }
        return false;
    }

    showItemDescription() {
        this.showDescription = !this.showDescription;
    }
}
