import './ds-pagination.scss';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class DsPagination {
    @bindable page;
    @bindable count;
    @bindable inputPage;
    pages;
    max = 6;
    width;
    sizeChanged;

    attached() {
        this.calculatePages();
    }

    calculatePages() {
        this.pages = [];
        if (this.count < this.max) {
            for (let i = 1; i < this.count + 1; i++) {
                this.pages.push(i.toString());
            }
        } else if (this.page < (this.max - 2)) {
            for (let i = 1; i < this.max - 1; i++) {
                this.pages.push(i.toString());
            }
            this.pages.push('...');
            this.pages.push(this.count.toString());
        } else if ((this.count - this.page) < this.max - 3) {
            this.pages.push('1');
            this.pages.push('...');
            for (let i = (this.count - (this.max - 3)); i < this.count + 1; i++) {
                this.pages.push(i.toString());
            }
        } else {
            this.pages.push('1');
            if (this.page <= (this.count / 2) || this.page + 3 <= this.count) {
                this.pages.push('...');
            }
            for (let i = (this.page - 1); i < this.page + 2; i++) {
                this.pages.push(i.toString());
            }
            if (this.page > (this.count / 2) || this.page + 3 <= this.count) {
                this.pages.push('...');
            }
            this.pages.push(this.count.toString());
        }
        return this.pages;
    }

    countChanged() {
        this.calculatePages();
    }

    inputPageChanged() {
        if (this.inputPage <= this.count && this.inputPage >= 1) {
            this.page = parseInt(this.inputPage);
            document.getElementById('pagination').blur();
            this.calculatePages();
        }
        this.inputPage = '';
    }

    changePage(index, value) {
        if (value !== '...') {
            this.page = parseInt(value);
        } else {
            if (index === 1) {
                this.page -= this.page > (this.count - 5) ? (this.page - (this.count - 5)) : 2;
            } else {
                this.page += this.page < 5 ? (6 - this.page) : 2;
            }
        }
        this.calculatePages();
    }

    pageNext() {
        this.page += this.page < this.count ? 1 : 0;
        this.calculatePages();
    }

    pagePrevious() {
        this.page -= this.page > 1 ? 1 : 0;
        this.calculatePages();
    }
}
