import { kountUrl } from 'environment';

export class ScriptService {
    fraudSessionId;

    injectPaysafeScript() {
        const el = document.getElementById('paysafe-script');
        if (!el) {
            const script = document.createElement('script');
            script.setAttribute('id', 'paysafe-script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', 'https://hosted.paysafe.com/js/v1/latest/paysafe.min.js');
            document.body.appendChild(script);
        }
    }

    injectPaysafe3DSScript() {
        const el = document.getElementById('paysafe-3ds-script');
        if (!el) {
            const script = document.createElement('script');
            script.setAttribute('id', 'paysafe-3ds-script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', 'https://hosted.paysafe.com/threedsecure/js/latest/paysafe.threedsecure.min.js');
            document.body.appendChild(script);
        }
    }

    injectIntercomScript() {
        const el = document.getElementById('intercom-script');
        if (!el) {
            const script = document.createElement('script');
            script.setAttribute('id', 'intercom-script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('async', 'true');
            script.setAttribute('src', 'https://widget.intercom.io/widget/dqgl5no3');
            document.body.appendChild(script);
        }
    }

    injectGoogleScript() {
        const el = document.getElementById('google-script');
        if (!el) {
            const script = document.createElement('script');
            script.setAttribute('id', 'google-script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBwRB5w56D8uTYXbNythSvbzJykWFqhgQg&callback=Function.prototype&libraries=places');
            document.body.appendChild(script);
        }
    }

    injectKountScript() {
        const el = document.getElementById('kount-script');
        if (!el) {
            const script = document.createElement('script');
            script.setAttribute('id', 'kount-script');
            script.setAttribute('type', 'text/javascript');
            script.setAttribute('src', `${kountUrl()}collect/sdk?m=700000`);
            document.body.appendChild(script);
        }
    }

    initiateKountFraudScript() {
        if (window.ka) {
            const client = new window.ka.ClientSDK();
            client.setupCallback(
                {
                    'collect-end':
                        (params) => {
                            this.fraudSessionId = params?.MercSessId;
                        },
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    'collect-begin': () => {}
                });
            client.autoLoadEvents();
        } else {
            setTimeout(() => {
                this.initiateKountFraudScript();
            }, 500);
        }
    }
}
