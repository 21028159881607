import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class BlacklistService {
    path = 'Blacklist';

    constructor(private api: ApiService) { }

    async getBlacklistByUserEmail(userEmail: string) {
        return await this.api.doGet(`${this.path}/${userEmail}/GetByUserEmail`);
    }
}
