import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class UserDocumentService {
    path = 'UserDocument';

    constructor(private api: ApiService) {}

    getStatus(data, type) {
        if (data.find(x => x.userDocumentVerificationCategoryId === type && !x.userDeleted)?.verified === null) {
            return true;
        } else {
            return false;
        }
    }

    async getUserDocumentsById(id, type) {
        return this.getStatus(await this.api.doGet(this.path + '/GetUserDocumentsById/' + id), type);
    }

    async deleteByUserIdAndCategoryId(userId, categoryId) {
        await this.api.doDelete(this.path + '/DeleteForUserByUserIdAndCategoryId/' + userId + '/' + categoryId);
    }
}
