import './ds-cart-login.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { validateTrigger, ValidationController, ValidationRules } from 'aurelia-validation';
import { ValidationRenderer } from 'resources/validation-renderer';
import { SessionService } from 'services/session-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { CustomerService } from 'services/customer-service';
import { ToastService } from 'services/toast-service';
import { ClearationTimeoutValueConverter } from 'resources/value-converters/clearation-timeout';
import { WebsiteService } from 'services/website-service';

@autoinject()
export class DsCartLogin {
    @bindable loggingIn;
    @bindable loadingFromAuth;
    showGreenCheckMarkEmail = false;
    showErrorCheckMarkEmail = false;
    whiteXMark = false;
    showGreenCheckMarkToken = false;
    showErrorCheckMarkToken = false;
    passwordValid = false;
    passwordInvalid = false;
    showErrorCheckMarkPassword = false;
    loginFailed = false;

    user = {
        slowValidateEmail: null,
        email: null,
        password: null,
        agreeToTos: true,
        optedInForEmails: false,
        token: null
    };

    loading = false;
    emailStopWatch;
    emailStopWatch2;
    miniSpinnerEmailStopWatch;
    showMiniSpinnerEmail;
    toastEmailSent;
    emailFocusInStopWatch;
    passwordStopWatch;
    passwordStopWatch2;
    miniSpinnerPasswordStopWatch;
    showMiniSpinnerPassword;
    toastPasswordSent;
    passwordFocusInStopWatch;
    tokenStopWatch;
    tokenStopWatch2;
    miniSpinnerTokenStopWatch;
    showMiniSpinnerToken;
    toastTokenSent;
    tokenFocusInStopWatch;
    firedFunction;
    successFunction;
    baseRules;
    registerRules;
    tokenRequired = false;
    isRequesting;
    showForgotPassword;
    timeouts;
    termsOfServicePageRoute;
    privacyPolicyPageRoute;
    parent;

    constructor(
        private sessionService: SessionService,
        private validator: ValidationController,
        private eventAggregator: EventAggregator,
        private customerService: CustomerService,
        private toastService: ToastService,
        private clearationTimeoutValueConverter: ClearationTimeoutValueConverter,
        private websiteService: WebsiteService) {
        this.validator.addRenderer(new ValidationRenderer());
        this.validator.validateTrigger = validateTrigger.manual;
        this.clearationTimeoutValueConverter = clearationTimeoutValueConverter;

        this.baseRules = ValidationRules
            .ensure('slowValidateEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/)
            .ensure('password').required().minLength(6).maxLength(100)
            .ensure('token').required().when(_x => this.tokenRequired).minLength(6).maxLength(6)
            .rules;

        this.registerRules = ValidationRules
            .ensure('slowValidateEmail').displayName('Email').required().matches(/^[\w-.+]+@([\w-]+\.)+[\w-]{1,4}$/)
            .rules;
    }

    bind(bindingContext) {
        this.parent = bindingContext;
    }

    async attached() {
        const pages = await this.websiteService.getPagesByWebsiteShortcode();
        this.termsOfServicePageRoute = pages.find(x => x.name === 'Terms Of Service')?.routeName ?? 'terms-of-service';
        this.privacyPolicyPageRoute = pages.find(x => x.name === 'Privacy Policy')?.routeName ?? 'privacy-policy';
    }

    async clearEmail() {
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        this.user.slowValidateEmail = null;
        this.showGreenCheckMarkEmail = false;
        this.showErrorCheckMarkEmail = false;
        this.whiteXMark = false;
    }

    async emailUpdatedOnFocusOut() {
        this.showMiniSpinnerEmail = this.whiteXMark = false;
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopWatch, this.emailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.user.slowValidateEmail !== null) {
            this.validator.addObject(this.user, this.loggingIn ? this.baseRules : this.registerRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.slowValidateEmail !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
            } else {
                this.showGreenCheckMarkEmail = false;
            }
            let checkPasswordValidation;
            let checkTokenValidation;
            if (this.user.password !== null && this.user.token !== null) {
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.passwordInvalid;
                if (this.user.token !== '') {
                    this.showGreenCheckMarkToken = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkToken = false;
                }
                this.showErrorCheckMarkToken = !this.showGreenCheckMarkToken;
                checkTokenValidation = this.showErrorCheckMarkToken;
            } else if (this.user.password !== null && this.user.token === null) {
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.passwordInvalid;
            }
            if (!checkValidation) {
                this.showErrorCheckMarkEmail = true;
                this.whiteXMark = false;
                if (!this.toastEmailSent) {
                    this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                }
                return;
            } else {
                this.whiteXMark = false;
                if (!this.loggingIn && !this.firedFunction) {
                    await this.register();
                } else if (this.tokenRequired && this.showGreenCheckMarkToken && this.passwordValid && !this.firedFunction) {
                    await this.login();
                } else if (!this.tokenRequired && this.passwordValid && !this.firedFunction) {
                    await this.login();
                }
                if (checkValidation && this.tokenRequired && (this.successFunction || !this.user.password || !this.user.token || checkTokenValidation || checkPasswordValidation)) {
                    this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = this.whiteXMark = false;
                    this.showGreenCheckMarkEmail = true;
                } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.user.password || checkPasswordValidation)) {
                    this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = this.whiteXMark = false;
                    this.showGreenCheckMarkEmail = true;
                }
            }
        }
    }

    async emailUpdatedOnFocusIn() {
        this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.firedFunction = this.toastEmailSent = this.successFunction = this.showMiniSpinnerEmail = false;
        if (this.user.slowValidateEmail) {
            this.whiteXMark = true;
        } else {
            this.whiteXMark = false;
        }
        this.validator.reset();

        this.emailFocusInStopWatch = setTimeout(() => {
            if (this.user.slowValidateEmail !== null) {
                this.emailUpdatedOnKeyPress();
            }
        });
    }

    async emailUpdatedOnKeyPress() {
        this.timeouts = [this.emailStopWatch, this.emailStopWatch2, this.miniSpinnerEmailStopWatch, this.emailFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.user.slowValidateEmail) {
            this.showGreenCheckMarkEmail = false;
            this.showErrorCheckMarkEmail = false;
            this.showMiniSpinnerEmail = false;
            this.toastEmailSent = false;
            this.successFunction = false;
            this.whiteXMark = true;
        } else {
            this.showGreenCheckMarkEmail = false;
            this.showErrorCheckMarkEmail = false;
            this.showMiniSpinnerEmail = false;
            this.toastEmailSent = false;
            this.successFunction = false;
            this.whiteXMark = false;
            return '';
        }
        this.miniSpinnerEmailStopWatch = setTimeout(() => {
            this.whiteXMark = false;
            this.showMiniSpinnerEmail = true;
        }, 1000);
        this.emailStopWatch = setTimeout(async() => {
            this.validator.addObject(this.user, this.loggingIn ? this.baseRules : this.registerRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.slowValidateEmail !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
            }
            let checkPasswordValidation;
            let checkTokenValidation;
            if (this.user.password !== null && this.user.token !== null) {
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.passwordInvalid;
                if (this.user.token !== '') {
                    this.showGreenCheckMarkToken = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkToken = false;
                }
                this.showErrorCheckMarkToken = !this.showGreenCheckMarkToken;
                checkTokenValidation = this.showErrorCheckMarkToken;
            } else if (this.user.password !== null && this.user.token === null) {
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.passwordInvalid;
            }
            if (!checkValidation) {
                this.emailStopWatch2 = setTimeout(() => {
                    this.toastEmailSent = true;
                    this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                    this.showMiniSpinnerEmail = this.whiteXMark = false;
                    this.toastService.showToast('Error', 'Please enter a valid email.', 'error');
                    return;
                }, 2000);
            } else if (!this.loggingIn) {
                this.firedFunction = true;
                await this.register();
            } else if (this.tokenRequired && this.showGreenCheckMarkToken && this.passwordValid) {
                this.firedFunction = true;
                await this.login();
            } else if (!this.tokenRequired && this.passwordValid) {
                this.firedFunction = true;
                await this.login();
            }

            if (checkValidation && this.tokenRequired && (this.successFunction || !this.user.password || !this.user.token || checkTokenValidation || checkPasswordValidation)) {
                this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = false;
                this.showGreenCheckMarkEmail = true;
            } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.user.password || checkPasswordValidation)) {
                this.showMiniSpinnerEmail = this.showErrorCheckMarkEmail = false;
                this.showGreenCheckMarkEmail = true;
            }
        }, 2000);
    }

    async passwordUpdatedOnFocusOut() {
        this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = this.successFunction = this.isRequesting = false;
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopWatch, this.passwordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.user.password !== null) {
            this.validator.addObject(this.user, this.loggingIn ? this.baseRules : this.registerRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.password !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
            } else if (this.user.password === '') {
                this.showErrorCheckMarkPassword = true;
            }
            let checkEmailValidation;
            let checkTokenValidation;
            if (this.user.slowValidateEmail !== null && this.user.token !== null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
                if (this.user.token !== '') {
                    this.showGreenCheckMarkToken = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkToken = false;
                }
                this.showErrorCheckMarkToken = !this.showGreenCheckMarkToken;
                checkTokenValidation = this.showErrorCheckMarkToken;
            } else if (this.user.slowValidateEmail !== null && this.user.token === null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
            }
            if (!checkValidation) {
                this.passwordInvalid = this.showErrorCheckMarkPassword = this.showForgotPassword = true;
                if (!this.toastPasswordSent) {
                    this.toastService.showToast('Error', 'Please enter a valid password.', 'error');
                }
                return;
            } else if (this.tokenRequired && this.showGreenCheckMarkToken  && this.showGreenCheckMarkEmail && !this.firedFunction) {
                await this.login();
            } else if (!this.tokenRequired && this.showGreenCheckMarkEmail && !this.firedFunction) {
                await this.login();
            }

            if (checkValidation && this.tokenRequired && (this.successFunction || !this.user.slowValidateEmail || !this.user.token || checkTokenValidation || checkEmailValidation)) {
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                this.passwordValid = true;
            } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.user.slowValidateEmail || checkEmailValidation)) {
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                this.passwordValid = true;
            } else {
                this.showErrorCheckMarkPassword = true;
            }
        }
    }

    async passwordUpdatedOnFocusIn() {
        this.passwordValid = this.passwordInvalid = this.showErrorCheckMarkPassword = this.firedFunction = this.showMiniSpinnerPassword = this.successFunction = this.toastPasswordSent = false;
        this.validator.reset();
        this.passwordFocusInStopWatch = setTimeout(() => {
            if (this.user.password !== null) {
                this.passwordUpdatedOnKeyPress();
            }
        });
    }

    async passwordUpdatedOnKeyPress() {
        this.timeouts = [this.passwordStopWatch, this.passwordStopWatch2, this.miniSpinnerPasswordStopWatch, this.passwordFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.loginFailed) {
            this.showGreenCheckMarkEmail = true;
            this.showErrorCheckMarkEmail = false;
            if (this.tokenRequired && this.user.token !== '') {
                this.showGreenCheckMarkToken = true;
            }
        }
        if (this.user.password) {
            this.passwordValid = false;
            this.passwordInvalid = false;
            this.showMiniSpinnerPassword = false;
            this.showErrorCheckMarkPassword = false;
            this.toastPasswordSent = false;
            this.successFunction = false;
        } else {
            this.passwordValid = false;
            this.passwordInvalid = false;
            this.showMiniSpinnerPassword = false;
            this.showErrorCheckMarkPassword = false;
            this.toastPasswordSent = false;
            this.successFunction = false;
            return '';
        }
        this.miniSpinnerPasswordStopWatch = setTimeout(() => {
            this.showMiniSpinnerPassword = true;
        }, 1000);
        this.passwordStopWatch = setTimeout(async() => {
            this.validator.addObject(this.user, this.loggingIn ? this.baseRules : this.registerRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.password !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
            }
            let checkEmailValidation;
            let checkTokenValidation;
            if (this.user.slowValidateEmail !== null && this.user.token !== null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
                if (this.user.token !== '') {
                    this.showGreenCheckMarkToken = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkToken = false;
                }
                this.showErrorCheckMarkToken = !this.showGreenCheckMarkToken;
                checkTokenValidation = this.showErrorCheckMarkToken;
            } else if (this.user.slowValidateEmail !== null && this.user.token === null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
            }
            if (!checkValidation) {
                this.passwordStopWatch2 = setTimeout(() => {
                    this.passwordInvalid = !this.passwordValid;
                    this.showErrorCheckMarkPassword = !this.passwordValid;
                    this.showMiniSpinnerPassword = this.isRequesting = false;
                    this.toastPasswordSent = true;
                    this.showForgotPassword = true;
                    this.toastService.showToast('Error', 'Please enter a valid password.', 'error');
                    return;
                }, 2000);
            } else if (this.tokenRequired && this.showGreenCheckMarkToken && this.showGreenCheckMarkEmail) {
                this.firedFunction = true;
                await this.login();
            } else if (!this.tokenRequired && this.showGreenCheckMarkEmail) {
                this.firedFunction = true;
                await this.login();
            }
            if (checkValidation && this.tokenRequired && (this.successFunction || !this.user.slowValidateEmail || !this.user.token || checkTokenValidation || checkEmailValidation)) {
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                this.passwordValid = true;
            } else if (checkValidation && !this.tokenRequired && (this.successFunction || !this.user.slowValidateEmail || checkEmailValidation)) {
                this.showMiniSpinnerPassword = this.showErrorCheckMarkPassword = false;
                this.passwordValid = true;
            }
        }, 2000);
    }

    async toggleLoggingIn() {
        if (this.isRequesting || this.showMiniSpinnerEmail || this.showMiniSpinnerPassword || this.showMiniSpinnerToken) {
            return;
        }
        this.validator.reset();
        this.showForgotPassword = this.showGreenCheckMarkEmail = this.showErrorCheckMarkEmail = this.passwordValid = this.passwordInvalid = this.showErrorCheckMarkPassword = this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = false;
        this.successFunction = this.isRequesting = false;
        this.loggingIn = false;
    }

    async tokenUpdatedOnFocusOut() {
        this.showMiniSpinnerToken = this.showErrorCheckMarkToken = this.successFunction = false;
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopWatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.user.token !== null) {
            this.validator.addObject(this.user, this.loggingIn ? this.baseRules : this.registerRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.token !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
            }
            let checkEmailValidation;
            let checkPasswordValidation;
            if (this.user.slowValidateEmail !== null && this.user.password !== null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.showErrorCheckMarkPassword;
            }
            if (!checkValidation) {
                this.showErrorCheckMarkToken = true;
                if (!this.toastTokenSent) {
                    this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
                }
                return;
            } else {
                if (this.tokenRequired && this.passwordValid && this.showGreenCheckMarkEmail && !this.firedFunction) {
                    await this.login();
                }

                if (checkValidation && (this.successFunction || !this.user.slowValidateEmail || !this.user.password || checkPasswordValidation || checkEmailValidation)) {
                    this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                    this.showGreenCheckMarkToken = true;
                } else {
                    this.showErrorCheckMarkToken = true;
                }
            }
        }
    }

    async tokenUpdatedOnFocusIn() {
        this.showGreenCheckMarkToken = this.showErrorCheckMarkToken = this.firedFunction = this.toastTokenSent = this.successFunction = this.showMiniSpinnerToken = false;
        this.validator.reset();
        this.tokenFocusInStopWatch = setTimeout(() => {
            if (this.user.token !== null) {
                this.tokenUpdatedOnKeyPress();
            }
        });
    }

    async tokenUpdatedOnKeyPress() {
        this.timeouts = [this.tokenStopWatch, this.tokenStopWatch2, this.miniSpinnerTokenStopWatch, this.tokenFocusInStopWatch];
        this.clearationTimeoutValueConverter.toView(this.timeouts);
        this.validator.reset();
        if (this.user.token) {
            this.showGreenCheckMarkToken = false;
            this.showErrorCheckMarkToken = false;
            this.showMiniSpinnerToken = false;
            this.successFunction = false;
            this.toastTokenSent = false;
        } else {
            this.showGreenCheckMarkToken = false;
            this.showErrorCheckMarkToken = false;
            this.showMiniSpinnerToken = false;
            this.successFunction = false;
            this.toastTokenSent = false;
            return '';
        }
        this.miniSpinnerTokenStopWatch = setTimeout(() => {
            this.showMiniSpinnerToken = true;
        }, 1000);
        this.tokenStopWatch = setTimeout(async() => {
            this.validator.addObject(this.user, this.baseRules);
            const result = await this.validator.validate();
            let checkValidation;
            if (this.user.token !== '') {
                checkValidation = result.results.filter(r => r.propertyName === 'token').every(r => r.valid);
            }
            let checkEmailValidation;
            let checkPasswordValidation;
            if (this.user.slowValidateEmail !== null && this.user.password !== null) {
                if (this.user.slowValidateEmail !== '') {
                    this.showGreenCheckMarkEmail = result.results.filter(r => r.propertyName === 'slowValidateEmail').every(r => r.valid);
                } else {
                    this.showGreenCheckMarkEmail = false;
                }
                this.showErrorCheckMarkEmail = !this.showGreenCheckMarkEmail;
                checkEmailValidation = this.showErrorCheckMarkEmail;
                if (this.user.password !== '') {
                    this.passwordValid = result.results.filter(r => r.propertyName === 'password').every(r => r.valid);
                } else {
                    this.passwordValid = false;
                }
                this.passwordInvalid = !this.passwordValid;
                this.showErrorCheckMarkPassword = !this.passwordValid;
                checkPasswordValidation = this.showErrorCheckMarkPassword;
            }
            if (!checkValidation) {
                this.tokenStopWatch2 = setTimeout(() => {
                    this.showErrorCheckMarkToken = !this.showGreenCheckMarkToken;
                    this.showMiniSpinnerToken = false;
                    this.toastTokenSent = true;
                    this.toastService.showToast('Error', 'Please enter a valid token.', 'error');
                    return;
                }, 2000);
            } else if (this.tokenRequired && this.passwordValid && this.showGreenCheckMarkEmail) {
                this.firedFunction = true;
                await this.login();
            }
            if (checkValidation && (this.successFunction || !this.user.slowValidateEmail || !this.user.password || checkPasswordValidation || checkEmailValidation)) {
                this.showMiniSpinnerToken = this.showErrorCheckMarkToken = false;
                this.showGreenCheckMarkToken = true;
            }
        }, 2000);
    }

    async register() {
        if (!this.isRequesting) {
            this.isRequesting = true;
            this.user.email = this.user.slowValidateEmail;
            if (this.loading) {
                return;
            }
            this.validator.addObject(this.user, this.registerRules);
            if (!this.loading) {
                const result = await this.validator.validate();
                if (!result.valid) {
                    this.loading = false;
                    return;
                }
                try {
                    const registerResponse = await this.customerService.register(this.user);
                    if (registerResponse?.token) {
                        this.showMiniSpinnerEmail = this.showMiniSpinnerPassword = this.showMiniSpinnerToken = this.showErrorCheckMarkEmail = this.showErrorCheckMarkPassword = this.showErrorCheckMarkToken = false;
                        this.successFunction = true;
                        this.loadingFromAuth = true;
                        await this.sessionService.saveToken(registerResponse.token);
                        const oldCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });
                        if (oldCart?.length) {
                            for (const item of oldCart) {
                                await this.sessionService.saveCart(oldCart, true, null, null, null, item, true);
                            }
                        }
                        const newCart = await this.sessionService.getCart();
                        this.eventAggregator.publish('cart-updated', { cart: newCart });
                        this.isRequesting = false;
                        this.toastService.showToast('Success!.', 'An account has been created for you. A temporary password has been sent to your email.', 'success');
                    } else if (registerResponse) {
                        this.loggingIn = true;
                        this.showGreenCheckMarkEmail = true;
                        this.isRequesting = false;
                    } else {
                        this.toastService.showToast('Failed to create user, please try using another email or password.', 'Please try again.', 'error');
                        this.isRequesting = false;
                    }
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }

    async login() {
        if (!this.isRequesting) {
            this.isRequesting = true;
            this.user.email = this.user.slowValidateEmail;
            if (this.loading) {
                return;
            }
            this.validator.addObject(this.user, this.baseRules);
            const result = await this.validator.validate();
            if (!result.valid) {
                return;
            }

            try {
                const response = await this.sessionService.login(this.user);
                if (response) {
                    this.showMiniSpinnerEmail = this.showMiniSpinnerPassword = this.showMiniSpinnerToken = this.showErrorCheckMarkEmail = this.showErrorCheckMarkPassword = this.showErrorCheckMarkToken = false;
                    if (response?.tokenRequired) {
                        this.successFunction = true;
                        this.toastService.showToast('Info', '2FA required for login.', 'info');
                        this.tokenRequired = true;
                        this.loginFailed = false;
                        this.isRequesting = false;
                        return;
                    }
                    if (response?.incorrectToken) {
                        this.toastService.showToast('Incorrect 2FA.', 'Please check that the token is correct, then try again or contact live chat for assistance.', 'error');
                        this.showGreenCheckMarkToken = this.isRequesting = false;
                        this.showGreenCheckMarkEmail = this.passwordValid = this.showErrorCheckMarkToken = true;
                        return;
                    }
                    if (response?.resetPassword) {
                        this.successFunction = true;
                        this.isRequesting = false;
                        this.toastService.showToast('Info', 'You are required to reset your password. An email has been sent to you to do so.', 'info');
                        const data = { email: this.user.email };
                        await this.customerService.requestPasswordReset(data);
                        return;
                    }
                    const oldCart = await this.sessionService.getCart();
                    this.eventAggregator.publish('user-updated', { user: await this.sessionService.getProfile() });
                    if (oldCart?.length) {
                        for (const item of oldCart) {
                            await this.sessionService.saveCart(oldCart, true, null, null, null, item, true);
                        }
                    }
                    const newCart = await this.sessionService.getCart();
                    this.eventAggregator.publish('cart-updated', { cart: newCart });
                    await this.checkUserInBlackList();
                    this.showGreenCheckMarkEmail = this.passwordValid = this.showGreenCheckMarkToken = true;
                    this.successFunction = true;
                    this.loadingFromAuth = true;
                    this.isRequesting = false;
                } else {
                    this.loginFailed = true;
                    this.showForgotPassword = true;
                    this.showGreenCheckMarkEmail = this.showGreenCheckMarkToken = this.passwordValid = this.showMiniSpinnerEmail = this.showMiniSpinnerPassword = this.showMiniSpinnerToken = false;
                    this.showErrorCheckMarkEmail = this.showErrorCheckMarkPassword = this.passwordInvalid = this.showErrorCheckMarkToken = true;
                    this.isRequesting = false;
                }
            } catch (e) {
                console.log(e);
            }
        }
    }

    async checkUserInBlackList() {
        const banForPaymenthMethod = await this.sessionService.checkIfUserByPaymentMethodInBlackList(this.parent.selectedPaymentMethod);
        if (banForPaymenthMethod.paymentMethods) {
            this.parent.paymentMethodSelector.paymentMethods = null;
            this.parent.selectedPaymentMethod = null;
            this.parent.summaryButtonState = 'disabled';
            this.parent.balancePaymentMethod = null;
            this.parent.userBanByPaymenthMethod = true;
            return true;
        }
        if (banForPaymenthMethod.filterPaymentMethods) {
            this.parent.paymentMethodSelector.paymentMethods = this.parent.paymentMethodSelector.paymentMethods?.filter(x => !x.requiresVerification && !x.requiresPhoneVerification);
            this.parent.selectedPaymentMethod = banForPaymenthMethod.selectedPaymentMethod ? null : this.parent.selectedPaymentMethod;
            this.parent.summaryButtonState = banForPaymenthMethod.selectedPaymentMethod ? 'disabled' : 'active';
        }
        return false;
    }
}
