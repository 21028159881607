import './ds-filter.scss';
import { autoinject, bindable } from 'aurelia-framework';
import { SessionService } from 'services/session-service';
import { Router } from 'aurelia-router';
import { CurrencyFormatValueConverter } from 'resources/value-converters/currency-formatter';
import { InputNumericValueChecker } from 'resources/value-converters/input-numeric-value-checker';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject()
export class DsFilter {
    @bindable iconPath;
    @bindable label;
    @bindable options;
    @bindable displayValue;
    @bindable changeFunction = function (...args: string[] | number[] | number[][]) {return args;};
    @bindable required = false;
    @bindable min = 0;
    @bindable max = 10000;
    @bindable filter;
    @bindable type;
    @bindable value;
    @bindable outlined;
    @bindable inputClass;
    @bindable forceDefault = false;
    @bindable disabled;
    @bindable radius;
    @bindable rangeStart = 0;
    @bindable rangeEnd = 1500;
    @bindable startPrice = this.rangeStart;
    @bindable endPrice = this.rangeEnd;
    currencySubscriber;
    currencyPrefix;
    selectedPrice;
    priceSelectorOpen = false;
    control;
    triggerPriceListener;
    startPriceElement;
    endPriceElement;
    filterSelector;

    constructor(private sessionService: SessionService, private currencyFormatValueConverter: CurrencyFormatValueConverter, private inputNumericValueChecker: InputNumericValueChecker, private eventAggregator: EventAggregator, private router: Router) {}

    valueChanged(newValue) {
        if (this.control && this.filter?.field?.listOfValue?.children) {
            this.control.selectedIndex = this.filter?.field?.listOfValue?.children.findIndex(e => e.value === newValue?.value);
        }
    }

    async attached() {
        if (this.filter?.field?.listOfValue?.children) {
            this.filter?.field?.listOfValue?.children.sort((a, b) => { return Number(b.position !== null) - Number(a.position !== null) || a.position - b.position; });
        }
        if (this.forceDefault && !this.value) {
            if (this.options) {
                this.value = this.options[0];
            }
            if (this.filter?.field?.listOfValue?.children) {
                this.value = this.filter?.field?.listOfValue?.children[0];
            }
            if (this.value) {
                this.changeFunction(null, this.filter, this.value);
            }
        } else {
            if (this.filter?.field?.listOfValue?.children) {
                this.value = this.filter?.field?.listOfValue?.children.find(x => !x.value);
            }
        }
        const preferredCurrency = await this.sessionService.getCurrency();
        this.currencyPrefix = this.currencyFormatValueConverter.getCurrencyPrefix(preferredCurrency);
        this.handlePricesPaste();
        this.handlePriceFocusOutEvent();
        this.handleEventSubscriptions();
        this.overrideMdc();
    }

    async detached() {
        this.currencySubscriber?.dispose();
    }

    handleEventSubscriptions() {
        this.currencySubscriber = this.eventAggregator.subscribe('currency-updated', payload => {
            const currency = payload.currency;
            this.currencyPrefix = this.currencyFormatValueConverter.getCurrencyPrefix(currency);
            if (this.type === 'price') {
                this.changeFunction([this.startPrice, this.endPrice]);
            }
        });
    }

    handlePricesPaste() {
        if (this.startPriceElement) {
            this.startPriceElement.addEventListener('paste', (event) => {
                const clipData = event.clipboardData || window['clipboardData'];
                const text = clipData?.getData('text/plain');
                if (text?.includes('e') || text?.includes('E') || text?.includes('+') || text?.includes('-')) {
                    event?.preventDefault();
                }
            });
        }

        if (this.endPriceElement) {
            this.endPriceElement.addEventListener('paste', (event) => {
                const clipData = event.clipboardData || window['clipboardData'];
                const text = clipData?.getData('text/plain');
                if (text?.includes('e') || text?.includes('E') || text?.includes('+') || text?.includes('-')) {
                    event?.preventDefault();
                }
            });
        }
    }

    overrideMdc() {
        const selectArrow = this.filterSelector?.querySelector('.mdc-select .mdc-select__dropdown-icon');
        const newArrowIcon = '<img class="faq-arrow-icon" src="/static/faq/arrow-white.svg" alt="arrow icon" loading="lazy">';
        if (selectArrow) {
            selectArrow.innerHTML = newArrowIcon;
        }
    }

    openPriceSlider() {
        this.priceSelectorOpen = true;
    }

    startPriceChanged() {
        if (this.startPrice > 1500) {
            this.startPrice = 1500;
        }
    }

    endPriceChanged() {
        if (this.endPrice > 1500) {
            this.endPrice = 1500;
        }
    }

    handleStartPriceUpdate() {
        if (this.startPrice > this.rangeEnd) {
            this.rangeEnd = 1500;
            this.endPrice = 1500;
        }
        this.rangeStart = this.startPrice;
        this.changeFunction([this.startPrice, this.endPrice]);
    }

    handleEndPriceUpdate() {
        if (this.endPrice <= this.rangeStart) {
            this.rangeStart = this.endPrice - 50;
            this.startPrice = this.endPrice - 50;
            if (this.startPrice < 0) {
                this.rangeStart = 0;
                this.startPrice = 0;
            }
        }
        this.rangeEnd = this.endPrice;
        this.changeFunction([this.startPrice, this.endPrice]);
    }

    rangeStartChanged() {
        this.startPrice = this.rangeStart;
        this.changeFunction([this.startPrice, this.endPrice]);
    }

    rangeEndChanged() {
        this.endPrice = this.rangeEnd;
        this.changeFunction([this.startPrice, this.endPrice]);
    }

    handlePriceFocusOutEvent() {
        const element = document.getElementById('priceDropdownMenu');
        if (element && !this.triggerPriceListener) {
            this.triggerPriceListener = true;
            element.addEventListener('hide.bs.dropdown', () => {
                this.priceSelectorOpen = false;
            });
        }
    }
}
