import { autoinject } from 'aurelia-framework';
import { ApiService } from './api-service';

@autoinject()
export class SiteSettingService {
    path = 'SiteSetting';
    siteSettings;

    constructor(private api: ApiService) {
        this.api = api;
    }

    async getSiteSettings() {
        return this.siteSettings;
    }

    async getAll() {
        this.siteSettings = await this.api.doGet(this.path);
    }

    async getById(id) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByKey(key) {
        return await this.api.doGet(this.path + '/ByKey/' + key);
    }
}
