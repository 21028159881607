import { autoinject, signalBindings } from 'aurelia-framework';
import { ApiService } from './api-service';
import { UserDocumentService } from './user-document-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ToastService } from './toast-service';
import { CurrencyService } from './currency-service';
import { debug, adminPanelUrl, websiteShortCode } from 'environment';
import { CustomerService } from './customer-service';
import { BlacklistService } from './blacklist-service';
import { ThousandSeparatorValueConverter } from 'resources/value-converters/thousand-separator';
import { ReferralService } from './referral-service';

const CURRENCY_KEY = 'currency';
const EMAIL_IN_REVIEW_KEY = 'email_in_review';
const PREVIOUS_CURRENCY_KEY = 'previous_currency';
const REFERRAL_LINK_KEY = 'referral_link';
const REFERRER_LINK_KEY = 'refferer_link';
const ADMIN_VIEW = 'admin_view';
const PURCHASED = 'purchased';
const CART_KEY = 'cart';
const GAME_CHARACTER = 'game_character_names';
const CURRENT_BLOGS_PAGE_VIEW = 'current_blogs_page_view';

@autoinject()
export class SessionService {
    isAuthenticated = false;
    currentUser = null;
    geolocation;
    userProxy;
    private intercomTimer: NodeJS.Timer;
    private TOKEN_KEY = 'jwt_token';
    CURRENCY_OPTIONS = [];
    userCart;
    userBlacklist;
    canOpenLiveChat = true;

    constructor(
        private apiService: ApiService,
        private eventAggregator: EventAggregator,
        private userDocumentService: UserDocumentService,
        private toastService: ToastService,
        private currencyService: CurrencyService,
        private customerService: CustomerService,
        private blacklistService: BlacklistService,
        private thousandSeparatorValueConverter: ThousandSeparatorValueConverter,
        private referralService: ReferralService) { }

    async login(data) {
        const formattedData = { email: data.email, password: data.password, token: data.token, websiteShortCode: websiteShortCode() };
        const response = await this.apiService.doPost('', formattedData);

        if (response) {
            if (!response.resetPassword) {
                if (response.userBlacklist?.length > 0) {
                    this.userBlacklist = response.userBlacklist;
                    const blackListResponse = await this.verifyIsUserInBlackList(null, true);
                    if (blackListResponse) {
                        return;
                    }
                }
                this.saveToken(response.token);
                this.isAuthenticated = true;
            }
            return response;
        }
    }

    async getProfile() {
        if (this.isTokenValid()) {
            if (this.currentUser) {
                return this.currentUser;
            }
            this.currentUser = await this.apiService.doGet('profile');
            if (!this.currentUser?.isDeleted) {
                this.saveToken(this.currentUser?.refreshToken?.token);
            } else if (this.currentUser?.isDeleted) {
                await this.toastService.showToast('Account deleted', 'As a result you have been logged out, please Sign up again to access your account or contact Support.', 'info');
                await this.logout();
            }
            if (this.currentUser?.userBlacklist?.length > 0) {
                this.userBlacklist = this.currentUser.userBlacklist;
                const blackListResponse = await this.verifyIsUserInBlackList(null, true);
                if (blackListResponse) {
                    await this.logout();
                }
            }
            return this.currentUser;
        }
    }

    async refreshProfile() {
        if (this.isTokenValid()) {
            this.currentUser = await this.apiService.doGet('profile');
            if (!this.currentUser?.isDeleted) {
                return this.currentUser;
            } else if (this.currentUser?.isDeleted) {
                await this.toastService.showToast('Account deleted', 'As a result you have been logged out, please Sign up again to access your account or contact Support.', 'info');
                await this.logout();
            }
            if (this.currentUser?.userBlacklist?.length > 0) {
                this.userBlacklist = this.currentUser.userBlacklist;
                const blackListResponse = await this.verifyIsUserInBlackList(this.currentUser.email, true);
                if (blackListResponse) {
                    await this.logout();
                }
            }
            return this.currentUser;
        }
    }

    async verifyIsUserInBlackList(email, skipEvent) {
        this.userBlacklist = skipEvent ? this.userBlacklist : await this.blacklistService.getBlacklistByUserEmail(email);
        let message = 'BLlvl4';
        for (const row of this.userBlacklist) {
            if (row.level > 2) {
                this.closeLivechat();
            }
            if (row.level === 4) {
                message += row.category === 1 ? 'E' : row.category === 2 ? 'P' : row.category === 3 ? 'IP' : row.category === 4 ? 'C' : 'R';
                this.toastService.showToast('There was an unexpected error. Please contact customer support at support@chickswatches.com. Ref:' + message + '.', 'Error', 'error');
                return true;
            }
        }
        return false;
    }

    closeLivechat() {
        this.canOpenLiveChat = false;
        if (window.Intercom) {
            window.Intercom('shutdown');
        }
    }

    initializeLiveChat() {
        let intercomSettings = {};
        if (this.currentUser) {
            intercomSettings = {
                email: debug() ? `STAGING-${this.currentUser?.email}` : this.currentUser?.email,
                // eslint-disable-next-line camelcase
                user_id: debug() ? `S${this.currentUser?.id}` : this.currentUser?.id,
                // eslint-disable-next-line camelcase
                user_hash: this.currentUser?.liveChatHash,
                name: `${this.currentUser?.firstName} ${this.currentUser?.lastName}`,
                // eslint-disable-next-line camelcase
                customer_URL: `${adminPanelUrl()}customers/${this.currentUser?.id}`,
            };
        }

        this.intercomTimer = setInterval(async () => {
            if (this.currentUser) {
                this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser.email);
                if (this.userBlacklist?.find(x => x.level === 3 || x.level === 4)) {
                    if (window.Intercom) {
                        window.Intercom('shutdown');
                    }
                    clearInterval(this.intercomTimer);
                    return;
                }
            }
            if (window.Intercom && this.canOpenLiveChat) {
                window.Intercom('boot', {
                    // eslint-disable-next-line camelcase
                    api_base: 'https://api-iam.intercom.io',
                    // eslint-disable-next-line camelcase
                    app_id: 'dqgl5no3',
                    ...intercomSettings
                });

                const urlParams = new URLSearchParams(window.location.search);
                const openLivechat = urlParams.get('openLivechat');
                if (openLivechat === 'true') {
                    window.Intercom('showNewMessage', '');
                }
                clearInterval(this.intercomTimer);
            }
        }, 1000);
    }

    getGameCharacter(game) {
        const gameCharacters = window.localStorage[GAME_CHARACTER];
        if (gameCharacters) {
            const json = JSON.parse(gameCharacters);
            if (json[game?.toString()]) {
                return json[game?.toString()];
            }
        }
        return null;
    }

    saveGameCharacter(game, characterName) {
        const gameCharacters = window.localStorage[GAME_CHARACTER];
        if (gameCharacters) {
            let games = JSON.parse(gameCharacters);
            if (games.length === 0) {
                games = {};
            }
            games[game] = characterName;
            window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
        } else {
            const games = {};
            games[game] = characterName;
            window.localStorage[GAME_CHARACTER] = JSON.stringify(games);
        }
    }

    hasValidSession() {
        const token = this.getToken();
        return token && token !== '' && token !== undefined && token !== 'undefined' && token !== 'null';
    }

    getPlatformLinkCookie(name) {
        return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || undefined;
    }

    async verifyPassword(password) {
        if (this.isTokenValid()) {
            return await this.apiService.doPost('VerifyPassword', password);
        }
    }

    async logout() {
        try {
            await this.apiService.doDelete('Logout');
            this.eventAggregator.publish('untrack-user', { userId: this.currentUser.id });
            this.saveBlogsCurrentPageView(1);
        } finally {
            this.destroyToken();
            this.currentUser = null;
            this.userCart = null;
            this.isAuthenticated = false;
            this.eventAggregator.publish('user-updated', { forDelete: true });
            await this.getCart();
            await this.destroyCart();
        }
    }

    clearSession() {
        this.destroyToken();
        this.currentUser = null;
        this.userCart = null;
        this.isAuthenticated = false;
        this.eventAggregator.publish('user-updated', { forDelete: true });
    }

    saveToken(token) {
        window.localStorage[this.TOKEN_KEY] = token;
    }

    getToken() {
        return window.localStorage[this.TOKEN_KEY];
    }

    destroyToken() {
        window.localStorage.removeItem(this.TOKEN_KEY);
    }

    async getUserProxy() {
        return this.userProxy;
    }

    async getCountry() {
        return this.geolocation?.countryName;
    }

    async isCountryValidForCartPage() {
        const invalidCountries = [
            'AL',
            'BA',
            'ME',
            'XK',
            'MK',
            'RS',
            'BY',
            'MM',
            'CF',
            'IR',
            'RU',
            'KP',
            'CU',
            'CD',
            'ET',
            'IQ',
            'LB',
            'LY',
            'SY'
        ];
        return this.currentUser?.roles?.includes('ViewAdminPanel') || !invalidCountries.includes(this.geolocation?.countryName);
    }

    async getState() {
        return this.geolocation?.regionName;
    }

    saveAdminView(bool) {
        window.localStorage[ADMIN_VIEW] = bool;
        this.eventAggregator.publish('admin-view-updated', { bool: bool });
    }

    getAdminView() {
        return (window.localStorage[ADMIN_VIEW] === 'true');
    }

    checkRolesForPanelAccess(user) {
        return !!user?.roles?.find(x => x.toLowerCase() === 'viewadminpanel');
    }

    isTokenValid() {
        const token = this.getToken();
        return token && token !== '' && token !== undefined && token !== 'undefined';
    }

    saveEmailInReview(bool) {
        window.localStorage[EMAIL_IN_REVIEW_KEY] = bool;
    }

    getEmailInReview() {
        const emailInReview = window.localStorage[EMAIL_IN_REVIEW_KEY];
        if (emailInReview) {
            return JSON.parse(emailInReview);
        }
    }

    async getIdVerificationInReview(id) {
        const idVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 1);
        if (idVerificationInReview) {
            return idVerificationInReview;
        }
    }

    async getAddressVerificationInReview(id) {
        const addressVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 2);
        if (addressVerificationInReview) {
            return addressVerificationInReview;
        }
    }

    async getSelfieVerificationInReview(id) {
        const selfieVerificationInReview = await this.userDocumentService.getUserDocumentsById(id, 3);
        if (selfieVerificationInReview) {
            return selfieVerificationInReview;
        }
    }

    deleteByUserIdAndCategoryId(userId, categoryId) {
        this.userDocumentService.deleteByUserIdAndCategoryId(userId, categoryId);
    }

    async getCurrencyOptions() {
        const currencies = await this.currencyService.getActiveCurrenciesByWebsite();
        this.CURRENCY_OPTIONS = [];
        if (currencies?.length) {
            for (const currency of currencies) {
                this.CURRENCY_OPTIONS.push({
                    value: currency.code,
                    text: currency.code,
                    displayName: currency.description,
                    shortName: currency.shortName,
                    icon: currency.imagePath,
                    currencySymbol: currency.symbol
                });
            }
        }
        return this.CURRENCY_OPTIONS;
    }

    getCurrency() {
        return window.localStorage[CURRENCY_KEY] || 'USD';
    }

    async saveCurrency(currency) {
        await this.currencyService.getStoredCurrencyRates(currency);
        this.eventAggregator.publish('currency-updated', { currency: currency });
        signalBindings('currency-changed');
        window.localStorage[CURRENCY_KEY] = currency;
    }

    getDesiredCurrency(currencyCode) {
        return this.CURRENCY_OPTIONS.find(currency => currency.value === currencyCode);
    }

    async savePreviousCurrency(currency) {
        window.localStorage[PREVIOUS_CURRENCY_KEY] = currency;
    }

    async getPreviousCurrency() {
        return window.localStorage[PREVIOUS_CURRENCY_KEY] || 'USD';
    }

    savePurchased(purchased) {
        window.localStorage[PURCHASED] = purchased;
    }

    getPurchased() {
        return window.localStorage[PURCHASED];
    }

    destroyPurchased() {
        window.localStorage.removeItem(PURCHASED);
    }

    async getGeolocation() {
        const response = await this.apiService.doGet('User/GetGeolocation', {});
        this.geolocation = response;
        return response;
    }

    saveBlogsCurrentPageView(page) {
        window.localStorage[CURRENT_BLOGS_PAGE_VIEW] = page;
    }

    getBlogsCurrentPageView() {
        const currentBlogsPageView = window.localStorage[CURRENT_BLOGS_PAGE_VIEW];
        if (currentBlogsPageView) {
            return JSON.parse(currentBlogsPageView);
        }
    }

    async getCart() {
        if (this.currentUser) {
            if (this.userCart) {
                this.userCart.forEach((e, index) => {
                    this.userCart[index].tempQuantity = e.quantity;
                    this.userCart[index].quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                    this.userCart[index].selectedQuantity = e.quantity;
                });
                return this.userCart;
            }
            this.userCart = await this.customerService.getUserCartProducts();
            this.userCart.forEach((e, index) => {
                this.userCart[index].cartItemId = index + 1;
                this.userCart[index].tempQuantity = e.quantity;
                this.userCart[index].quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                this.userCart[index].selectedQuantity = e.quantity;
            });
            return this.userCart;
        } else {
            const stringCart = window.localStorage[CART_KEY];
            if (stringCart && stringCart !== '[]' && stringCart !== '') {
                const cartItems = JSON.parse(stringCart);
                cartItems.forEach((e, index) => {
                    cartItems[index].tempQuantity = e.quantity;
                });
                return cartItems;
            } else {
                this.destroyCart();
            }
        }
    }

    async checkIfProductInCart(productId) {
        const cart = await this.getCart();
        if (cart) {
            for (const cartProduct of cart) {
                if (cartProduct.id === parseInt(productId)) {
                    return true;
                }
            }
        }
    }

    async saveCart(cart, skipEvent?, index?, updateWithoutRefresh?, cartId?, cartItem?, loggingIn?, updateCart?) {
        if (!skipEvent) {
            this.eventAggregator.publish('cart-updated', { cart: cart, index: index, updateWithoutRefresh: updateWithoutRefresh });
        }
        if (this.currentUser && (!skipEvent || (skipEvent && cartItem))) {
            if (cartId) {
                await this.customerService.destroyUserCartProducts(cartId);
            } else {
                const response = await this.customerService.storeUserCartProducts(cartItem ?? cart, this.currentUser?.id, loggingIn, updateCart);
                cart.forEach((e) => {
                    e.quantity = this.thousandSeparatorValueConverter.toView(e.quantity);
                    e.selectedQuantity = e.quantity;
                });
                if (response) {
                    const checkItem = Array.isArray(cartItem) ? cartItem[0] : cartItem;
                    const productCartIndex = cart.findIndex(x => x.cartItemId === checkItem.cartItemId || (x.id === checkItem.id && x.character === checkItem));
                    cart[productCartIndex].userCartId = response;
                }
            }
        }
        window.localStorage[CART_KEY] = JSON.stringify(cart);
    }

    async destroyCart() {
        if (this.currentUser) {
            await this.customerService.destroyUserCartProducts();
        }
        window.localStorage.removeItem(CART_KEY);
        this.userCart = null;
        this.eventAggregator.publish('cart-updated', { cart: [] });
    }

    destroyReferralLink() {
        window.localStorage.removeItem(REFERRAL_LINK_KEY);
    }

    destroyReferrerLink() {
        window.localStorage.removeItem(REFERRER_LINK_KEY);
    }

    saveReferralLink(linkName) {
        this.referralService.submitReferralLink(linkName, '1');
        window.localStorage[REFERRAL_LINK_KEY] = linkName;
    }

    saveReferrerLink(linkName) {
        this.referralService.submitReferralLink(linkName, '2');
        window.localStorage[REFERRER_LINK_KEY] = linkName;
    }

    getReferralLink() {
        return window.localStorage[REFERRAL_LINK_KEY];
    }

    getReferrerLink() {
        return window.localStorage[REFERRER_LINK_KEY];
    }

    async storeCartItem(item) {
        if (item.salePrice) {
            item.price = item.salePrice;
        }
        const cart = await this.getCart();
        item.cartItemId = cart?.length ? cart.length + 1 : 1;
        if (this.currentUser) {
            item.character = item.character ? item.character : null;
            if (item.productId) {
                item.id = item.productId;
            }
        }
        if (cart) {
            for (const cartItem of cart) {
                if ((cartItem.productId === item.productId || cartItem.id === item.id) && !item.isService && cartItem.character === item.character) {
                    cartItem.selectedQuantity = parseFloat(item.quantity) + parseFloat(cartItem.quantity);
                    cartItem.quantity = parseFloat(item.quantity) + parseFloat(cartItem.quantity);
                    cartItem.price = item.price;
                    return this.saveCart(cart, true, null, null, null, [cartItem]);
                }
            }
            cart.push(item);
            this.saveCart(cart, null, null, null, null, [item]);
        } else {
            this.saveCart([item]);
        }
    }

    async updateCartItem(item, skipEvent) {
        const cart = await this.getCart();
        if (cart) {
            for (const cartItem of cart) {
                if (cartItem.id === item.id && cartItem.name === item.name) {
                    cartItem.character = item.character;
                    cartItem.quantity = this.thousandSeparatorValueConverter.toView(item.quantity.toString());
                    cartItem.selectedQuantity = parseFloat(item.quantity);
                    cartItem.insuranceId = item.insuranceId;
                    cartItem.purchasedInsurance = item.purchasedInsurance;
                    cartItem.deliveryMethod = item.deliveryMethod;
                    cartItem.deliveryMethodId = item.deliveryMethod?.id;
                    return this.saveCart(cart, skipEvent, null, null, null, cartItem, null, true);
                }
            }
        }
    }

    async deleteCartItem(product) {
        const cart = await this.getCart();
        let index;
        let cartId;
        if (product.cartItemId && cart.every(x => x.cartItemId)) {
            index = cart.findIndex(i => i.cartItemId === product.cartItemId);
        } else if (product.id) {
            index = cart.findIndex(i => i.id === product.id);
        } else {
            index = cart.findIndex(i => i.name === product.name);
        }
        if (index !== -1) {
            if (this.currentUser) {
                cartId = cart[index]?.userCartId;
            }
            cart.splice(index, 1);
        }
        await this.saveCart(cart, false, index, this.currentUser ? false : true, cartId);
        if (cart.length < 1) {
            return true;
        }
    }

    async validateCharacterName(product) {
        const cart = await this.getCart();
        return !cart.find(x => product.isService && x.name === product.name && x.character === product.character && (x.cartItemId !== product.cartItemId || x.userCartId !== product.userCartId));
    }

    async checkIfUserByPaymentMethodInBlackList(selectedPaymentMethod) {
        const result = {
            selectedPaymentMethod: false,
            paymentMethods: false,
            filterPaymentMethods: false
        };
        if (!this.currentUser) {
            this.currentUser = await this.getProfile();
        }
        if (this.currentUser) {
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.currentUser?.email);
            if (this.userBlacklist?.some(x => x.level === 3  || x.level === 4 && (x.category === 2 || x.category === 3 || (x.addByVeriffGroup && x.veriffGroupActive)))) {
                result.paymentMethods = true;
                result.selectedPaymentMethod = true;
                return result;
            }
            if (this.userBlacklist?.some(x => x.level === 2 && (x.category === 1 || x.category === 2 || (x.addByVeriffGroup && x.veriffGroupActive)))) {
                result.filterPaymentMethods = true;
                if (selectedPaymentMethod?.requiresVerification || selectedPaymentMethod?.requiresPhoneVerification) {
                    result.selectedPaymentMethod = true;
                }
            }
        }
        return result;
    }
}
