export class User {
    firstName: string;
    lastName: string;
    email: string;
    id: number;
    idVerified: boolean;
    phoneNumberConfirmed: boolean;
    emailConfirmed: boolean;
    addressVerified: boolean;
}
