import { autoinject } from 'aurelia-framework';
import { SessionService } from './session-service';
import { ToastService } from './toast-service';

const AUTHORIZATION_HEADER = 'Authorization';

@autoinject()
export class ApiInterceptor {
    constructor(private sessionService: SessionService, private toastService: ToastService) {}

    async request(request) {
        try {
            if (!this.sessionService.hasValidSession()) {
                return request;
            }

            if (!request.headers.get(AUTHORIZATION_HEADER)) {
                const bearerToken = `Bearer ${this.sessionService.getToken()}`;
                request.headers.append(AUTHORIZATION_HEADER, bearerToken);
            }

            return request;
        } catch (e) {
            console.log(e);
            await this.toastService.showToast('Something went wrong.', 'If this error continues, please contact support.', 'error');
        }
    }

    async response(response) {
        try {
            switch (response?.status) {
                case 401:
                    await this.sessionService.clearSession();
                    break;
                case 403:
                    await this.toastService.showToast('Something went wrong.', 'You do not have access to this section.', 'error');
                    break;
                case 404:
                    return null;
                case 400:
                case 422:
                case 500: {
                    const data = await response.json();
                    let msg = data.Message || data.message;
                    if (msg) {
                        const titleDependingOnMessage = 'Error';
                        msg.endsWith('.') ? msg : msg = msg + '.';
                        await this.toastService.showToast(titleDependingOnMessage, msg.replace(/(?:\r\n|\r|\n)/g, '<br />'), 'error');
                    }

                    if (data.validationErrors) {
                        let errorMsg = '';
                        Object.keys(data.validationErrors).forEach((key) => {
                            data.validationErrors[key].forEach(m => {
                                errorMsg += `${m}<br>`;
                            });
                        });
                        await this.toastService.showToast('Error', errorMsg, 'error');
                    }
                    new Error(msg);
                    break;
                }
            }
            return response;
        } catch (e) {
            console.log(e);
            await this.toastService.showToast('Something went wrong', 'If this error continues, please contact support.', 'error');
        }
    }
}
