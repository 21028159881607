import { PLATFORM } from 'aurelia-pal';
import { activationStrategy, Router } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { AuthorizeStep } from 'resources/pipelines/authorize-step';
import { MetadataStep } from 'resources/pipelines/metadata-step';
import { SeoStep } from 'resources/pipelines/seo-step';
import { IntercomStep } from 'resources/pipelines/intercom-step';
import { ScrollPageStep } from 'resources/pipelines/scroll-page-step';
import { autoinject } from 'aurelia-dependency-injection';
import { Toast } from 'services/models/toast';
import { SignalrService } from 'services/signalr-service';
import { HubConnection } from '@microsoft/signalr';
import { ScriptService } from 'services/script-service';
import { SessionService } from 'services/session-service';
import { PageContentAreaService } from 'services/page-content-area-service';
import { InterComStylingService } from 'services/intercom-styling-service';
import { CurrencyService } from 'services/currency-service';
import { HealthService } from 'services/health-service';
import { WebsiteService } from 'services/website-service';
import { IpAddressService } from 'services/ip-address-service';
import { BlacklistService } from 'services/blacklist-service';
import { baseUrl } from 'environment';
import { CapitalizeTextValueConverter } from 'resources/value-converters/capitalize-text';
import { ProductCategoryService } from 'services/product-category-service';
import isOnline from 'is-online';

@autoinject()
export class App {
    private user;
    private currencyOptions = [];
    private router: Router;
    private toasts = [] as Toast[];
    private healthTimeout: NodeJS.Timer;
    private triggeredConnection: boolean;
    private connection: HubConnection;
    private userSubscriber;
    private veriffSubscriber;
    private routerSubscription;
    hideIntercomIcon: boolean;
    width: number;
    height: number;
    triggeredMonitoring: boolean;
    triggeredBanner: boolean;
    urlParams;
    userBlacklist;
    pages;
    firstTimeBreadcrumbCall;
    untrackUserConnection: Subscription;

    constructor(
        private eventAggregator: EventAggregator,
        private signalRService: SignalrService,
        private scriptService: ScriptService,
        private sessionService: SessionService,
        private pageContentAreaService: PageContentAreaService,
        private intercomStylingService: InterComStylingService,
        private currencyService: CurrencyService,
        private healthService: HealthService,
        private websiteService: WebsiteService,
        private ipAddressService: IpAddressService,
        private blacklistService: BlacklistService,
        private capitalizeTextValueConverter: CapitalizeTextValueConverter,
        private productCategoryService: ProductCategoryService) {
        eventAggregator.subscribe('toast', (toast) => { this.onToastReceived(toast); });
    }

    async activate() {
        [this.user, this.pages, this.currencyOptions] = await Promise.all([
            this.sessionService.getProfile(),
            this.websiteService.getPagesByWebsiteShortcode(),
            this.sessionService.getCurrencyOptions(),
            this.currencyService.getAllCurrencyRates()
        ]);
        await this.pageContentAreaService.getByPageId(this.pages.find(x => x.name === 'Footer')?.id);
    }

    async attached() {
        this.width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        this.height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.scriptService.injectIntercomScript();
        this.handleWindowResizement();
        this.urlParams = new URLSearchParams(window.location.search);

        if (this.urlParams.get('openLivechat') && window.Intercom) {
            window.Intercom('show');
        }

        if (this.user) {
            await this.ipAddressService.postIp();
            this.userBlacklist = await this.blacklistService.getBlacklistByUserEmail(this.user.email);
        }

        this.handleReferralAndReferrerLinks();
        this.handleSignalRConnection();

        //Styling & Google Snippet calls
        this.firstTimeBreadcrumbCall = true;
        this.handleBreadcrumbList();
        this.interComDeviceStyling();

        //Event & Script calls
        this.handleEventSubscriptions();
        this.handleMonitoringWebsite();
        this.handleGoogleTagManagerUserId(this.user?.id);
        if (this.user) {
            if (this.userBlacklist?.some(x => x.level === 3 || x.level === 4)) {
                if (window.Intercom) {
                    window.Intercom('shutdown');
                }
                return;
            }
        }
        this.sessionService.initializeLiveChat();
    }

    detached() {
        this.userSubscriber?.dispose();
        this.veriffSubscriber?.dispose();
        this.routerSubscription?.dispose();
        this.untrackUserConnection?.dispose();
    }

    handleEventSubscriptions() {
        this.routerSubscription = this.eventAggregator.subscribe('router:navigation:success', async () => {
            if (!this.firstTimeBreadcrumbCall) {
                this.handleBreadcrumbList();
            }
        });

        this.userSubscriber = this.eventAggregator.subscribe('user-updated', async (payload) => {
            this.handleGoogleTagManagerUserId(payload.user?.id);
            if (payload) {
                if (payload.forDelete) {
                    this.user = undefined;
                }
                else {
                    const profile = await this.sessionService.getProfile();
                    if (payload.user.id === profile.id) {
                        this.user = payload.user;
                    }
                    this.ipAddressService.postIp();
                    this.handleSignalRConnection();
                    this.sessionService.initializeLiveChat();
                    await this.signalRService.trackUserConnection(this.user.id);
                }
            }
        });

        this.untrackUserConnection = this.eventAggregator.subscribe('untrack-user', async(payload) => {
            if (payload.userId) {
                await this.signalRService.untrackUserConnection(payload.userId);
            }
        });
    }

    async handleReferralAndReferrerLinks() {

        const referralLink = this.urlParams.get('ref');

        if (referralLink) {
            this.sessionService.saveReferralLink(referralLink);
            this.urlParams.delete('ref');
        }

        const referrerLink = document.referrer;
        if (referrerLink && referrerLink !== '' && !referralLink && !referrerLink.includes('chickswatches') && !referrerLink.includes('localhost')) {
            this.sessionService.saveReferrerLink(referrerLink);
        }
    }

    handleWindowResizement() {
        if (window.ResizeObserver) {
            const resizeObserver = new ResizeObserver(async entries => {
                for (const entry of entries) {
                    this.width = entry?.contentRect.width;
                    this.height = entry?.contentRect.height;
                    this.interComDeviceStyling();
                    this.eventAggregator.publish('size-changed', { width: this.width, height: this.height });
                }
            });
            resizeObserver.observe(document.getElementById('main-page-host'));
        }
    }

    interComDeviceStyling() {
        let intercom = document.getElementById('intercom-style');

        this.intercomStylingService.handleStyles(this.width);

        this.veriffSubscriber = this.eventAggregator.subscribe('opened-veriff-dialog', payload => {
            this.hideIntercomIcon = payload;
            if (this.hideIntercomIcon) {
                if (!intercom) {
                    intercom = document.createElement('style');
                    intercom.setAttribute('id', 'intercom-style');
                }
                intercom.innerHTML = '.intercom-launcher-frame, .intercom-app > div:nth-child(2) { display: none !important; } .intercom-lightweight-app-launcher { display: none !important; } .intercom-messenger-frame { display: none !important; }';
            }
        });
    }

    async handleSignalRConnection() {
        if (!this.triggeredConnection) {
            this.triggeredConnection = true;
            this.connection = await this.signalRService.setupSignalRConnection();
            await this.signalRService.start(this.connection);

            this.connection.on('ApiRedeployment', async(response) => {
                if (response) {
                    this.eventAggregator.publish('banner-updated', { successful: 'warning', text: 'Divica Sales was updated. To refresh, please', clickFunctionName: 'refresh' });
                }
            });

            if (this.user) {
                await this.signalRService.trackUserConnection();
            }
        }
    }

    onToastReceived(toast) {
        this.toasts.push(toast);
    }

    handleGoogleTagManagerUserId(userId) {
        const scriptElement = document.getElementById('google-tag-manager');
        if (!scriptElement) {
            const s = document.createElement('script');
            s.setAttribute('id', 'google-tag-manager');
            s.innerHTML = this.getGoogleTagManagerUserIdScript(userId);
            document.head.appendChild(s);
        } else {
            scriptElement.innerHTML = this.getGoogleTagManagerUserIdScript(userId);
        }
    }

    async handleBreadcrumbList() {
        const breadcrumb = document.getElementById('breadcrumb-list');
        if (breadcrumb) {
            breadcrumb.remove();
        }
        const s = document.createElement('script');
        s.setAttribute('id', 'breadcrumb-list');
        s.type = 'application/ld+json';
        const dynamicPagesForNav = await this.productCategoryService.getAllForNav();
        let foundChildRoute;
        if (this.router.currentInstruction.config.name === 'sell') {
            const sellCategory = this.router.currentInstruction.fragment.split('/');
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === `${sellCategory[1]} ${sellCategory[2]}`.toLowerCase());
        } else {
            foundChildRoute = dynamicPagesForNav.find(x => x.name.toLowerCase() === this.router?.currentInstruction.config.name.replace('-', ' ').toLowerCase());
        }
        if (foundChildRoute?.gameForNav.length) {
            foundChildRoute = foundChildRoute.gameForNav.find(x => x.slug.find(y => y === window.location.pathname.split('/').pop()));
        }
        s.innerHTML = `[{
                            "@context": "https://schema.org",
                            "@type": "BreadcrumbList",
                            "itemListElement": [{ 
                                "@type": "ListItem",
                                "position": 1,
                                "name": "${this.capitalizeTextValueConverter.toView(this.router?.currentInstruction.config.name.toString().replaceAll('-', ' '), 'sentence')}",
                                "item": "${`${baseUrl()}${this.router?.currentInstruction.config.navModel.config.route}`}"`;
        if (Object.keys(this.router.currentInstruction.params).length !== 0) {
            s.innerHTML += `}, {
                                "@type": "ListItem",
                                "position": 2,
                                "name": "${foundChildRoute?.name ?? this.capitalizeTextValueConverter.toView(Object.values(this.router?.currentInstruction.params)[0].toString().replace(/\d+/g, '').replaceAll('-', ' ').replaceAll('/', ' ').trim(), 'sentence')}",
                                "item": "${`${baseUrl().slice(0, -1)}${this.router?.currentInstruction.fragment}`}"
                            }]
                        }]`;
        } else {
            s.innerHTML += '}] }]';
        }
        document.head.appendChild(s);
        this.firstTimeBreadcrumbCall = false;
    }

    handleMonitoringWebsite() {
        this.healthTimeout = setInterval(async() => {
            if (!this.triggeredMonitoring) {
                try {
                    this.triggeredMonitoring = true;
                    const response = await this.healthService.checkApiHealth();
                    if (!response) {
                        clearInterval(this.healthTimeout);
                        if (await isOnline() && !this.triggeredBanner) {
                            this.triggeredBanner = true;
                            this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'Divica Sales is currently down. To check status, please', url: 'https://status.chickswatches.com/' });
                        }
                    }
                } catch (e) {
                    clearInterval(this.healthTimeout);
                    if (await isOnline() && !this.triggeredBanner) {
                        this.triggeredBanner = true;
                        this.eventAggregator.publish('banner-updated', { successful: 'error', text: 'Divica Sales is currently down. To check status, please', url: 'https://status.chickswatches.com/' });
                    }
                } finally {
                    this.triggeredMonitoring = false;
                    this.triggeredBanner = false;
                }
            }
        }, 60000);
    }

    getGoogleTagManagerUserIdScript(userId) {
        return userId
            ?
            `window.dataLayer = window.dataLayer || [];
            
            window.dataLayer.push({
                'user_id': '${userId}'
            });

            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TZX7NPJ');`
            :
            `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-TZX7NPJ');`;
    }

    async configureRouter(config, router) {
        config.options.pushState = true;
        config.title = 'Chickswatches';
        config.titleSeparator = ' - ';
        config.addAuthorizeStep(AuthorizeStep);
        config.addPreRenderStep(MetadataStep);
        config.addPreRenderStep(SeoStep);
        config.addPostRenderStep(IntercomStep);
        config.addPostRenderStep(ScrollPageStep);
        config.map([
            {
                route: this.getRouteNameForPage(this.pages, 'Maintenance') ?? 'maintenance',
                name: 'maintenance',
                moduleId: PLATFORM.moduleName('pages/maintenance/maintenance'),
                title: this.getTitleForPage(this.pages, 'Maintenance'),
                settings: {
                    metaDescription: this.getMetaDescriptionForPage(this.pages, 'Maintenance'),
                }
            },
            // Routes disabled to add the white screen
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Home') ?? '',
            //     name: 'home',
            //     moduleId: PLATFORM.moduleName('pages/home/home'),
            //     title: this.getTitleForPage(this.pages, 'Home'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Home'),
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Currency') ?? 'currency',
            //     name: 'currency',
            //     moduleId: PLATFORM.moduleName('pages/currency/currency'),
            //     title: this.getTitleForPage(this.pages, 'Currency'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Currency')
            //     }
            // },
            // {
            //     route: 'cart',
            //     name: 'cart',
            //     moduleId: PLATFORM.moduleName('pages/cart/cart'),
            //     title: 'Cart'
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Terms Of Service') ?? 'terms-of-service',
            //     name: 'terms-of-service',
            //     moduleId: PLATFORM.moduleName('pages/terms-of-service/terms-of-service'),
            //     title: this.getTitleForPage(this.pages, 'Terms Of Service'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Terms Of Service')
            //     }
            // },
            // {
            //     route: 'order-details/:id',
            //     name: 'order-details',
            //     moduleId: PLATFORM.moduleName('pages/order-details/order-details'),
            //     title: 'Order Details'
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Copyright Policy') ?? 'copyright-policy',
            //     name: 'copyright-policy',
            //     moduleId: PLATFORM.moduleName('pages/copyright-policy/copyright-policy'),
            //     title: this.getTitleForPage(this.pages, 'Copyright Policy'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Copyright Policy')
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Privacy Policy') ?? 'privacy-policy',
            //     name: 'privacy-policy',
            //     moduleId: PLATFORM.moduleName('pages/privacy-policy/privacy-policy'),
            //     title: this.getTitleForPage(this.pages, 'Privacy Policy'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Privacy Policy')
            //     }
            // },
            // {
            //     route: 'sign-up',
            //     name: 'sign-up',
            //     moduleId: PLATFORM.moduleName('pages/auth/auth'),
            //     title: 'Sign Up'
            // },
            // {
            //     route: 'sign-in',
            //     name: 'sign-in',
            //     moduleId: PLATFORM.moduleName('pages/auth/auth'),
            //     title: 'Sign In'
            // },
            // {
            //     route: 'sign-in/reset-password',
            //     name: 'reset-password',
            //     moduleId: PLATFORM.moduleName('pages/auth/auth'),
            //     title: 'Reset Password'
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Contact Us') ?? 'contact',
            //     name: 'contact',
            //     moduleId: PLATFORM.moduleName('pages/contact/contact'),
            //     title: this.getTitleForPage(this.pages, 'Contact Us'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Contact Us')
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'About Us') ?? 'about-us',
            //     name: 'about-us',
            //     moduleId: PLATFORM.moduleName('pages/about-us/about-us'),
            //     title: this.getTitleForPage(this.pages, 'About Us'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'About Us')
            //     }
            // },
            // {
            //     route: 'expired-link',
            //     name: 'expired-link',
            //     moduleId: PLATFORM.moduleName('pages/expired-link/expired-link'),
            //     title: 'Expired Link'
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Customer Portal') ?? 'customer-portal',
            //     name: 'customer-portal',
            //     moduleId: PLATFORM.moduleName('pages/customer-portal/customer-portal'),
            //     title: this.getTitleForPage(this.pages, 'Customer Portal'),
            //     settings: {
            //         auth: true,
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Customer Portal')
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Faq') ?? 'faq',
            //     name: 'faq',
            //     moduleId: PLATFORM.moduleName('pages/faq/faq'),
            //     title: this.getTitleForPage(this.pages, 'Faq'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Faq')
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, 'Blogs') ?? 'blogs',
            //     name: 'blogs',
            //     moduleId: PLATFORM.moduleName('pages/blogs/blogs'),
            //     title: this.getTitleForPage(this.pages, 'Blogs'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blogs')
            //     }
            // },
            // {
            //     route: this.getRouteNameForPage(this.pages, '404') ?? '404',
            //     name: '404',
            //     moduleId: PLATFORM.moduleName('pages/404/404'),
            //     title: this.getTitleForPage(this.pages, '404'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, '404')
            //     }
            // },
            // {
            //     route: `${this.getRouteNameForPage(this.pages, 'Blogs') ?? 'blogs'}/:blog`,
            //     name: 'blog-post',
            //     moduleId: PLATFORM.moduleName('pages/blogs/blogpost/blogpost'),
            //     title: this.getTitleForPage(this.pages, 'Blogs'),
            //     settings: {
            //         metaDescription: this.getMetaDescriptionForPage(this.pages, 'Blogs')
            //     },
            //     activationStrategy: activationStrategy.replace
            // }
        ]);

        config.mapUnknownRoutes(() => {
            return { redirect: 'maintenance' };
        });

        this.router = router;
    }

    getTitleForPage(pages, pageName) {
        return pages?.find(x => x.name === pageName)?.title;
    }

    getMetaDescriptionForPage(pages, pageName) {
        return pages?.find(x => x.name === pageName)?.metaDescription;
    }

    getRouteNameForPage(pages, pageName) {
        return pages?.find(x => x.name === pageName)?.routeName;
    }
}
