import { autoinject } from 'aurelia-dependency-injection';
import { ApiService } from './api-service';
import { websiteShortCode } from 'environment';

@autoinject()
export class PageContentAreaService {
    path = 'PageContentArea';
    retrievedContent = [];

    constructor(private api: ApiService) {
        this.api = api;
    }

    async getById(id: number) {
        return await this.api.doGet(this.path + '/' + id);
    }

    async getByKey(key: string) {
        const foundContent = this.retrievedContent.find(x => x.key === key);
        if (foundContent) {
            return foundContent;
        } else {
            return null;
        }
    }

    async getByPageId(pageId: number) {
        const params = {
            pageId: pageId,
            websiteShortCode: websiteShortCode()
        };

        const content = await this.api.doGet(this.path + '/ByPage', params);
        content.forEach((e) => {
            if (!this.retrievedContent.find(x => x.key === e.key)) {
                this.retrievedContent.push(e);
            }
        });
        return this.retrievedContent;
    }
}
